import { Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { TAdjustments, TReportHeader } from 'integration/resources/goals'
import {
  correctDate,
  formatReferenceMonth
} from 'src/screens/WalletsManagement/Components/ReportPDF/utils'

import { GoalAdjustmentCard } from './GoalAdjustmentCard'
import { Header } from './Header'
import { formatGoalValue } from './ReportPDF'

export type GoalAdjustmentsPageProps = {
  header: TReportHeader
  goal_adjustments: TAdjustments[]
}

export const GoalAdjustmentsPage = ({ header, goal_adjustments }: GoalAdjustmentsPageProps) => {
  return (
    <>
      {goal_adjustments?.map((goal, index) => {
        return (
          <Page size="A4" style={styles.page} key={index}>
            <Header header={{ ...header }} />

            <View style={styles.mainContent} key={index}>
              <Text style={styles.goalTitle}>Metas {goal.type}</Text>

              <Text style={styles.goalSubtitle}>
                {formatReferenceMonth(correctDate(String(goal.year_month)))} •{' '}
                {formatGoalValue(goal.type, goal.goal)}
              </Text>

              {goal.adjustments.map((item, index) => (
                <GoalAdjustmentCard goalType={goal.type} key={index} {...item} />
              ))}
            </View>

            <View style={styles.pageFooterContainer} fixed>
              <View style={styles.pageCounter}>
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber }) => `${pageNumber}`}
                  fixed
                />
                <Text style={styles.separator}>/</Text>
                <Text
                  style={styles.totalPages}
                  render={({ totalPages }) => `${totalPages}`}
                  fixed
                />
              </View>
            </View>
          </Page>
        )
      })}
    </>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 40,
    paddingBottom: 52,
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    backgroundColor: '#FFFFFF'
  },
  mainContent: {
    marginTop: 20
  },
  goalTitle: {
    fontSize: 24,
    color: '#000',
    fontWeight: 'bold'
  },
  goalSubtitle: {
    fontSize: 14,
    color: '#000',
    fontWeight: 'bold',
    paddingBottom: 8
  },
  pageFooterContainer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
  pageCounter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#DDD',
    paddingHorizontal: 10,
    paddingVertical: 2,
    alignSelf: 'center',
    width: 50
  },
  pageNumber: {
    color: '#F3123C',
    fontSize: 10,
    fontWeight: 'bold'
  },
  separator: {
    color: '#999',
    fontSize: 10,
    paddingHorizontal: 3
  },
  totalPages: {
    color: '#999',
    fontSize: 10
  }
})
