import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'

import { RVCalculatorsSimProps } from './RVCalculatorsSim.types'
import { useRVCalculatorsSim } from './useRVCalculatorsSim'

const RVCalculatorsSimDesktop = lazy(() => import('./RVCalculatorsSim.desktop'))

export const RVCalculatorsSim: FunctionComponent<RVCalculatorsSimProps> = (props) => {
  const RVCalculatorsSim = useRVCalculatorsSim(props)

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <RVCalculatorsSimDesktop {...RVCalculatorsSim} />
    </Suspense>
  )
}
