import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import {
  TParams,
  TGoalsStaffListItem,
  TGoalsLeaderDetails,
  TOpenGoalsPeriodParams,
  TGoalsSummary,
  TGoalsLeadersListItem,
  TListsParams,
  TChangePeriodParams,
  TGoalsDetails,
  TGoalsReportSerialized
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_ADMIN_SUMMARY_QUERY = 'getGoalsSummary'

export const KEY_LEADERS_LIST_QUERY = 'getGoalsLeadersList'

export const KEY_LEADER_SUMMARY_QUERY = 'getGoalsLeaderDetails'

export const KEY_STAFF_LIST_QUERY = 'getStaffList'

export const KEY_GOALS_DETAILS_QUERY = 'getGoalsDetails'

export const KEY_REPORT_QUERY = 'getGoalsReport'

export const useOpenGoalsPeriodMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TOpenGoalsPeriodParams>>,
    AxiosError<ResponseError>,
    TOpenGoalsPeriodParams
  >(requests.openGoalsPeriod, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_ADMIN_SUMMARY_QUERY])

      queryClient.invalidateQueries([KEY_LEADERS_LIST_QUERY])

      queryClient.invalidateQueries([KEY_LEADER_SUMMARY_QUERY])

      queryClient.invalidateQueries([KEY_STAFF_LIST_QUERY])
    }
  })
}

export const useUpdateGoalsPeriodMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TChangePeriodParams>>,
    AxiosError<ResponseError>,
    TChangePeriodParams
  >(requests.updateGoalsPeriodStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_ADMIN_SUMMARY_QUERY])

      queryClient.invalidateQueries([KEY_LEADERS_LIST_QUERY])

      queryClient.invalidateQueries([KEY_LEADER_SUMMARY_QUERY])

      queryClient.invalidateQueries([KEY_STAFF_LIST_QUERY])
    }
  })
}

export const useGetGoalsSummaryQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<TGoalsSummary>>, AxiosError<ResponseError>>(
    [KEY_ADMIN_SUMMARY_QUERY],
    () => requests.getGoalsSummary()
  )

export const useGetGoalsLeadersListInfinityQuery = ({ enabled = true, ...params }: TListsParams) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<TGoalsLeadersListItem[]>>,
    AxiosError<ResponseError>
  >(
    [KEY_LEADERS_LIST_QUERY, params],
    ({ pageParam }) => requests.getGoalsLeadersList({ ...params, page: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination?.current_page <
        lastPage.data.metadata.pagination?.last_page
          ? lastPage.data.metadata.pagination?.current_page + 1
          : false
    }
  )

export const useGetGoalsStaffListInfinityQuery = ({ enabled = true, ...params }: TListsParams) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<TGoalsStaffListItem[]>>,
    AxiosError<ResponseError>
  >(
    [KEY_STAFF_LIST_QUERY, params],
    ({ pageParam }) => requests.getGoalsStaffList({ ...params, page: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetGoalsLeaderDetailsSummaryQuery = ({
  leader_std_code
}: {
  leader_std_code: string
}) => {
  return useQuery<AxiosResponse<ResponseSuccess<TGoalsLeaderDetails>>, AxiosError<ResponseError>>(
    [KEY_LEADER_SUMMARY_QUERY, leader_std_code],
    () => requests.getGoalsLeaderDetailsSummary({ leader_std_code })
  )
}

export const useGetGoalsDetailsQuery = ({
  enabled = true,
  staffId
}: {
  enabled: boolean
  staffId: string
}) => {
  return useQuery<AxiosResponse<ResponseSuccess<TGoalsDetails>>, AxiosError<ResponseError>>(
    [KEY_GOALS_DETAILS_QUERY, staffId],
    () => requests.getGoalsDetails(staffId),
    { enabled }
  )
}

export const useSaveGoalsAdjustmentsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<ResponseSuccess<TParams>>, AxiosError<ResponseError>, TParams>(
    requests.saveGoalsAdjustments,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_ADMIN_SUMMARY_QUERY])

        queryClient.invalidateQueries([KEY_LEADERS_LIST_QUERY])

        queryClient.invalidateQueries([KEY_LEADER_SUMMARY_QUERY])

        queryClient.invalidateQueries([KEY_STAFF_LIST_QUERY])

        queryClient.invalidateQueries([KEY_GOALS_DETAILS_QUERY])
      }
    }
  )
}

export const useGetGoalsReportQuery = ({
  enabled = true,
  leader_std_code
}: {
  enabled?: boolean
  leader_std_code: string
}) => {
  return useQuery<
    AxiosResponse<ResponseSuccess<TGoalsReportSerialized>>,
    AxiosError<ResponseError>
  >([KEY_REPORT_QUERY, leader_std_code], () => requests.getGoalsReport({ leader_std_code }), {
    enabled
  })
}
