import { useCallback, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import {
  TGoalsStaffListItem,
  useGetGoalsStaffListInfinityQuery,
  useGetGoalsLeaderDetailsSummaryQuery,
  INDIVIDUAL_PERIOD_STATUS_ENUM,
  useGetGoalsReportQuery,
  META_TYPE_ENUM,
  useUpdateGoalsPeriodMutation
} from 'integration/resources/goals'
import {
  DIRECTORY_TYPE_ENUM,
  EXTENSION_TYPE_ENUM,
  useUploadMutation
} from 'integration/resources/upload'
import { useSaveReportFileMutation } from 'integration/resources/walletsManagement'
import { useBreakpointValue, useDisclose } from 'native-base'
import useDebounce from 'src/hooks/useDebounce'
import { useAuthAtomValue } from 'src/store/auth'

import { OrderField, UseGoalsLeaderDetailsScreen } from './GoalsLeaderDetailsScreen.types'
import { ReportPDF } from '../Components/ReportPDF/ReportPDF'

const mergeGoalsStaffInfiniteQuery = (
  data: ReturnType<typeof useGetGoalsStaffListInfinityQuery>['data']
) =>
  data?.pages.reduce<TGoalsStaffListItem[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Valor da meta',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o Menor' }
    ]
  }
]

export const useGoalsLeaderDetailsScreen: UseGoalsLeaderDetailsScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtomValue = useAuthAtomValue()

  const leaderStdCode = String(route?.params?.userStdCode ?? authAtomValue?.user?.std_code ?? 0)

  const isAdmin = useMemo(
    () => !!(authAtomValue?.user?.is_admin && leaderStdCode),
    [authAtomValue?.user?.is_admin, leaderStdCode]
  )

  const linkTo = useLinkTo()

  const handleGoBack = () => linkTo('/metas-admin')

  const { data: leaderDetailsData, isLoading: leaderDetailsIsLoading } =
    useGetGoalsLeaderDetailsSummaryQuery({
      leader_std_code: leaderStdCode
    })

  const detailsData = leaderDetailsData?.data.data

  const [currentList, setCurrentList] = useState<META_TYPE_ENUM>(META_TYPE_ENUM.Production)

  const handleListChange = (list: META_TYPE_ENUM) => {
    setCurrentList(list)
  }

  const [search, onSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search, 500)

  const handleSearchClear = () => {
    onSearch('')
  }

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const [appliedOrderBy, setAppliedOrderBy] = useState<'user_name' | 'meta_value' | undefined>()

  const [appliedOrderByDirection, setAppliedOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >()

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  const handleApply = () => {
    setAppliedOrderBy(
      selectedOrderBy?.field
        ? selectedOrderBy?.field === 'Nome'
          ? 'user_name'
          : 'meta_value'
        : undefined
    )

    setAppliedOrderByDirection(selectedOrderBy?.direction)
  }

  const {
    data: staffListData,
    isLoading: staffListLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetGoalsStaffListInfinityQuery({
    leader_std_code: leaderStdCode,
    order_by: appliedOrderBy,
    order_by_direction: appliedOrderByDirection,
    meta_type: currentList,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const staffList = useMemo(() => mergeGoalsStaffInfiniteQuery(staffListData), [staffListData])

  const staffListTotalItems = staffList.length

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const handleGoToOpenGoalsPeriod = useCallback(() => {
    navigation.navigate('OpenGoalsPeriod', {
      leaderStdCode
    })
  }, [navigation, leaderStdCode])

  const {
    isOpen: reactivateModalIsOpen,
    onClose: reactivateModalOnClose,
    onOpen: reactivateModalOnOpen
  } = useDisclose()

  const { mutateAsync: updatePeriod, isLoading: updatePeriodIsLoading } =
    useUpdateGoalsPeriodMutation()

  const handleReactivate = useCallback(() => {
    detailsData?.id &&
      updatePeriod(
        {
          periodId: detailsData?.id,
          status: INDIVIDUAL_PERIOD_STATUS_ENUM.REACTIVATED,
          leader_std_code: Number(leaderStdCode ?? 0)
        },
        {
          onError: () => {
            reactivateModalOnClose()
          },
          onSuccess: () => {
            reactivateModalOnClose()
          }
        }
      )
  }, [reactivateModalOnClose, detailsData?.id, updatePeriod, leaderStdCode])

  const {
    isOpen: concludeModalIsOpen,
    onClose: concludeModalOnClose,
    onOpen: concludeModalOnOpen
  } = useDisclose()

  const handleConclude = useCallback(() => {
    detailsData?.id &&
      updatePeriod(
        {
          periodId: detailsData?.id,
          status: INDIVIDUAL_PERIOD_STATUS_ENUM.COMPLETED,
          leader_std_code: Number(leaderStdCode ?? 0)
        },
        {
          onError: () => {
            concludeModalOnClose()
          },
          onSuccess: () => {
            concludeModalOnClose()
          }
        }
      )
  }, [concludeModalOnClose, detailsData?.id, updatePeriod, leaderStdCode])

  const { data: goalsReportData, isLoading: goalsReportIsLoading } = useGetGoalsReportQuery({
    leader_std_code: String(leaderStdCode ?? 0),
    enabled: true
  })

  const { mutateAsync: saveFileMutationAsync, isLoading: saveFileIsLoading } =
    useSaveReportFileMutation()

  const { mutateAsync: uploadMutationAsync, isLoading: isUploadLoading } = useUploadMutation()

  const reportData = goalsReportData?.data.data

  const [downloadReportIsLoading, setDownloadReportIsLoading] = useState(false)

  const handleDownloadReport = useCallback(async () => {
    setDownloadReportIsLoading(true)

    if (!reportData?.file_url) {
      try {
        const blob = await pdf(<ReportPDF {...reportData} />).toBlob()

        const file = blob

        if (file.type && file.type.split('/').length > 0) {
          const type = file.type.split('/')[1]

          const files = await uploadMutationAsync({
            directory_type: DIRECTORY_TYPE_ENUM.GOALS_REPORTS,
            extension_type: type.toUpperCase() as keyof typeof EXTENSION_TYPE_ENUM,
            files: [file]
          })

          if (files.length) {
            if (files[0].status !== 200) {
              console.log('erro')

              return
            }

            const fileName = files[0].name

            saveFileMutationAsync({
              leaderStdCode: Number(leaderStdCode),
              fileName
            })
          }
        }

        saveAs(file, 'Relatorio Ajuste de Metas.pdf')
      } catch (error) {
        console.error('Erro ao gerar o PDF:', error)
      }
    } else {
      const response = await fetch(reportData?.file_url)

      const file = await response.blob()

      saveAs(file, 'Relatorio Ajuste de Metas.pdf')
    }

    setDownloadReportIsLoading(false)
  }, [reportData, leaderStdCode, saveFileMutationAsync, uploadMutationAsync])

  const {
    isOpen: goalAdjustmentModalIsOpen,
    onClose: goalAdjustmentModalOnClose,
    onOpen: goalAdjustmentModalOnOpen
  } = useDisclose()

  const [adjustmentStaffInfos, setAdjustmentStaffInfos] = useState<{
    name: string
    occupation: string
  }>()

  const handleOpenGoalAdjustment = (staffId: string, name: string, occupation: string) => {
    navigation.navigate('GoalsAdjustment', {
      staffId: String(staffId),
      infos: {
        name,
        occupation
      },
      currentList
    })

    setAdjustmentStaffInfos({
      name,
      occupation
    })

    goalAdjustmentModalOnOpen()
  }

  const [openedTooltip, setOpenedTooltip] = useState<string | undefined>()

  const handleOpenTooltip = (title?: string) => {
    setOpenedTooltip(title)
  }

  const anyHookIsLoading =
    leaderDetailsIsLoading ||
    staffListLoading ||
    downloadReportIsLoading ||
    goalsReportIsLoading ||
    updatePeriodIsLoading ||
    isUploadLoading ||
    saveFileIsLoading

  const deltasIsOk =
    Object.entries(detailsData?.deltas ?? {}).filter(([key, value]) => Number(value) !== 0)
      .length === 0

  const periodIsOpen =
    detailsData?.status === INDIVIDUAL_PERIOD_STATUS_ENUM.OPENED ||
    detailsData?.status === INDIVIDUAL_PERIOD_STATUS_ENUM.REACTIVATED

  return {
    isMobile,
    isAdmin,
    handleGoBack,
    detailsData,
    leaderDetailsIsLoading,
    currentList,
    handleListChange,
    staffListData: staffList,
    staffListIsLoading: staffListLoading,
    staffListIsFetchingNextPage: isFetchingNextPage,
    staffListTotalItems,
    onEndReached,
    search,
    onSearch,
    onPressSearchClear: handleSearchClear,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    handleGoToOpenGoalsPeriod,
    reactivateModalIsOpen,
    reactivateModalOnClose,
    reactivateModalOnOpen,
    handleReactivate,
    anyHookIsLoading,
    handleDownloadReport,
    downloadReportIsLoading,
    concludeModalIsOpen,
    concludeModalOnClose,
    concludeModalOnOpen,
    handleConclude,
    goalAdjustmentModalIsOpen,
    goalAdjustmentModalOnClose,
    handleOpenGoalAdjustment,
    openedTooltip,
    handleOpenTooltip,
    adjustmentStaffInfos,
    updatePeriodIsLoading,
    deltasIsOk,
    periodIsOpen
  }
}
