import { useMemo } from 'react'

import { PROFILE_BUSINESS_CODE_ENUM } from 'integration/resources/auth'
import {
  useGetContractsAmountQuery,
  useGetCreditInsuranceQuery,
  useGetMarketShareQuery,
  useGetPotentialQuery,
  useGetProductionQuery,
  useGetProposalFunnelQuery,
  useGetSantanderAutoInsuranceQuery,
  useGetSantanderAutoTypeQuery,
  useGetSpreadPercentageQuery,
  useGetWebmotorsQuery,
  useGetApnefQuery
} from 'integration/resources/panels'
import { useAuthAtomValue } from 'src/store/auth'
import { getShortMonthAndYear } from 'src/utils'

import { UseTabPanels } from './types'

const verifyHasData = (key: string, data: any[]) => {
  const INITIAL_VALUE_REDUCE = 0

  const values = data.map((item) => item[key])

  const sum = values.reduce((acc, value) => acc + value, INITIAL_VALUE_REDUCE)

  return sum > 0
}

export const useTabPanels: UseTabPanels = ({ tabNumber }) => {
  const authAtom = useAuthAtomValue()

  const isGoodsAndServices = useMemo(
    () => authAtom?.user.business_code === PROFILE_BUSINESS_CODE_ENUM.GOODS_AND_SERVICES,
    [authAtom?.user.business_code]
  )

  const { data: apnefData, isLoading: apnefDataIsLoading } = useGetApnefQuery(tabNumber)

  const { data: proposalFunnel, isLoading: proposalFunnelDataIsLoading } =
    useGetProposalFunnelQuery(tabNumber)

  const proposalFunnelData = useMemo(() => {
    return {
      dataSerialized: proposalFunnel?.data.data.dataSerialized,
      processed_at: proposalFunnel?.data.data.processed_at,
      is_short_format: proposalFunnel?.data.data?.is_short_format ?? false,
      higherValue: proposalFunnel?.data.data.higherValue,
      hasData: verifyHasData('value', proposalFunnel?.data.data.dataSerialized ?? [])
    }
  }, [proposalFunnel])

  const { data: production, isLoading: productionDataIsLoading } = useGetProductionQuery({
    tab_number: tabNumber,
    year_month_qty: 6
  })

  const productionData = useMemo(() => {
    return {
      production: production?.data.data.production,
      processed_at: production?.data.data.processed_at,
      is_short_format: production?.data.data?.is_short_format ?? false,
      higherValue: production?.data.data.higherValue,
      hasData: verifyHasData('value', production?.data.data.production ?? [])
    }
  }, [production])

  const { data: contractsAmount, isLoading: contractsAmountDataIsLoading } =
    useGetContractsAmountQuery({ tab_number: tabNumber, year_month_qty: 3 })

  const contractsAmountData = useMemo(() => {
    return {
      contracts_quantity: contractsAmount?.data.data.contracts_quantity,
      insurance_auto_contracts_quantity:
        contractsAmount?.data.data.insurance_auto_contracts_quantity,
      insurance_contracts_quantity: contractsAmount?.data.data.insurance_contracts_quantity,
      tickValues: contractsAmount?.data.data.months.map((item: { year_month: number }) =>
        getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: contractsAmount?.data.data.processed_at,
      is_short_format: contractsAmount?.data.data?.is_short_format ?? false,
      hasData: verifyHasData('value', [
        ...(contractsAmount?.data?.data?.contracts_quantity ?? []),
        ...(contractsAmount?.data?.data?.insurance_contracts_quantity ?? []),
        ...(contractsAmount?.data?.data?.insurance_auto_contracts_quantity ?? [])
      ])
    }
  }, [contractsAmount])

  const { data: spreadPercentage, isLoading: spreadPercentageDataIsLoading } =
    useGetSpreadPercentageQuery({
      tab_number: tabNumber,
      year_month_qty: 3,
      enabled: !productionDataIsLoading && !contractsAmountDataIsLoading
    })

  const spreadPercentageData = useMemo(() => {
    return {
      net_spread: spreadPercentage?.data.data.net_spread,
      gross_spread: spreadPercentage?.data.data.gross_spread,
      tickValues: spreadPercentage?.data.data.months.map((item: { year_month: number }) =>
        getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: spreadPercentage?.data.data.processed_at,
      is_short_format: spreadPercentage?.data.data?.is_short_format ?? false,
      hasData: verifyHasData('value', [
        ...(spreadPercentage?.data?.data?.gross_spread ?? []),
        ...(spreadPercentage?.data?.data?.net_spread ?? [])
      ])
    }
  }, [spreadPercentage])

  const { data: potential, isLoading: potentialDataIsLoading } = useGetPotentialQuery({
    tab_number: tabNumber,
    year_month_qty: 6,
    enabled: !spreadPercentageDataIsLoading
  })

  const potentialData = useMemo(() => {
    return {
      potential: potential?.data.data.potential,
      processed_at: potential?.data.data.processed_at,
      is_short_format: potential?.data.data?.is_short_format ?? false,
      higherValue: potential?.data.data.higherValue,
      hasData: verifyHasData('value', potential?.data?.data?.potential ?? [])
    }
  }, [potential])

  const { data: marketShare, isLoading: marketShareDataIsLoading } = useGetMarketShareQuery({
    tab_number: tabNumber,
    year_month_qty: 6,
    enabled: !potentialDataIsLoading
  })

  const marketShareData = useMemo(() => {
    return {
      actual_share: marketShare?.data.data.actual_share,
      market_visualization: marketShare?.data.data.market_visualization,
      market_visualization_processed_at: marketShare?.data.data.market_visualization_processed_at,
      tickValues: marketShare?.data.data.months.map((item: { year_month: number }) =>
        getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: marketShare?.data.data.processed_at,
      is_short_format: marketShare?.data.data?.is_short_format ?? false,
      higherValueShare: marketShare?.data.data.higherValueShare,
      higherValueVisualization: marketShare?.data.data.higherValueVisualization,
      actual_share_hasData: verifyHasData('value', [
        ...(marketShare?.data?.data?.actual_share ?? [])
      ]),
      market_visualization_hasData: verifyHasData('value', [
        ...(marketShare?.data?.data?.market_visualization ?? [])
      ])
    }
  }, [marketShare])

  const { data: creditInsurance, isLoading: creditInsuranceDataIsLoading } =
    useGetCreditInsuranceQuery({
      tab_number: tabNumber,
      year_month_qty: 3,
      enabled: !marketShareDataIsLoading
    })

  const creditInsuranceData = useMemo(() => {
    return {
      ip_insurance_spf_qty: creditInsurance?.data.data.ip_insurance_spf_qty,
      ip_insurance_spf_volume: creditInsurance?.data.data.ip_insurance_spf_volume,
      tickValues: creditInsurance?.data.data.months.map((item: { year_month: number }) =>
        getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: creditInsurance?.data.data.processed_at,
      is_short_format: creditInsurance?.data.data?.is_short_format ?? false,
      hasData: verifyHasData('value', [
        ...(creditInsurance?.data?.data?.ip_insurance_spf_qty ?? []),
        ...(creditInsurance?.data?.data?.ip_insurance_spf_volume ?? [])
      ])
    }
  }, [creditInsurance])

  const { data: santanderAutoInsurance, isLoading: santanderAutoInsuranceDataIsLoading } =
    useGetSantanderAutoInsuranceQuery({
      tab_number: tabNumber,
      year_month_qty: 6,
      enabled: !creditInsuranceDataIsLoading
    })

  const santanderAutoInsuranceData = useMemo(() => {
    return {
      ip_auto_insurance_qty: santanderAutoInsurance?.data.data.ip_auto_insurance_qty,
      processed_at: santanderAutoInsurance?.data.data.processed_at,
      is_short_format: santanderAutoInsurance?.data.data?.is_short_format ?? false,
      higherValue: santanderAutoInsurance?.data.data.higherValue,
      hasData: verifyHasData('value', [
        ...(santanderAutoInsurance?.data?.data?.ip_auto_insurance_qty ?? [])
      ])
    }
  }, [santanderAutoInsurance])

  const { data: santanderAutoType, isLoading: santanderAutoTypeDataIsLoading } =
    useGetSantanderAutoTypeQuery({
      tab_number: tabNumber,
      year_month_qty: 3,
      enabled: !santanderAutoInsuranceDataIsLoading
    })

  const santanderAutoTypeData = useMemo(() => {
    return {
      complete_insurance: santanderAutoType?.data.data.complete_insurance,
      fire_theft_insurance: santanderAutoType?.data.data.fire_theft_insurance,
      loose_insurance: santanderAutoType?.data.data.loose_insurance,
      third_party_insurance: santanderAutoType?.data.data.third_party_insurance,
      tickValues: santanderAutoType?.data.data.months.map((item: { year_month: number }) =>
        getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: santanderAutoType?.data.data.processed_at,
      is_short_format: santanderAutoType?.data.data?.is_short_format ?? false,
      hasData: verifyHasData('value', [
        ...(santanderAutoType?.data?.data?.complete_insurance ?? []),
        ...(santanderAutoType?.data?.data?.fire_theft_insurance ?? []),
        ...(santanderAutoType?.data?.data?.loose_insurance ?? []),
        ...(santanderAutoType?.data?.data?.third_party_insurance ?? [])
      ])
    }
  }, [santanderAutoType])

  const { data: webmotors, isLoading: webmotorsDataIsLoading } = useGetWebmotorsQuery({
    tab_number: tabNumber,
    year_month_qty: 6,
    enabled: !santanderAutoTypeDataIsLoading
  })

  const webmotorsData = useMemo(() => {
    return {
      web_realized_value: webmotors?.data.data.web_realized_value,
      processed_at: webmotors?.data.data.processed_at,
      is_short_format: webmotors?.data.data?.is_short_format ?? false,
      higherValue: webmotors?.data.data.higherValue,
      hasData: verifyHasData('value', [...(webmotors?.data?.data?.web_realized_value ?? [])])
    }
  }, [webmotors])

  return {
    apnefData,
    apnefDataIsLoading,
    proposalFunnelData,
    proposalFunnelDataIsLoading,
    productionData,
    productionDataIsLoading,
    contractsAmountData,
    contractsAmountDataIsLoading,
    spreadPercentageData,
    spreadPercentageDataIsLoading,
    potentialData,
    potentialDataIsLoading,
    marketShareData,
    marketShareDataIsLoading,
    creditInsuranceData,
    creditInsuranceDataIsLoading,
    santanderAutoInsuranceData,
    santanderAutoInsuranceDataIsLoading,
    santanderAutoTypeData,
    santanderAutoTypeDataIsLoading,
    webmotorsData,
    webmotorsDataIsLoading,
    isGoodsAndServices
  }
}
