import { useCallback } from 'react'

import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { USER_STATUS_ENUM, useAcceptTermsMutation } from 'integration/resources/auth'
import { useBreakpointValue, useDisclose } from 'native-base'
import { useAuthAtom } from 'src/store/auth'

import { UseAcceptTermsScreen } from './AcceptTermsScreen.types'

export const useAcceptTermsScreen: UseAcceptTermsScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const canGoBack = navigation?.canGoBack()

  const handleGoBack = useCallback(() => navigation?.goBack(), [navigation])

  const { isOpen: isConfirmed, onToggle: handleToggleConfirm } = useDisclose(false)

  const [authAtom, setAuthAtom] = useAuthAtom()

  const acceptTermsMutation = useAcceptTermsMutation()

  const bottomSheetContext = useBottomSheetContext()

  const handleOnAccept = useCallback(
    () =>
      acceptTermsMutation.mutate(undefined, {
        onError: () =>
          bottomSheetContext.open({
            description: 'Não foi possível aceitar os termos, tente novamente mais tarde.',
            title: 'Erro ao aceitar termos'
          }),
        onSuccess: () => {
          if (authAtom) {
            /**
             * NOTE: RootStackNavigator is listening for authAtom.
             * Once authAtom change its value, the RootStackNavigator will handle the transition to either the Onboarding screen or the Home screen.
             */
            setAuthAtom({
              ...authAtom,
              waiting_onboarding: true,
              user: {
                ...authAtom.user,
                status: USER_STATUS_ENUM.WAITING_ONBOARDING,
                accepted_commitment_term_at: new Date().toString(),
                accepted_responsibility_term_at: new Date().toString()
              }
            })
          }
        }
      }),
    [acceptTermsMutation, authAtom, bottomSheetContext, setAuthAtom]
  )

  const handleGoToRefuseTerms = useCallback(() => navigation?.navigate('RefuseTerms'), [navigation])

  return {
    isMobile,
    canGoBack,
    handleGoBack,
    handleToggleConfirm,
    isConfirmed,
    isLoading: acceptTermsMutation.isLoading,
    handleOnAccept,
    handleGoToRefuseTerms
  }
}
