import { ATTACHMENT_EXTENSION_ENUM } from 'integration/resources/knowledgeBase'

export const selectIcon = (extension: string) => {
  switch (extension) {
    case ATTACHMENT_EXTENSION_ENUM.PDF:
      return 'PDF'

    case ATTACHMENT_EXTENSION_ENUM.PNG:
      return 'PNG'

    case ATTACHMENT_EXTENSION_ENUM.MP3:
      return 'MP3'

    case ATTACHMENT_EXTENSION_ENUM.ZIP:
      return 'ZIP'

    case ATTACHMENT_EXTENSION_ENUM.JPEG:
      return 'JPG'

    case ATTACHMENT_EXTENSION_ENUM.JPG:
      return 'JPG'

    case ATTACHMENT_EXTENSION_ENUM.CSV:
      return 'CSV'

    case ATTACHMENT_EXTENSION_ENUM.DOC:
      return 'DOC'

    case ATTACHMENT_EXTENSION_ENUM.DOCX:
      return 'DOCX'

    case ATTACHMENT_EXTENSION_ENUM.MP4:
      return 'MP4'

    case ATTACHMENT_EXTENSION_ENUM.PPT:
      return 'PPT'

    case ATTACHMENT_EXTENSION_ENUM.PPTX:
      return 'PPT'

    case ATTACHMENT_EXTENSION_ENUM.XLS:
      return 'XLS'

    case ATTACHMENT_EXTENSION_ENUM.XLSX:
      return 'XLSX'

    case ATTACHMENT_EXTENSION_ENUM.XLSM:
      return 'XLSM'

    default:
      return 'File'
  }
}
