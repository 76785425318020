import * as serializers from './serializers'
import {
  TParams,
  TGoalsStaffListItem,
  TGoalsLeaderDetails,
  TOpenGoalsPeriodParams,
  TGoalsSummary,
  TGoalsLeadersListItem,
  TListsParams,
  TChangePeriodParams,
  TGoalsDetails,
  TReport
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getGoalsSummary = async () =>
  await client.get<ResponseSuccess<TGoalsSummary>>(`v1/goals-management/summary`)

export const getGoalsLeadersList = async (params: TListsParams) => {
  const response = await client.get<ResponseSuccess<TGoalsLeadersListItem[]>>(
    `v1/goals-management/goals`,
    {
      params: {
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        order_by: params.order_by ?? undefined,
        order_by_direction: params.order_by_direction ?? 'desc',
        ...params
      }
    }
  )

  return response
}

export const openGoalsPeriod = async ({
  date_start,
  date_end,
  leader_std_code,
  business,
  goals
}: TOpenGoalsPeriodParams) => {
  return await client.post<ResponseSuccess<TOpenGoalsPeriodParams>>(`v1/goals-management/period`, {
    date_start,
    date_end,
    business,
    leader_std_code: leader_std_code ?? undefined,
    goals
  })
}

export const updateGoalsPeriodStatus = async ({
  periodId,
  status,
  leader_std_code
}: TChangePeriodParams) => {
  return await client.put<ResponseSuccess<TChangePeriodParams>>(
    `v1/goals-management/period/status`,
    {
      period_id: periodId,
      status,
      leader_std_code
    }
  )
}

export const getGoalsLeaderDetailsSummary = async (params: TParams) => {
  const response = await client.get<ResponseSuccess<TGoalsLeaderDetails>>(
    `v1/goals-management/summary/${params.leader_std_code ?? 0}`,
    {}
  )

  return response
}

export const getGoalsStaffList = async (params: TListsParams) => {
  const response = await client.get<ResponseSuccess<TGoalsStaffListItem[]>>(
    `v1/goals-management/goals/${params.leader_std_code}`,
    {
      params: {
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        order_by: params.order_by ?? undefined,
        order_by_direction: params.order_by_direction ?? 'desc',
        ...params
      }
    }
  )

  return response
}

export const getGoalsDetails = async (staffId: string) => {
  const response = await client.get<ResponseSuccess<TGoalsDetails>>(
    `v1/goals-management/goals/details/${staffId}`
  )

  return response
}

export const saveGoalsAdjustments = async ({ goals_adjustments }: TParams) =>
  await client.post<ResponseSuccess<TParams>>(`v1/goals-management/goals/details`, {
    ...goals_adjustments
  })

export const getGoalsReport = async (params: TParams) => {
  const response = await client.get<ResponseSuccess<TReport>>(
    `v1/goals-management/report/${params.leader_std_code}`,
    {}
  )

  return serializers.getGoalsReport(response)
}
