export enum DIRECTORY_TYPE_ENUM {
  CONTACT_ATTACHMENT = 'CONTACT_ATTACHMENT',
  CONTACT_COVER_IMAGE = 'CONTACT_COVER_IMAGE',
  COMPANY_LOGO = 'COMPANY_LOGO',
  PROFILE_IMAGES = 'PROFILE_IMAGES',
  STORE_COVER = 'STORE_COVER',
  PRODUCT_COVER = 'PRODUCT_COVER',
  STORE_ATTACHMENT = 'STORE_ATTACHMENT',
  KNOWLEDGE_BASE_CONTENT_COVER = 'KNOWLEDGE_BASE_CONTENT_COVER',
  KNOWLEDGE_BASE_CONTENT_BODY_IMAGES = 'KNOWLEDGE_BASE_CONTENT_BODY_IMAGES',
  KNOWLEDGE_BASE_CONTENT_ATTACHMENTS = 'KNOWLEDGE_BASE_CONTENT_ATTACHMENTS',
  ACTIVITY_ATTACHMENTS = 'ACTIVITY_ATTACHMENTS',
  WALLETS_MANAGEMENT_REPORTS = 'WALLETS_MANAGEMENT_REPORTS',
  GOALS_REPORTS = 'GOALS_REPORTS'
}

export enum EXTENSION_TYPE_ENUM {
  CSV = 'CSV',
  DOC = 'DOC',
  DOCX = 'DOCX',
  JPEG = 'JPEG',
  JPG = 'JPG',
  MP4 = 'MP4',
  MP3 = 'MP3',
  PDF = 'PDF',
  PNG = 'PNG',
  PPT = 'PPT',
  PPTX = 'PPTX',
  XLSX = 'XLSX',
  XLS = 'XLS',
  XLSM = 'XLSM',
  ZIP = 'ZIP'
}

export type PostFileParams = {
  directory_type: keyof typeof DIRECTORY_TYPE_ENUM
  extension_type: keyof typeof EXTENSION_TYPE_ENUM
}

export type PresignedUrlResponse = {
  url: string
  file_name: string
}

export type PutUploadFile = {
  files: string[] // uri local file
}

export type UploadFilePayload = PostFileParams & {
  files: Blob[]
}

export type UploadFileResponse = {
  url: string
  name: string
  status: number
}
