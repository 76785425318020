import { FunctionComponent, lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

import { PANELS_TYPES_ENUM } from './constants'

type TabPanelsProps = {
  handleGoToFullChartScreen(type: PANELS_TYPES_ENUM): void
  tabNumber: string
}

const TabPanelsMobile = lazy(() => import('./TabPanels.mobile'))

const TabPanelsDesktop = lazy(() => import('./TabPanels.desktop'))

export const TabPanels: FunctionComponent<TabPanelsProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? TabPanelsMobile : TabPanelsDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...props} />
    </Suspense>
  )
}
