import * as serializers from './serializers'
import {
  FinishParams,
  GetListParams,
  GetOpportunityFiltersList,
  OpportunitiesItem,
  OpportunitiesSummary,
  OpportunityDetailsItem,
  OpportunityDetailsItemSerialized,
  OPPORTUNITY_TYPE_ENUM
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getOpportunitiesSummary = async () =>
  serializers.getOpportunitySummary(
    await client.get<ResponseSuccess<OpportunitiesSummary>>('v2/opportunities/summary')
  )

export const getOpportunitiesODCList = async (params: GetListParams) =>
  await client.get<ResponseSuccess<OpportunitiesItem[]>>('v2/opportunities/odcs', {
    params: {
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1,
      ...params
    }
  })

export const getOpportunitiesActiveBaseList = async (params: GetListParams) =>
  await client.get<ResponseSuccess<OpportunitiesItem[]>>('v2/opportunities/active-bases', {
    params: {
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1,
      ...params
    }
  })

export const getOpportunitiesInsuranceList = async (params: GetListParams) =>
  await client.get<ResponseSuccess<OpportunitiesItem[]>>('v2/opportunities/insurance-autos', {
    params: {
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1,
      ...params
    }
  })

export const getOpportunitiesChargeList = async (params: GetListParams) =>
  await client.get<ResponseSuccess<OpportunitiesItem[]>>('v2/opportunities/charges', {
    params: {
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1,
      ...params
    }
  })

export const getOpportunitiesHighlightedList = async (params: GetListParams) =>
  await client.get<ResponseSuccess<OpportunitiesItem[]>>('v2/opportunities/highlighted', {
    params: {
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1,
      ...params
    }
  })

export const getOpportunitiesFinishedList = async (params: GetListParams) =>
  await client.get<ResponseSuccess<OpportunitiesItem[]>>('v2/opportunities/finished', {
    params: {
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1,
      ...params
    }
  })

export const getOpportunitiesPendingList = async (params: GetListParams) =>
  await client.get<ResponseSuccess<OpportunitiesItem[]>>('v2/opportunities/pending', {
    params: {
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1,
      ...params
    }
  })

export const getOpportunityDetailsOdcItem = async (id?: string) =>
  serializers.getOpportunityDetailsItem(
    await client.get<ResponseSuccess<OpportunityDetailsItemSerialized>>(
      `v2/opportunities/odcs/${id}`
    )
  )

export const getOpportunityDetailsActiveBaseItem = async (id?: string) =>
  await client.get<ResponseSuccess<OpportunityDetailsItemSerialized>>(
    `v2/opportunities/active-bases/${id}`
  )

export const setOpportunityOdcHighlighted = async ({ id }: Pick<OpportunityDetailsItem, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<OpportunityDetailsItem, 'id'>>>(
    `v2/opportunities/odcs/${id}/highlighted`
  )

export const setOpportunityOdcUnHighlighted = async ({ id }: Pick<OpportunityDetailsItem, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<OpportunityDetailsItem, 'id'>>>(
    `v2/opportunities/odcs/${id}/unhighlighted`
  )

export const setOpportunityActiveBaseHighlighted = async ({
  id
}: Pick<OpportunityDetailsItem, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<OpportunityDetailsItem, 'id'>>>(
    `v2/opportunities/active-bases/${id}/highlighted`
  )

export const setOpportunityActiveBaseUnHighlighted = async ({
  id
}: Pick<OpportunityDetailsItem, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<OpportunityDetailsItem, 'id'>>>(
    `v2/opportunities/active-bases/${id}/unhighlighted`
  )

export const setFinishedOpportunityOdc = async (data: FinishParams) =>
  await client.patch<ResponseSuccess<FinishParams>>(`v2/opportunities/odcs/finished`, {
    ...data
  })

export const setUnFinishedOpportunityOdc = async (data: FinishParams) =>
  await client.patch<ResponseSuccess<FinishParams>>(`v2/opportunities/odcs/unfinished`, {
    ...data
  })

export const setFinishedOpportunityActiveBase = async (data: FinishParams) =>
  await client.patch<ResponseSuccess<FinishParams>>(`v2/opportunities/active-bases/finished`, {
    ...data
  })

export const setUnFinishedOpportunityActiveBase = async (data: FinishParams) =>
  await client.patch<ResponseSuccess<FinishParams>>(`v2/opportunities/active-bases/unfinished`, {
    ...data
  })

export const getFiltersList = async (opportunityType: OPPORTUNITY_TYPE_ENUM) =>
  await client.get<ResponseSuccess<GetOpportunityFiltersList>>(
    `v2/opportunities/filters?opportunity_type=${opportunityType}`,
    {}
  )
