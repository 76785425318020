import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'

import { createNavigationContainerRef } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { NotificationPush } from 'atoms'
import { AxiosError } from 'axios'
import { BlockScreenProvider } from 'contexts/BlockScreenContext'
import { format } from 'date-fns'
import * as ScreenOrientation from 'expo-screen-orientation'
import client from 'integration/client'
import { USER_STATUS_ENUM } from 'integration/resources/auth'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import * as Sentry from 'sentry-expo'
import screensNames from 'src/constants/screensNames'
import { useDeviceBlockControl } from 'src/hooks/useDeviceBlockControl'
import { AcceptTermsScreen, RefuseTermsScreen, OnboardingScreen, ChatScreen } from 'src/screens'
import { useAuthAtom } from 'src/store/auth'
import { useTokenAtom } from 'src/store/token'
import { LogEventUserId, LogEventScreen } from 'src/utils/logEvents'

import { linking } from './linking'
import { loggedScreens } from './loggedScreens'
import { noLoggedScreens } from './noLoggedScreens'
import { RootNavigationContainer } from './RootNavigationContainer'
import { RootStackParamList } from './types'

const NativeStackNavigator = createNativeStackNavigator<RootStackParamList>()

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

const screensRightSheet = [
  'Simulator',
  'SummaryDetail',
  'NotificationDetail',
  'PreferencesProfile',
  'ManageNotifications',
  'SecurityProfile',
  'RemoveAccountConfirm',
  'About',
  'EditProfile',
  'Content',
  'RemoveAccountConfirm',
  'ContactCreate',
  'ContactEdit',
  'CreateNoteDetails',
  'TabCreateNoteDetails',
  'TabEditNoteDetails',
  'ContactEditNoteDetails',
  'ContactCreateNoteDetails',
  'EditNoteDetails',
  'Opportunity',
  'OpportunityFilter',
  'OpportunityOrderByFilter',
  'SurveyDetail',
  'TabEdit',
  'OrganizationOpportunity',
  'OrganizationActivityCreate',
  'OrganizationActivityEdit',
  'OrganizationActivity',
  'Content',
  'Production',
  'LeadsApnef',
  'Recommendations',
  'ContractList',
  'ConcludeManagement',
  'OpenWalletsPeriod',
  'OpenGoalsPeriod',
  'GoalsAdjustment'
]

export const RootStackNavigator: FunctionComponent = () => {
  const refNavigation = createNavigationContainerRef<RootStackParamList>()

  const routeNameRef = useRef<string | null>()

  const [tokenAuthAtom, setTokenAuthAtom] = useTokenAtom()

  const [authAtom, setAuthAtom] = useAuthAtom()

  const queryClient = useQueryClient()

  const { verifyIfUserCanBeContinueLogged } = useDeviceBlockControl({ refNavigation })

  const userNeedsToAcceptTerms = useMemo(
    () =>
      authAtom &&
      authAtom?.user?.status === USER_STATUS_ENUM.AWAITING_ACCEPT_TERM &&
      (!authAtom?.user?.accepted_commitment_term_at ||
        !authAtom?.user?.accepted_responsibility_term_at),
    [authAtom]
  )

  const userWaitingOnboarding = useMemo(
    () =>
      (authAtom && authAtom?.user?.status === USER_STATUS_ENUM.WAITING_ONBOARDING) ||
      authAtom?.waiting_onboarding,
    [authAtom]
  )

  const screens = useMemo(() => {
    // user is logged
    if (authAtom && tokenAuthAtom) {
      // User needs to accept the terms
      if (userNeedsToAcceptTerms) {
        return (
          <>
            <NativeStackNavigator.Screen
              component={AcceptTermsScreen}
              name="AcceptTerms"
              options={{ title: screensNames['AcceptTerms'] }}
            />
            <NativeStackNavigator.Screen
              component={RefuseTermsScreen}
              name="RefuseTerms"
              options={{ title: screensNames['RefuseTerms'] }}
            />
            <NativeStackNavigator.Screen
              component={ChatScreen}
              name="Chat"
              options={{ title: screensNames['Chat'] }}
            />
          </>
        )
      }

      // userWaitingOnboarding
      // In the first access of the user we show the onboarding screen to him
      if (userWaitingOnboarding) {
        return (
          <NativeStackNavigator.Screen
            component={OnboardingScreen}
            name="Onboarding"
            options={{ title: screensNames['Onboarding'] }}
          />
        )
      }

      // logged screens
      return Object.entries(loggedScreens).map(([name, component]) => (
        <NativeStackNavigator.Screen
          // @ts-ignore
          component={component}
          key={name}
          name={name as keyof RootStackParamList}
          options={{
            gestureEnabled: name !== 'ProductionChart' && name !== 'Production' && name !== 'Chart',
            presentation:
              Platform.OS !== 'web'
                ? name === 'ContentByCategory'
                  ? Platform.OS === 'ios'
                    ? 'card'
                    : 'fullScreenModal'
                  : undefined
                : screensRightSheet.includes(name)
                ? 'transparentModal'
                : undefined,
            // @ts-ignore
            title: screensNames[name as keyof RootStackParamList]
          }}
        />
      ))
    }

    // Auth flow, create account and reset password
    return Object.entries(noLoggedScreens).map(([name, component]) => (
      <NativeStackNavigator.Screen
        // @ts-ignore
        component={component}
        key={name}
        name={name as keyof RootStackParamList}
        // @ts-ignore
        options={{ title: screensNames[name as keyof RootStackParamList] }}
      />
    ))
  }, [authAtom, userNeedsToAcceptTerms, userWaitingOnboarding, tokenAuthAtom])

  //redirects to login when users token expired
  useEffect(() => {
    client.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        //@ts-ignore
        if (error.response?.status >= 500) {
          refNavigation.current?.navigate('ErrorPageErrorOccurred', {})
        }

        if (error.response?.status !== 401) {
          if (Platform.OS === 'web') {
            Sentry.Browser.captureException(error)

            error.response?.data &&
              Sentry.Browser.captureMessage(JSON.stringify(error.response?.data))
          } else {
            Sentry.Native.captureException(error)

            error.response?.data &&
              Sentry.Native.captureMessage(JSON.stringify(error.response?.data))
          }
        }

        if (error.response?.status === 401 && !error.response?.config.url?.includes('auth')) {
          const logout = () => {
            LogEventUserId(null)

            setAuthAtom(null)

            setTokenAuthAtom(null)

            queryClient.invalidateQueries()
          }

          if (Platform.OS === 'web') {
            if (authAtom !== undefined && tokenAuthAtom !== undefined) {
              logout()
            }
          } else {
            changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP).then(() => {
              logout()
            })
          }
        }

        return Promise.reject(error)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, setAuthAtom, setTokenAuthAtom, authAtom, tokenAuthAtom])

  const [noConnection] = useState(false)

  const pageViewAnalitycs = async () => {
    const previousRouteName = routeNameRef.current

    const currentRouteName = refNavigation?.current?.getCurrentRoute()?.name

    if (previousRouteName !== currentRouteName) {
      const date = new Date()

      const formated = format(date, 'dd-MM-yyyy HH:mm')

      LogEventScreen({
        screen_name:
          // @ts-ignore
          currentRouteName in screensNames ? screensNames[currentRouteName] : currentRouteName,
        screen_class: currentRouteName,
        date: formated,
        hora: format(date, 'HH'),
        minutos: format(date, 'mm')
      })
    }

    routeNameRef.current = currentRouteName
  }

  return (
    <RootNavigationContainer
      //@ts-ignore
      ref={refNavigation}
      onReady={() => {
        routeNameRef.current = refNavigation.current?.getCurrentRoute()?.name

        if (Platform.OS !== 'web') {
          verifyIfUserCanBeContinueLogged()
        }
      }}
      {...{ onStateChange: pageViewAnalitycs }}
      {...{ linking }}>
      {/* is necessary for to use useNavigationHook */}
      <BlockScreenProvider refNavigation={refNavigation}>
        <NotificationPush />
        <NativeStackNavigator.Navigator
          initialRouteName={noConnection ? 'ErrorPageNoConnection' : 'Entry'}
          screenOptions={{
            headerShown: false,
            animation: 'slide_from_right'
          }}>
          {screens}
        </NativeStackNavigator.Navigator>
      </BlockScreenProvider>
    </RootNavigationContainer>
  )
}
