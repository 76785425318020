import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  KEY_DASHBOARDS_QUERY,
  useFavoriteDashboardMutation,
  useGetDashboardsInfiniteQuery,
  useUnfavoriteDashboardMutation
} from 'integration/resources/dashboards/hooks'
import { Dashboard } from 'integration/resources/dashboards/types'
import { useGetHighlightedFeaturesQuery } from 'integration/resources/menu'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import useDebounce from 'src/hooks/useDebounce'
import { FAVORITE_TYPE_ENUM } from 'src/screens/ContentScreen/ContentScreen.types'

import { UseDashboardScreen } from './DashboardScreen.types'

export const useDashboardScreen: UseDashboardScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [searchVisible, setSearchVisible] = useState(false)

  const [search, setSearch] = useState<string>()

  const debouncedSearch = useDebounce(search, 500)

  const [filterByFavorites, setFilterByFavorites] = useState<0 | 1>(0)

  const { data, isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } =
    useGetDashboardsInfiniteQuery({
      ...(debouncedSearch?.length && { search: debouncedSearch }),
      is_favorite: filterByFavorites ? 1 : undefined
    })

  const handleApplyFilter = (activeFilter: boolean) => {
    setFilterByFavorites(activeFilter ? 1 : 0)
  }

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  const dashboardsList = useMemo(
    () =>
      data?.pages.reduce<Dashboard[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data.data]
      }, []) ?? [],
    [data]
  )

  const pagination = data?.pages[0].data.metadata.pagination

  const queryClient = useQueryClient()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRefresh = () => queryClient.invalidateQueries([KEY_DASHBOARDS_QUERY])

  const favoriteDashboardMutation = useFavoriteDashboardMutation()

  const unfavoriteDashboardMutation = useUnfavoriteDashboardMutation()

  const handleToggleFavorite = (type: FAVORITE_TYPE_ENUM, id: string) => {
    const toggle = [favoriteDashboardMutation, unfavoriteDashboardMutation]

    const mutation = type === FAVORITE_TYPE_ENUM.FAVORITE ? toggle[0] : toggle[1]

    return mutation.mutateAsync({ id })
  }

  const handleGoBack = useCallback(() => {
    navigation.canGoBack() && navigation.goBack()
  }, [navigation])

  const handleGoToDetails = useCallback(
    (dashboardId: string) => {
      navigation.navigate('DashboardDetail', { dashboardId })
    },
    [navigation]
  )

  useEffect(() => {
    onRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  useEffect(() => {
    refetch()
  }, [filterByFavorites, debouncedSearch, refetch])

  const { data: highlighttData } = useGetHighlightedFeaturesQuery()

  const featuredData = highlighttData?.data?.data

  const dashboardsHasHighlight = featuredData?.filter((item) => item.menu_key === 1)[0]

  return {
    isMobile,
    navigation,
    isLoading: isLoading || isFetching,
    dashboardsList,
    onEndReached,
    onRefresh,
    pagination,
    setSearchVisible,
    searchVisible,
    isFetchingNextPage,
    handleToggleFavorite,
    handleApplyFilter,
    filterByFavorites,
    setSearch,
    search,
    handleGoBack,
    handleGoToDetails,
    highlightItem: dashboardsHasHighlight
  }
}
