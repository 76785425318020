import { useCallback, useMemo, useState } from 'react'

import { useLogoutMutation } from 'integration/resources/auth'
import {
  ContentSerialized,
  KNOWLEDGE_BASES_STATUS_ENUM,
  useGetKnowledgeBasesFavoriteInfiniteQuery,
  useGetKnowledgeBasesInfiniteQuery
} from 'integration/resources/knowledgeBase'
import { useGetProfileQuery } from 'integration/resources/user'
import {
  ContactSerialized,
  useGetContactListInfiniteQuery,
  useUnfavoriteContactMutation
} from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import { FAVORITE_TYPE_ENUM } from 'src/screens/ContentScreen/ContentScreen.types'
import { useAuthAtomValue, useSetAuthAtom } from 'src/store/auth'
import { useSetTokenAtom } from 'src/store/token'
import { registerForPushNotificationsAsync } from 'src/utils/device'
import { LogEvent, LogEventUserId } from 'src/utils/logEvents'

import { ScreenOptions, UseProfileScreen } from './ProfileScreen.types'

const mergeContentInfiniteQuery = (
  data: ReturnType<typeof useGetKnowledgeBasesInfiniteQuery>['data']
) =>
  data?.pages.reduce<ContentSerialized[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

const mergeContactInfiniteQuery = (
  data: ReturnType<typeof useGetContactListInfiniteQuery>['data']
) =>
  data?.pages.reduce<ContactSerialized[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useProfileScreen: UseProfileScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtom = useAuthAtomValue()

  const setTokenAuthAtom = useSetTokenAtom()

  const logoutMutation = useLogoutMutation()

  const profile = useGetProfileQuery()

  const handleGoToScreen = useCallback(
    (screen: keyof ScreenOptions) => navigation.navigate(screen),
    [navigation]
  )

  const [currentTab, setCurrentTab] = useState<number>(route.path ? 2 : 1)

  const tabs = [
    {
      id: 1,
      title: 'Conteúdos salvos'
    },
    {
      id: 2,
      title: 'Contatos favoritos'
    }
  ]

  const {
    data: favoriteContentData,
    isFetching: knowledgeLoading,
    refetch: refreshKnowledgeBase
  } = useGetKnowledgeBasesFavoriteInfiniteQuery({
    is_favored: KNOWLEDGE_BASES_STATUS_ENUM.ACTIVE,
    per_page: 100
  })

  const {
    data: favoriteContactListData,
    isLoading: contactLoading,
    refetch: refreshContactList
  } = useGetContactListInfiniteQuery({
    is_favorite: 1,
    per_page: 100,
    expand_details: !isMobile && Platform.OS === 'web'
  })

  const favoriteContentList = useMemo(
    () => mergeContentInfiniteQuery(favoriteContentData),
    [favoriteContentData]
  )

  const favoriteContactList = useMemo(
    () => mergeContactInfiniteQuery(favoriteContactListData),
    [favoriteContactListData]
  )

  const unfavoriteContactMutation = useUnfavoriteContactMutation()

  const handleToggleFavorite = useCallback(
    (type: FAVORITE_TYPE_ENUM, id: string) => {
      const mutation = unfavoriteContactMutation

      if (type === FAVORITE_TYPE_ENUM.FAVORITE) {
        LogEvent(`perfil_contatos_favoritar`, {
          item_id: id
        })
      } else {
        LogEvent(`perfil_contatos_desfavoritar`, {
          item_id: id
        })
      }

      return mutation.mutateAsync({ id })
    },
    [unfavoriteContactMutation]
  )

  const goToEditProfile = () => {
    LogEvent(`editar_perfil`, {
      item_id: ''
    })

    navigation.navigate('OptionsProfile')
  }

  const data = currentTab === 1 ? favoriteContentList : favoriteContactList

  const queryClient = useQueryClient()

  const [refreshPage, setRefreshPage] = useState(false)

  const onRefresh = async () => {
    setRefreshPage(true)

    await refreshKnowledgeBase()

    await refreshContactList()

    setRefreshPage(false)
  }

  const setAuthAtom = useSetAuthAtom()

  const onExit = useCallback(async () => {
    const device_push_notification_token = await registerForPushNotificationsAsync()

    const callbackLogout = () => {
      LogEvent(`sair_do_turbo`, {
        item_id: ''
      })

      LogEventUserId(null)

      Promise.all([setAuthAtom(null), setTokenAuthAtom(null)]).then(() =>
        queryClient.removeQueries()
      )
    }

    logoutMutation.mutate(
      { device_push_notification_token },
      {
        onError: callbackLogout,
        onSuccess: callbackLogout
      }
    )
  }, [logoutMutation, queryClient, setAuthAtom, setTokenAuthAtom])

  const screenHeight = Dimensions.get('screen').height

  const business = useMemo(
    () => (authAtom?.user?.business_code === 3 ? 'SIM' : 'Veículos'),
    [authAtom?.user?.business_code]
  )

  const occupation = useMemo(
    () => profile.data?.data.data.occupation,
    [profile.data?.data.data.occupation]
  )

  return {
    authAtom,
    profile,
    handleGoToScreen,
    handleToggleFavorite,
    onExit,
    data,
    isMobile,
    goToEditProfile,
    isLoading: knowledgeLoading || contactLoading || refreshPage,
    currentTab,
    tabs,
    setCurrentTab,
    screenHeight,
    onRefresh,
    business,
    occupation
  }
}
