import { useMemo } from 'react'

import { Icons, Illustrations } from 'atoms'
import { PROFILE_ACCESS_LEVEL_TYPE_ENUM } from 'integration/resources/auth'
import { MENU_FEATURES_ENUM, useGetHighlightedFeaturesQuery } from 'integration/resources/menu'
import { Box, ScrollView, Text, VStack } from 'native-base'
import { StyleSheet } from 'react-native'
import { useQueryClient } from 'react-query'
import { ZendeskAPI } from 'react-zendesk'
import { useAuthAtomValue, useSetAuthAtom } from 'src/store/auth'
import { useSetTokenAtom } from 'src/store/token'
import { LogEvent } from 'src/utils/logEvents'

import { ItemMenu } from './ItemMenu'
import Appconfig from '../../../../../app.config'

export const Sidebar = () => {
  const queryClient = useQueryClient()

  const { data } = useGetHighlightedFeaturesQuery()

  const featuredData = useMemo(() => data?.data?.data, [data?.data?.data])

  const dashboardsHasHighlight = featuredData?.filter(
    (item) => item.menu_key === MENU_FEATURES_ENUM.DASHBOARDS && !item.read
  )[0]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const surveysHasHighlight = featuredData?.filter(
    (item) => item.menu_key === MENU_FEATURES_ENUM.SURVEYS && !item.read
  )[0]

  const setAuthAtom = useSetAuthAtom()

  const setAuthToken = useSetTokenAtom()

  const handleLogout = () => {
    setAuthAtom(null)

    setAuthToken(null)

    queryClient.removeQueries()
  }

  const isDev = process.env.Environment !== 'prd'

  const authAtomValue = useAuthAtomValue()

  const isAdmin = useMemo(
    () =>
      authAtomValue?.user?.is_admin &&
      authAtomValue?.user?.access_level === PROFILE_ACCESS_LEVEL_TYPE_ENUM.ALL_DATA,
    [authAtomValue?.user?.is_admin, authAtomValue?.user?.access_level]
  )

  const isLeader = useMemo(() => authAtomValue?.user?.is_leader, [authAtomValue?.user?.is_leader])

  const is_visible_cockpit = authAtomValue?.user.is_visible_cockpit

  const handleToggleZendesk = () => {
    ZendeskAPI('webWidget', 'open')

    ZendeskAPI('webWidget', 'setLocale', 'pt-BR')

    ZendeskAPI('webWidget', 'helpCenter:setSuggestions', { search: 'login' })
  }

  return (
    <VStack w="255px" position="relative" p={8} pr={0} pb={0} bg="background.base">
      <Box style={styles.background} />
      <VStack space={4}>
        <Icons.LogoTurbo size={130} />
        <Box opacity={1}>
          <Icons.PortalMaisTurbo />
        </Box>
      </VStack>
      <ScrollView showsVerticalScrollIndicator={false} position="relative" zIndex={3}>
        <Box height="83vh" justifyContent="space-between" mt={10} position="relative">
          <Box>
            <VStack space={4}>
              <ItemMenu testID="start" icon="FlameSantander" title="Início" path="/home" />
              {is_visible_cockpit && (
                <ItemMenu
                  testID="cockpit"
                  icon="Cockpit"
                  title="Cockpit"
                  onPress={() => {
                    LogEvent(`menu_link_cockpit`, {
                      item_id: ''
                    })

                    window.open('https://cockpitai.com.br/', '_blank', 'noopener')
                  }}
                />
              )}
              <ItemMenu testID="profile" icon="User" title="Perfil" path="/perfil" />
              <ItemMenu
                testID="wallet"
                icon="Suitcase"
                title="Carteira"
                submenus={[
                  {
                    id: 1,
                    title: 'Contatos',
                    path: '/carteira/contatos',
                    testID: 'contacts'
                  },
                  {
                    id: 2,
                    title: 'TABs',
                    path: '/carteira/tabs',
                    testID: 'tabs'
                  }
                ]}
              />
              <ItemMenu
                testID="organization"
                icon="Calendar"
                title="Organização"
                submenus={[
                  {
                    id: 1,
                    title: 'Tarefas',
                    path: '/minha-organizacao/tarefas',
                    testID: 'activities'
                  },
                  {
                    id: 2,
                    title: 'Oportunidades',
                    path: '/minha-organizacao/oportunidades/5',
                    testID: 'opportunities'
                  }
                ]}
              />
              {isDev && (isLeader || isAdmin) && (
                <ItemMenu
                  testID="walletsManagement"
                  icon="WalletsManagement"
                  title="Carteirização"
                  path={isAdmin ? '/carteirizacao-admin' : '/carteirizacao-lider'}
                />
              )}
              {isDev && (isLeader || isAdmin) && (
                <ItemMenu
                  testID="goals"
                  icon="Union"
                  title="Distribuição de Metas"
                  path={isAdmin ? '/metas-admin' : '/metas-lider'}
                />
              )}
              <ItemMenu testID="contents" icon="BookOpen" title="Conteúdos" path="/conteudos" />
              <ItemMenu
                isHighlighted={!!dashboardsHasHighlight}
                testID="dashboards"
                icon="Dashboards"
                title="Painéis"
                path="/paineis"
              />
              <ItemMenu
                // isHighlighted={!!surveysHasHighlight}
                testID="survey"
                icon="Survey"
                title="Pesquisas"
                path="/pesquisas"
              />
              <ItemMenu testID="hire-more" icon="Plus" title="Contrata Já" path="/contrata-ja" />
              <ItemMenu
                testID="formation"
                icon="Formation"
                title="Formação"
                onPress={() => {
                  LogEvent(`menu_link_formacao`, {
                    item_id: ''
                  })

                  window.open('https://portal.formacaoturbo.com.br/entrar', '_blank', 'noopener')
                }}
              />
            </VStack>
            <Box h="1px" bg="gray.600" opacity={0.6} my={10} w="full" maxW="193px" mr={6} />
            <VStack space={4}>
              <ItemMenu
                testID="global-support"
                icon="Support"
                title="Suporte"
                onPress={handleToggleZendesk}
              />
              <ItemMenu
                testID="global-logout"
                icon="GoOut"
                title="Sair do Turbo"
                onPress={handleLogout}
              />
            </VStack>
          </Box>
          <Box position="relative" left={4} mt={10}>
            <Text
              fontSize="14px"
              lineHeight="20px"
              color="white"
              fontWeight={600}>{`versão ${Appconfig.version}`}</Text>
          </Box>
        </Box>
      </ScrollView>

      <Box
        position="absolute"
        right={0}
        bottom={0}
        zIndex={1}
        borderBottomRightRadius="20px"
        overflow="hidden">
        <Illustrations.FlameSantanderTurbo />
      </Box>
    </VStack>
  )
}

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    backgroundImage: 'linear-gradient(146.76deg, #494848 4.39%, #393939 42.03%, #070707 94.18%)'
  }
})
