import { FunctionComponent } from 'react'

import Svg, { SvgProps, Path } from 'react-native-svg'

export const FormationIcon: FunctionComponent<SvgProps> = ({ ...props }) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M16.5 7.5V12m-12-2.625V12c0 .597.474 1.17 1.318 1.591.844.422 1.989.66 3.182.66 1.194 0 2.338-.238 3.182-.66.844-.422 1.318-.994 1.318-1.59V9.374m2.565-1.183a.75.75 0 00-.014-1.379L9.623 3.885a1.5 1.5 0 00-1.245 0L1.95 6.81a.75.75 0 000 1.374l6.428 2.931a1.5 1.5 0 001.245 0l6.442-2.923z"
      stroke="#A8A8A8"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
