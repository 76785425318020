import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import {
  FinishParams,
  GetListParams,
  GetOpportunityFiltersList,
  OpportunitiesItem,
  OpportunitiesSummary,
  OpportunityDetailsItem,
  OpportunityDetailsItemSerialized,
  OPPORTUNITY_TYPE_ENUM
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_OPPORTUNITIES_SUMMARY = 'getOpportunitiesSummary'

export const KEY_OPPORTUNITIES_ODC_LIST = 'getOpportunitiesODCList'

export const KEY_OPPORTUNITIES_ACTIVE_BASE_LIST = 'getOpportunitiesActiveBaseList'

export const KEY_OPPORTUNITIES_INSURANCE_LIST = 'getOpportunitiesInsuranceList'

export const KEY_OPPORTUNITIES_CHARGE_LIST = 'getOpportunitiesChargeList'

export const KEY_OPPORTUNITIES_HIGHLIGHTED_LIST = 'getOpportunitiesHighlightedList'

export const KEY_OPPORTUNITIES_FINISHED_LIST = 'getOpportunitiesFinishedList'

export const KEY_OPPORTUNITIES_PENDING_LIST = 'getOpportunitiesPendingList'

export const KEY_OPPORTUNITIES_DETAILS_ODC_ITEM = 'getOpportunityDetailsOdcItem'

export const KEY_OPPORTUNITIES_DETAILS_ACTIVE_BASE_ITEM = 'getOpportunityDetailsActiveBaseItem'

export const KEY_FILTERS_QUERY = 'getOpportunityFilters'

export const useGetOpportunitiesSummaryQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<OpportunitiesSummary>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_SUMMARY],
    () => requests.getOpportunitiesSummary()
  )

export const useGetOpportunitiesODCListInfiniteQuery = (params: GetListParams, enabled?: boolean) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<OpportunitiesItem[]>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_ODC_LIST, params],
    ({ pageParam }) => requests.getOpportunitiesODCList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      enabled: enabled ?? true
    }
  )

export const useGetOpportunitiesActiveBaseListInfiniteQuery = (
  params: GetListParams,
  enabled?: boolean
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<OpportunitiesItem[]>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_ACTIVE_BASE_LIST, params],
    ({ pageParam }) => requests.getOpportunitiesActiveBaseList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      enabled: enabled ?? true
    }
  )

export const useGetOpportunitiesInsuranceListInfiniteQuery = (
  params: GetListParams,
  enabled?: boolean
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<OpportunitiesItem[]>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_INSURANCE_LIST, params],
    ({ pageParam }) => requests.getOpportunitiesInsuranceList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      enabled: enabled ?? true
    }
  )

export const useGetOpportunitiesChargeListInfiniteQuery = (
  params: GetListParams,
  enabled?: boolean
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<OpportunitiesItem[]>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_CHARGE_LIST, params],
    ({ pageParam }) => requests.getOpportunitiesChargeList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      enabled: enabled ?? true
    }
  )

export const useGetOpportunitiesHighlightedListInfiniteQuery = (
  params: GetListParams,
  enabled?: boolean
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<OpportunitiesItem[]>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_HIGHLIGHTED_LIST, params],
    ({ pageParam }) => requests.getOpportunitiesHighlightedList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      enabled: enabled ?? true
    }
  )

export const useGetOpportunitiesFinishedListInfiniteQuery = (
  params: GetListParams,
  enabled?: boolean
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<OpportunitiesItem[]>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_FINISHED_LIST, params],
    ({ pageParam }) => requests.getOpportunitiesFinishedList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      enabled: enabled ?? true
    }
  )

export const useGetOpportunitiesPendingListInfiniteQuery = (
  params: GetListParams,
  enabled?: boolean
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<OpportunitiesItem[]>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_PENDING_LIST, params],
    ({ pageParam }) => requests.getOpportunitiesPendingList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      enabled: enabled ?? true
    }
  )

export const useGetOpportunityDetailsOdcItemQuery = (id: string, enabled?: boolean) =>
  useQuery<
    AxiosResponse<ResponseSuccess<OpportunityDetailsItemSerialized>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_OPPORTUNITIES_DETAILS_ODC_ITEM}.${id}`],
    () => requests.getOpportunityDetailsOdcItem(id),
    { enabled: enabled ?? true }
  )

export const useGetOpportunityDetailsActiveBaseItemQuery = (id: string, enabled?: boolean) =>
  useQuery<
    AxiosResponse<ResponseSuccess<OpportunityDetailsItemSerialized>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_OPPORTUNITIES_DETAILS_ACTIVE_BASE_ITEM}.${id}`],
    () => requests.getOpportunityDetailsActiveBaseItem(id),
    { enabled: enabled ?? true }
  )

export const useSetOpportunityOdcHighlightedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<OpportunityDetailsItem, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<OpportunityDetailsItem, 'id'>
  >(requests.setOpportunityOdcHighlighted, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITIES_ODC_LIST,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries([KEY_OPPORTUNITIES_HIGHLIGHTED_LIST])

      queryClient.invalidateQueries([`${KEY_OPPORTUNITIES_DETAILS_ODC_ITEM}.${data.data.data.id}`])
    }
  })
}

export const useSetOpportunityOdcUnHighlightedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<OpportunityDetailsItem, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<OpportunityDetailsItem, 'id'>
  >(requests.setOpportunityOdcUnHighlighted, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITIES_ODC_LIST,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries([KEY_OPPORTUNITIES_HIGHLIGHTED_LIST])

      queryClient.invalidateQueries([`${KEY_OPPORTUNITIES_DETAILS_ODC_ITEM}.${data.data.data.id}`])
    }
  })
}

export const useSetOpportunityActiveBaseHighlightedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<OpportunityDetailsItem, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<OpportunityDetailsItem, 'id'>
  >(requests.setOpportunityActiveBaseHighlighted, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITIES_ACTIVE_BASE_LIST,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries([KEY_OPPORTUNITIES_HIGHLIGHTED_LIST])

      queryClient.invalidateQueries([
        `${KEY_OPPORTUNITIES_DETAILS_ACTIVE_BASE_ITEM}.${data.data.data.id}`
      ])
    }
  })
}

export const useSetOpportunityActiveBaseUnHighlightedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<OpportunityDetailsItem, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<OpportunityDetailsItem, 'id'>
  >(requests.setOpportunityActiveBaseUnHighlighted, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITIES_ACTIVE_BASE_LIST,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries([KEY_OPPORTUNITIES_HIGHLIGHTED_LIST])

      queryClient.invalidateQueries([
        `${KEY_OPPORTUNITIES_DETAILS_ACTIVE_BASE_ITEM}.${data.data.data.id}`
      ])
    }
  })
}

export const useSetFinishedOpportunityOdcMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<FinishParams>>,
    AxiosError<ResponseError>,
    FinishParams
  >(requests.setFinishedOpportunityOdc, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITIES_ODC_LIST,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries(KEY_OPPORTUNITIES_PENDING_LIST)

      queryClient.invalidateQueries(KEY_OPPORTUNITIES_FINISHED_LIST)
    }
  })
}

export const useSetUnFinishedOpportunityOdcMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<FinishParams>>,
    AxiosError<ResponseError>,
    FinishParams
  >(requests.setUnFinishedOpportunityOdc, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITIES_ODC_LIST,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries(KEY_OPPORTUNITIES_PENDING_LIST)

      queryClient.invalidateQueries(KEY_OPPORTUNITIES_FINISHED_LIST)
    }
  })
}

export const useSetFinishedOpportunityActiveBaseMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<FinishParams>>,
    AxiosError<ResponseError>,
    FinishParams
  >(requests.setFinishedOpportunityActiveBase, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITIES_ACTIVE_BASE_LIST,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries(KEY_OPPORTUNITIES_PENDING_LIST)

      queryClient.invalidateQueries(KEY_OPPORTUNITIES_FINISHED_LIST)
    }
  })
}

export const useSetUnFinishedOpportunityActiveBaseMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<FinishParams>>,
    AxiosError<ResponseError>,
    FinishParams
  >(requests.setUnFinishedOpportunityActiveBase, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITIES_ACTIVE_BASE_LIST,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries(KEY_OPPORTUNITIES_PENDING_LIST)

      queryClient.invalidateQueries(KEY_OPPORTUNITIES_FINISHED_LIST)
    }
  })
}

export const useGetOpportunityFiltersList = (
  opportunityType: OPPORTUNITY_TYPE_ENUM,
  enabled: boolean
) =>
  useQuery<AxiosResponse<ResponseSuccess<GetOpportunityFiltersList>>, AxiosError<ResponseError>>(
    [KEY_FILTERS_QUERY],
    () => requests.getFiltersList(opportunityType),
    {
      enabled
    }
  )
