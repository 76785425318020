export enum OPPORTUNITIES_TYPES_ENUM {
  RISK_OF_LOSS = 'RISCO DE PERDA',
  MORE_CONVERSION = '+CONVERSAO',
  POWERED = 'POTENCIALIZOU',
  POWERED2 = 'POTENCIALIZOU!',
  VIEW_MORE = 'VISUALIZA+',
  EFFECTIVE = 'EFETIVAR',
  APNEF = 'APNEF',
  ACCELERATES_RTG10 = 'ACELERA RTG10',
  ACCREDITATE = 'CREDENCIAR',
  RE_CREDIT = 'RECREDENCIAR',
  CHARGE = 'COBRANCA',
  TURBO_PLAN = 'PLANO TURBO',
  SPF = 'SPF'
}

export const OPPORTUNITIES_TYPES_NAMES = {
  [OPPORTUNITIES_TYPES_ENUM.RISK_OF_LOSS]: 'RISCO DE PERDA',
  [OPPORTUNITIES_TYPES_ENUM.MORE_CONVERSION]: '+CONVERSÃO',
  [OPPORTUNITIES_TYPES_ENUM.SPF]: 'SPF',
  [OPPORTUNITIES_TYPES_ENUM.POWERED]: 'PLANO TURBO',
  [OPPORTUNITIES_TYPES_ENUM.POWERED2]: 'PLANO TURBO',
  [OPPORTUNITIES_TYPES_ENUM.VIEW_MORE]: 'VISUALIZA+',
  [OPPORTUNITIES_TYPES_ENUM.EFFECTIVE]: 'EFETIVAR',
  [OPPORTUNITIES_TYPES_ENUM.APNEF]: 'APNEF',
  [OPPORTUNITIES_TYPES_ENUM.ACCELERATES_RTG10]: 'ACELERA RTG10',
  [OPPORTUNITIES_TYPES_ENUM.ACCREDITATE]: 'CREDENCIAR',
  [OPPORTUNITIES_TYPES_ENUM.RE_CREDIT]: 'RECREDENCIAR',
  [OPPORTUNITIES_TYPES_ENUM.CHARGE]: 'COBRANÇA',
  [OPPORTUNITIES_TYPES_ENUM.TURBO_PLAN]: 'PLANO TURBO'
}

export type DescriptionItem = {
  title: string
  value: string
}

export enum OPPORTUNITY_TYPE_ENUM {
  ODC = 1,
  ACTIVE_BASE = 2,
  INSURANCE = 3,
  CHARGE = 4,
  HIGHLIGHTED = 5,
  CONCLUDED = 6,
  PENDING = 7
}

export type TOpportunityFilterParams = {
  search?: string
  order_by_direction?: string
  order_by?: string
  offer?: string
  public_rating?: string
  segment?: string
  network?: string
  subsidiary?: string
  opportunity_type?: string
  user_std_code?: number | string
}

export type TNetworks = {
  network: string
  subsidiaries: string[]
}

export type TUsers = {
  network_name?: string
  std_code: number
  subsidiary_name?: string
  user_name: string
}

type TOpportunityFiltersList = {
  offers?: string[]
  public_ratings?: string[]
  segments?: string[]
  networks: TNetworks[]
  users?: TUsers[]
}

export type GetOpportunityFiltersList = TOpportunityFiltersList

export type GetListParams = {
  page?: number
  per_page?: number
} & TOpportunityFilterParams

export type SummaryItem = {
  id: OPPORTUNITY_TYPE_ENUM
  title: string
  value: string
  type: OPPORTUNITIES_TYPES_ENUM
  descriptions: DescriptionItem[]
  updatedAt: string
}

export type OpportunitiesSummary = {
  odc: SummaryItem
  activeBase: SummaryItem
  insuranceAuto: SummaryItem
  charge: SummaryItem
}

export type CallsignsItem = {
  name: string
  value: number
}

export type StoreAddressItem = {
  address_uf: string
  city_name_ibge: string
  address_neighborhood: string
  address_type: string
  address: string
  address_number: string
  address_complement: string
  cep_number: string
}

export type StoreItem = {
  name_fantasy: string
  tab_number: number
  segment_name?: string
  subsegment_name?: string
  store_cnpj_number?: number
  debit_balance?: number
  overdue_days?: number
  store_cnpj?: number
  cpnj_status?: string
  cnae_tax?: string
  opening_date?: string
  opening_ad?: string
  updated_last_date?: string
  updated_last_ad?: string
  corporate_reason?: string
  address?: StoreAddressItem
  phone_number_01?: string
  phone_number_02?: string
  validated_flag?: false
  accreditation_date?: string
  last_contract_date?: string
  store_email?: string
  dsc_web_site?: string
}

export type ContractInfosItem = {
  contract_number: number
  installment_quantity: number
  installment_value: number
  accounting_balance: number
  safra: number
  installment_quantity_delayed: number
  installment_first_delayed: number
  tracker: boolean
}

export type CustomerInfosItem = {
  customer_name: string
  customer_cpf_cnpj: string
  phone_number_01?: string
  phone_number_02?: string
  address_city_neighborhood: string
}

export type CarInfosItem = {
  car_model: string
  car_chassis_number: string
  car_year_model: number
  car_license_plate: string
}

export type OpportunitiesItem = {
  id: string
  is_finished?: boolean
  highlighted?: boolean
  store: StoreItem
  callsigns?: CallsignsItem[]
  public_rating?: OPPORTUNITIES_TYPES_ENUM
  contract_infos?: ContractInfosItem
  customer_infos?: CustomerInfosItem
  car_infos?: CarInfosItem
  opportunity_type_enum?: OPPORTUNITY_TYPE_ENUM
  opportunity_odc_id?: string
}

export type PoliciesItem = {
  quantity: number
  target_quantity: number
  average_quantity: number
  average_value: string
  date_ref: string
}

export type BaseItem = {
  name: string
  value: string
}

export type LeadsItem = {
  id: string
  lead_date: string
  store_tab_number: string
  id_lead: number
  customer_cpf_cnpj: string
  rating: string
  campaign: string
  car_brand: string
  car_model: string
  car_year: number
  deleted_at: null
  created_at: string
  updated_at: string
}

export type ApnefItem = {
  id: string
  store_tab_number: string
  proposal_number: number
  customer_cpf_cnpj: string
  rating: string
  apnef_date: string
  entry_percentage_value: string
  financing_value: string
  installment_value: string
  installment_quantity: string
  account_holder: boolean
  car_brand: string
  car_model: string
  car_year: number
  deleted_at?: string
  created_at: string
  updated_at: string
}

export type XrayItem = {
  store_month_potential: string
  store_current_production: string
  store_average_ticket: string
  store_share_pdv: string
  store_share_pdv_ad: string
  store_conversion: string
  store_conversion_ad: string
  store_visualization: string
  store_visualization_ad: string
  store_insurance_auto_ip: string
  store_insurance_auto_ip_ad: string
  store_spf_ip: string
  store_spf_ip_ad: string
  store_fpd_10: string
  store_fpd_10_ad: string
  store_over_30m3: string
  store_over_30m3_ad: string
  last_contract_date: string
  date_ref_production: string
  date_ref_pdv: string
  date_ref_sa: string
  date_ref_conversao: string
  date_ref_intermediario: string
  date_ref_over: string
  date_ref_fpd_10: string
  date_ref_over_m3: string
}

export type XrayItemSerialized = {
  value: string
  title: string
  description?: { title: string; value?: string }
  updated_at: string
}

export type AcceleratorsItem = {
  items: BaseItem[]
  apnfes: ApnefItem[]
  leads: LeadsItem[]
}

export type ChargesItem = {
  id: string
  store: StoreItem
  contract_infos: ContractInfosItem
  customer_infos: CustomerInfosItem
  car_infos: CarInfosItem
}

export type KnowledgesItem = {
  id: string
  title: string
  category: {
    id: string
    name: string
    code: number
  }
  cover_image?: string
  published_at: string
}

export type ActivitiesItem = {
  id: string
  external_id: number
  title: string
  description: string
  is_finished: boolean
  finished_at?: string
  schedule_date: string
  schedule_date_start: string
  schedule_date_end: string
  is_all_day_schedule: boolean
  store_wallet_identifier: string
  opportunity_external_id: string
  user_std_code: number
  created_by_user_std_code?: string
  knowledge_base_id?: string
  created_at: string
  updated_at: string
}

export type PolicyOrContractInfo = {
  quantity: number
  goal: number
  average: number
  value: number
  updated_at: string
}

export type OpportunityDetailsItem = {
  id: string
  public_rating?: OPPORTUNITIES_TYPES_ENUM
  potential_value?: string
  odc_target?: string
  highlighted: boolean
  is_finished: boolean
  dsc_opportunity_month: string
  description?: string
  created_by?: string
  user_name: string
  created_opportunity_at: string
  store: StoreItem
  production?: PoliciesItem
  spf?: PoliciesItem
  insurance_auto?: PoliciesItem
  active_offers?: {
    items: BaseItem[]
    data_ref: string
  }
  accelerators?: AcceleratorsItem
  recommendations: {
    items: BaseItem[]
  }
  store_xray?: XrayItem
  charges?: ChargesItem[]
  knowledges: KnowledgesItem[]
  activities?: ActivitiesItem[]
}

export type OpportunityDetailsItemSerialized = OpportunityDetailsItem & {
  production_serialized: PolicyOrContractInfo
  spf_serialized: PolicyOrContractInfo
  insurance_auto_serialized: PolicyOrContractInfo
  store_xray_serialized: XrayItemSerialized[]
}

export type FinishParams = {
  id: string
  only_finish: boolean
  score?: string
  insight?: number
  comment?: string
}

export type HandleFinishParams = {
  id: string
  finished: boolean
  only_finish: boolean
  comment?: string
  insight?: number
  score?: string
  opportunity_type_enum?: OPPORTUNITY_TYPE_ENUM
}
