import React from 'react'

import { Document, Font } from '@react-pdf/renderer'
import { TAdjustments, TGoalsReportSerialized } from 'integration/resources/goals'
import { correctDate } from 'src/screens/WalletsManagement/Components/ReportPDF/utils'
import { capitalizeText } from 'src/utils'

import { CoverPage } from './CoverPage'
import { GoalAdjustmentsPage } from './GoalAdjustments'
//@ts-ignore
import AssistantBold from '../../../../../assets/fonts/assistant.bold.ttf'
//@ts-ignore
import AssistantRegular from '../../../../../assets/fonts/assistant.regular.ttf'
//@ts-ignore
import OpenSansBold from '../../../../../assets/fonts/openSans.bold.ttf'
//@ts-ignore
import OpenSansRegular from '../../../../../assets/fonts/openSans.regular.ttf'

export const formatGoalValue = (goalType: TAdjustments['type'], value: number): string => {
  switch (goalType) {
    case '+Contas':
      return `${value} contas`

    case 'Base Ativa':
      return `${value} lojas`

    case 'Cobranças':
      return `${value} pontos`

    default:
      return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`
  }
}

export const formatPercentageOrUnit = (goalType: TAdjustments['type'], value: number): string => {
  switch (goalType) {
    case '+Contas':
      return `${value} contas`

    case 'Base Ativa':
      return `${value} lojas`

    case 'Cobranças':
      return `${value} pontos`

    default:
      return `${value}%`
  }
}

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSansRegular,
      fontWeight: 'normal'
    },
    {
      src: OpenSansBold,
      fontWeight: 'bold'
    }
  ]
})

Font.register({
  family: 'Assistant',
  fonts: [
    {
      src: AssistantRegular,
      fontWeight: 'normal'
    },
    {
      src: AssistantBold,
      fontWeight: 'bold'
    }
  ]
})

export const ReportPDF: React.FC<TGoalsReportSerialized> = ({ header, goal_adjustments }) => {
  return (
    <Document>
      <CoverPage
        referenceMonth={correctDate(String(header?.year_month))}
        mainLeader={capitalizeText(header?.leader_name ?? '')}
        periodStartDate={header?.started_at}
        periodEndDate={header?.finished_at}
      />

      {goal_adjustments?.length && (
        <GoalAdjustmentsPage header={{ ...header }} goal_adjustments={goal_adjustments} />
      )}
    </Document>
  )
}
