import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'integration/types'

import {
  ReportEntry,
  TAdjustments,
  TGoalAdjustment,
  TGoalsReportSerialized,
  TReport
} from './types'

export const getGoalsReport = (
  response: AxiosResponse<ResponseSuccess<TReport>>
): AxiosResponse<ResponseSuccess<TGoalsReportSerialized>> => ({
  ...response,
  data: {
    ...response.data,
    data: serializeTReportToTGoalsReport(response.data.data)
  }
})

const serializeTReportToTGoalsReport = (tReport: TReport): TGoalsReportSerialized => {
  const { leader_std_code, year_month, file_name, report_data } = tReport

  const goalTypes = [
    'accounts',
    'active_base',
    'auto',
    'charge',
    'production',
    'spf',
    'wm'
  ] as const

  const goalAdjustments: TAdjustments[] = goalTypes
    .map((goalType) => {
      const adjustments = report_data.goals_report
        .map((entry: ReportEntry) => {
          const current = entry.current[goalType]

          const history = entry.history[goalType]

          return {
            staff_name: entry.specialist.name,
            occupation: entry.specialist.occupation,
            staff_std_code: String(entry.specialist.std_code),
            current: {
              year_month: entry.current.year_month ?? year_month,
              default_goal: Number(current.goal),
              adjusted_goal: Number(current.current),
              percentage: Number(current.percentage),
              last_adjustment_date: entry.current.adjustment.last_adjustment_date,
              adjusted_by: entry.current.adjustment.adjusted_by
            },
            history: {
              year_month: entry.history.year_month ?? year_month,
              default_goal: Number(history.goal),
              adjusted_goal: Number(history.current),
              percentage: Number(history.percentage),
              last_adjustment_date: entry.history.adjustment.last_adjustment_date,
              adjusted_by: entry.history.adjustment.adjusted_by
            }
          }
        })
        .filter((entry: TGoalAdjustment) => entry !== null)

      return adjustments.length > 0
        ? {
            type: mapGoalType(goalType),
            year_month,
            goal: Number(report_data.header.goals[goalType]),
            adjustments
          }
        : null
    })
    .filter((goal): goal is TAdjustments => goal !== null)

  return {
    file_url: undefined,
    file_name,
    header: {
      leader_std_code,
      leader_name: report_data.header.leader_name,
      year_month,
      current_date: new Date().toISOString(),
      started_at: report_data.header.period.started_at,
      finished_at: report_data.header.period.finished_at
    },
    goal_adjustments: goalAdjustments
  }
}

const mapGoalType = (key: string): TAdjustments['type'] => {
  const mapping: Record<string, TAdjustments['type']> = {
    accounts: '+Contas',
    active_base: 'Base Ativa',
    auto: 'Seguro Auto',
    charge: 'Cobranças',
    production: 'Produção',
    spf: 'Seguro Prestamista',
    wm: 'Webmotors'
  }

  return mapping[key] || 'Produção'
}
