import { useCallback, useState } from 'react'

import * as ScreenOrientation from 'expo-screen-orientation'
import {
  useGetTabItemQuery,
  useFavoriteTabMutation,
  useUnfavoriteTabMutation,
  Note,
  useDeleteNoteStore,
  useFavoriteContactMutation,
  useUnfavoriteContactMutation
} from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { PANELS_TYPES_ENUM } from 'organisms/TabPanels/constants'
import { FAVORITE_TYPE_ENUM } from 'src/screens/ContentScreen/ContentScreen.types'

import { Classification } from './components/Classification'
import { Documents } from './components/Documents'
import { Ecosystem } from './components/Ecosystem'
import { Informations } from './components/Informations'
import { Notes } from './components/Notes'
import { Panels } from './components/Panels'
import { Regulatory } from './components/Regulatory'
import { UseTabScreen } from './TabScreen.types'
import { TabRelatedTypeProps } from '../TabRelatedScreen/TabRelatedScreen.types'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

export const useTabScreen: UseTabScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const tabNumber = route?.params?.tabNumber

  const { data, isLoading } = useGetTabItemQuery(tabNumber)

  const tabDetail = data?.data.data

  const favoriteTabMutation = useFavoriteTabMutation(tabDetail?.identifier as string)

  const unfavoriteTabMutation = useUnfavoriteTabMutation(tabDetail?.identifier as string)

  const favoriteContactMutation = useFavoriteContactMutation()

  const unfavoriteContactMutation = useUnfavoriteContactMutation()

  const handleToggleFavorite = (
    type: FAVORITE_TYPE_ENUM,
    mutationType: 'contact' | 'tab',
    id: string
  ) => {
    const toggle =
      mutationType === 'tab'
        ? [favoriteTabMutation, unfavoriteTabMutation]
        : [favoriteContactMutation, unfavoriteContactMutation]

    const mutation = type === FAVORITE_TYPE_ENUM.FAVORITE ? toggle[0] : toggle[1]

    return mutation.mutateAsync({ id, identifier: tabDetail?.identifier })
  }

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const handleGoToTabDetails = useCallback(() => {
    if (tabDetail) {
      navigation.navigate('TabDetails', { tabNumber })
    }
  }, [navigation, tabDetail, tabNumber])

  const handleGoToTabLocation = useCallback(() => {
    if (tabNumber) navigation.navigate('TabLocation', { tabNumber })
  }, [navigation, tabNumber])

  const handleGoToTabContact = useCallback(
    () => navigation.navigate('TabContact', { tabNumber }),
    [navigation, tabNumber]
  )

  const handleGoToContactEdit = useCallback(() => {
    if (tabDetail)
      navigation.navigate('TabEdit', {
        tabDetail: { ...tabDetail, tab_number: tabNumber },
        tabNumber
      })
  }, [navigation, tabDetail, tabNumber])

  const handleGoToNoteDetails = useCallback(
    (id: string) => navigation.navigate('NoteDetails', { id, key: 'stores', tabNumber }),
    [navigation, tabNumber]
  )

  const handleGoToTabRelated = useCallback(
    (type: TabRelatedTypeProps) => navigation.navigate('TabRelated', { type, tabNumber }),
    [navigation, tabNumber]
  )

  const handleGoToNoteCreateDetails = useCallback(
    () =>
      navigation.navigate(isMobile ? 'CreateNoteDetails' : 'TabCreateNoteDetails', { tabNumber }),
    [isMobile, navigation, tabNumber]
  )

  const [showOptions, setShowOptions] = useState(false)

  const handleOpenOptions = () => {
    setShowOptions(true)
  }

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)

  const handleOpenConfirmDelete = () => {
    setShowOptions(false)

    setIsConfirmDeleteOpen(true)
  }

  const handleGoToEditNote = useCallback(
    (note: Note) => {
      if (isMobile) {
        navigation.navigate('EditNoteDetails', {
          note,
          key: 'stores',
          tabNumber
        })

        return
      }

      navigation.navigate('TabEditNoteDetails', {
        note,
        key: 'stores',
        tabNumber
      })
    },
    [isMobile, navigation, tabNumber]
  )

  const handleGoToFullChartScreen = useCallback(
    (type: PANELS_TYPES_ENUM) => {
      if (isMobile) {
        changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() => {
          navigation.navigate('Chart', { type, tabNumber })
        })

        return
      }

      navigation.navigate('Chart', { type, tabNumber })
    },
    [isMobile, navigation, tabNumber]
  )

  const [currentTab, setCurrentTab] = useState<number>(1)

  const [isConfirmDeleteNoteOpen, setIsConfirmDeleteNoteOpen] = useState(false)

  const { mutate: deleteNoteMutation, isLoading: isLoadingNoteDelete } = useDeleteNoteStore()

  const handleConfirmDelete = (note: Note) => {
    deleteNoteMutation(
      { id: note!.id },
      {
        onSuccess: () => {
          setIsConfirmDeleteNoteOpen(false)
        },
        onError: () => {
          setIsConfirmDeleteNoteOpen(false)
        }
      }
    )
  }

  const mainContent = {
    1: {
      component: Panels,
      name: 'Painéis'
    },
    3: {
      component: Informations,
      name: 'Informações'
    },
    4: {
      component: Regulatory,
      name: 'Regulatório'
    },
    5: {
      component: Notes,
      name: 'Notas'
    },
    7: {
      component: Documents,
      name: 'Documentos'
    },
    8: {
      component: Classification,
      name: 'Classificação'
    },
    9: {
      component: Ecosystem,
      name: 'Ecossistema'
    }
  }

  return {
    isMobile,
    tabIsLoading: isLoading,
    handleGoToContactEdit,
    handleGoBack,
    handleGoToTabContact,
    favoriteIsLoading: favoriteTabMutation.isLoading || unfavoriteTabMutation.isLoading,
    showOptions,
    mainContent,
    mainContentCommonProps: {
      isConfirmDeleteNoteOpen,
      isLoadingNoteDelete,
      setIsConfirmDeleteNoteOpen,
      handleConfirmDelete,
      handleGoToNoteCreateDetails,
      handleGoToFullChartScreen,
      handleGoToNoteDetails,
      handleGoToTabLocation,
      handleGoToTabDetails,
      handleGoToTabContact,
      handleGoToTabRelated,
      handleToggleFavorite,
      handleGoToContactEdit,
      handleGoToEditNote,
      tabNumber,
      tabDetail
    },
    handleOpenOptions,
    setShowOptions,
    handleOpenConfirmDelete,
    setIsConfirmDeleteOpen,
    setCurrentTab,
    isConfirmDeleteOpen,
    tabIsFavorite: tabDetail?.is_favorite ?? false,
    currentTab,
    tabDetail: { ...tabDetail, tab_number: tabNumber },
    handleToggleFavorite
  }
}
