import { FunctionComponent, memo, useState } from 'react'

import { Avatar, Icons } from 'atoms'
import { AxiosResponse } from 'axios'
import { Button, Center, HStack, Heading, Text, VStack, View } from 'native-base'
import { StyleSheet } from 'react-native'
import { FAVORITE_TYPE_ENUM } from 'src/screens/ContentScreen/ContentScreen.types'

type CardBaseProps = {
  onViewMoreClick?: () => void
  onFavoriteClick?: (type: FAVORITE_TYPE_ENUM, id: string) => Promise<AxiosResponse<any, any>>
  title: string
  id: string
  coverImageURL?: string
  actions?: JSX.Element
  subtitle?: JSX.Element
  details?: JSX.Element
  isFavorite?: boolean
}

export const AccordionCard: FunctionComponent<CardBaseProps> = memo(
  ({
    id,
    coverImageURL,
    title,
    subtitle,
    actions,
    details,
    isFavorite,
    onViewMoreClick,
    onFavoriteClick
  }) => {
    const [active, setActive] = useState(false)

    const [loading, setLoading] = useState(false)

    const [favorite, setFavorite] = useState(isFavorite)

    const handlePressFavorite = () => {
      setLoading(true)

      onFavoriteClick?.(
        isFavorite ? FAVORITE_TYPE_ENUM.UNFAVORITE : FAVORITE_TYPE_ENUM.FAVORITE,
        id
      )
        .then(() => {
          setFavorite(!isFavorite)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return (
      <VStack
        bg="white"
        borderRadius="20px"
        style={[styles.card, active && styles.openCard]}
        mb={4}>
        <HStack space={4} alignItems="center" p={6}>
          <Center bg={coverImageURL ? 'white' : 'gray.50'} borderRadius="full" h={12} w={12}>
            <Avatar uri={coverImageURL} title={title} type="dark" h={12} w={12} />
          </Center>
          <VStack flex={2}>
            <Heading
              testID="profile-contact-name"
              fontSize="16px"
              lineHeight="24px"
              fontWeight="bold"
              color="gray.700"
              noOfLines={1}
              textTransform="capitalize"
              mb={0.5}>
              {title.toLocaleLowerCase()}
            </Heading>
            {subtitle && (
              <Text
                fontSize="15px"
                lineHeight="24px"
                fontWeight="normal"
                color="gray.500"
                maxH={6}
                overflow="hidden">
                {subtitle}
              </Text>
            )}
          </VStack>
          {actions && (
            <HStack space={4} flex={1} style={[styles.details, !active && styles.openDetails]}>
              {actions}
            </HStack>
          )}

          <HStack flex={1} h={12} alignItems="center" justifyContent="flex-end" space={4}>
            {onViewMoreClick && (
              <Button
                testID="profile-all-contact-data"
                h={9}
                px={4}
                onPress={onViewMoreClick}
                variant="outline"
                colorScheme="gray"
                _text={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: 'gray.700'
                }}
                style={[styles.details, active && styles.openDetails]}>
                Ver perfil completo
              </Button>
            )}

            <Button
              testID="profile-favorite-contact"
              alignSelf="flex-end"
              onPress={handlePressFavorite}
              h={10}
              w={10}
              borderRadius="full"
              isLoading={loading}
              colorScheme="dark"
              _spinner={{
                color: 'gray.700'
              }}
              _loading={{
                bgColor: 'gray.70'
              }}
              bgColor="gray.30">
              <Center>
                <Icons.Star
                  // size={4}
                  filled={favorite}
                  color={favorite ? 'tabs-yellow.50' : 'gray.200'}
                />
              </Center>
            </Button>

            {details && (
              <Button
                testID="profile-expand-contact-data"
                alignSelf="flex-end"
                onPress={() => setActive(!active)}
                h={10}
                w={10}
                borderRadius="full"
                bgColor="gray.50">
                <Center>
                  <Icons.AccordionArrow
                    direction={active ? 'up' : 'down'}
                    color="gray.700"
                    size={5}
                  />
                </Center>
              </Button>
            )}
          </HStack>
        </HStack>

        <VStack px={6} style={[styles.details, active && styles.openDetails]}>
          <View w="full" h="1px" bg="gray.100" />

          <HStack style={[styles.details, active && styles.openDetails]}>{details}</HStack>
        </VStack>
      </VStack>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  card: {
    height: 98,
    transition: 'all ease 0.3s'
  },
  openCard: {
    height: 250,
    boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.08)'
  },
  details: {
    opacity: 0,
    visibility: 'hidden',
    transition: 'all ease 0.3s 0s'
  },
  openDetails: {
    opacity: 1,
    transitionDelay: '0.2s',
    visibility: 'visible'
  }
})
