import { OPPORTUNITY_TYPE_ENUM, TNetworks, TUsers } from 'integration/resources/newOpportunities'
import * as yup from 'yup'

import { TOption, TOptionCommon } from './OpportunityFilterScreen.types'
import { LIST_TYPE_ENUM } from '../../OrganizationScreen.types'

export const getOptionLabel = (option: TOption) =>
  typeof option === 'string'
    ? option
    : (option as TOptionCommon)?.name ??
      (option as TNetworks)?.network ??
      (option as TUsers)?.user_name

export const OPPORTUNITY_TYPES_OPTIONS = [
  {
    id: OPPORTUNITY_TYPE_ENUM.ODC,
    name: 'Oportunidades'
  },
  {
    id: OPPORTUNITY_TYPE_ENUM.ACTIVE_BASE,
    name: 'Base Ativa'
  }
]

export const BaseFormSchema = yup.object().shape({
  segment: yup.string(),
  network: yup.string(),
  subsidiary: yup.string(),
  user_std_code: yup.string()
})

export const ChargeFormSchema = BaseFormSchema

export const ODCFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    offer: yup.string(),
    public_rating: yup.string()
  })
)

export const ActiveBaseFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    public_rating: yup.string()
  })
)

export const InsuranceFormSchema = BaseFormSchema

export const HighlightedFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    opportunity_type: yup.string()
  })
)

export const ConcludedFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    opportunity_type: yup.string()
  })
)

export const PendingFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    opportunity_type: yup.string()
  })
)

export const OPPORTUNITY_FILTER_SCHEMAS = {
  [LIST_TYPE_ENUM.ODC]: ODCFormSchema,
  [LIST_TYPE_ENUM.ActiveBase]: ActiveBaseFormSchema,
  [LIST_TYPE_ENUM.Insurance]: InsuranceFormSchema,
  [LIST_TYPE_ENUM.Highlighted]: HighlightedFormSchema,
  [LIST_TYPE_ENUM.Charge]: ChargeFormSchema,
  [LIST_TYPE_ENUM.Concluded]: ConcludedFormSchema,
  [LIST_TYPE_ENUM.Pending]: PendingFormSchema
}

export const returnOPPORTUNITY_TYPE_ENUM = (listType: LIST_TYPE_ENUM) => {
  switch (Number(listType)) {
    case LIST_TYPE_ENUM.ODC:
      return OPPORTUNITY_TYPE_ENUM.ODC

    case LIST_TYPE_ENUM.ActiveBase:
      return OPPORTUNITY_TYPE_ENUM.ACTIVE_BASE

    case LIST_TYPE_ENUM.Insurance:
      return OPPORTUNITY_TYPE_ENUM.INSURANCE

    case LIST_TYPE_ENUM.Highlighted:
      return OPPORTUNITY_TYPE_ENUM.HIGHLIGHTED

    case LIST_TYPE_ENUM.Charge:
      return OPPORTUNITY_TYPE_ENUM.CHARGE

    case LIST_TYPE_ENUM.Concluded:
      return OPPORTUNITY_TYPE_ENUM.CONCLUDED

    case LIST_TYPE_ENUM.Pending:
      return OPPORTUNITY_TYPE_ENUM.PENDING

    default:
      return OPPORTUNITY_TYPE_ENUM.ODC
  }
}
