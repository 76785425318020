import { createRef, useCallback, useEffect, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { useBreakpointValue } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard, Platform, TextInput } from 'react-native'
import fieldsValidation from 'src/constants/fields-validation'
import { useSendAuthCode } from 'src/hooks/useSendAuthCode'
import {
  usePreAuthMutation,
  PreAuthPayload,
  AuthData,
  PreAuthData
} from 'src/integration/resources/auth'
import { useSendConfirmAccountEmailMutation } from 'src/integration/resources/createAccount'
import { RootStackScreenComponentProps } from 'src/navigation'
import { useSetAuthAtom } from 'src/store/auth'
import { useSetisNewVersionAtom } from 'src/store/newVersion'
import { useSetPreAuthAtom } from 'src/store/preauth'
import { useSetTokenAtom } from 'src/store/token'
import { APP_VERSION_PLATFORM_TYPE_SELECT_OPTIONS } from 'src/utils'
import {
  getDeviceDescription,
  getDeviceId,
  registerForPushNotificationsAsync
} from 'src/utils/device'
import { LogEventUserId, LogEventUserProperties } from 'src/utils/logEvents'
import { returnDescriptionSegment } from 'src/utils/users'
import * as Yup from 'yup'

import { UseLoginScreen } from './LoginScreen.types'
import Appconfig from '../../../../app.config'

export const refInputPassword = createRef<TextInput>()

export const handleInputPasswordFocus = () => refInputPassword.current?.focus()

export const REMEMBER_EMAIL_STORAGE_KEY = 'rememberEmail'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(fieldsValidation.email.invalid)
    .required(fieldsValidation.common.required),
  password: Yup.string()
    .required(fieldsValidation.common.required)
    .max(60, 'Senha ultrapassou 60 caracteres')
})

const envSSO = process.env.EXPO_PUBLIC_SSO_URL

type THandleMethodAuthentication = (type: 'sms' | 'email') => void

export const useLoginScreen: UseLoginScreen = ({
  navigation
}: Pick<RootStackScreenComponentProps<'Login'>, 'navigation'>) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [openMethodAuthenticate, setOpenMethodAuthenticate] = useState(false)

  const setPreAuthAtom = useSetPreAuthAtom()

  const setTokenAuthAtom = useSetTokenAtom()

  const setIsNewVersionAtom = useSetisNewVersionAtom()

  const { setAuthCodeAtom, handleSendAuthCode, isLoadingAuthCode } = useSendAuthCode()

  const setAuthAtom = useSetAuthAtom()

  const [showErrorAttempsLogin, setShowErrorAttempsLogin] = useState(false)

  const bottomSheetContext = useBottomSheetContext()

  const rememberEmailRef = useRef(true)

  const { control, handleSubmit, setValue, resetField } = useForm<PreAuthPayload>({
    resolver: yupResolver(schema)
  })

  const { isLoading, mutate } = usePreAuthMutation()

  const sendConfirmEmailMutation = useSendConfirmAccountEmailMutation()

  const goToResetPasswordSendEmailScreen = useCallback(() => {
    navigation.navigate('ResetPasswordSendEmail')
  }, [navigation])

  const goToTermsScreen = useCallback(() => {
    navigation.navigate('Terms')
  }, [navigation])

  const setRememberEmail = useCallback((value: boolean) => {
    rememberEmailRef.current = value
  }, [])

  const handleConfirmButtonMethodAuthentication: THandleMethodAuthentication = async (type) => {
    try {
      await handleSendAuthCode(type)

      setOpenMethodAuthenticate(false)

      navigation.navigate('LoginAuthCode')
    } catch (err) {
      setPreAuthAtom(undefined)

      setOpenMethodAuthenticate(false)

      bottomSheetContext.open({
        illustrationName: 'TimeInForWork',
        title: 'Atenção',
        description:
          'Esta ação está indisponível por excesso de tentativas com falha. Tente novamente em algumas horas.'
      })
    }
  }

  const handleSSORedirect = useCallback(() => {
    window.location.href = `${envSSO}auth/login/portal_turbo`
  }, [])

  const submit = handleSubmit(
    useCallback<SubmitHandler<PreAuthPayload>>(
      async (formData) => {
        Keyboard.dismiss()

        if (rememberEmailRef.current) {
          AsyncStorage.setItem(REMEMBER_EMAIL_STORAGE_KEY, formData.email)
        } else {
          AsyncStorage.removeItem(REMEMBER_EMAIL_STORAGE_KEY)
        }

        const device_code = await getDeviceId()

        const device_description = getDeviceDescription()

        const device_push_notification_token = await registerForPushNotificationsAsync()

        mutate(
          {
            ...formData,
            device_code,
            device_description,
            device_push_notification_token,
            platform_device: APP_VERSION_PLATFORM_TYPE_SELECT_OPTIONS.find(
              (option) => option.name === Platform.OS
            )?.id,
            app_version_code:
              Platform.OS === 'web'
                ? undefined
                : Platform.OS === 'android'
                ? Number(Appconfig.ios?.buildNumber)
                : Number(Appconfig.android?.versionCode)
          },
          {
            onError: ({ response }) => {
              if (response?.data.errors.includes('Blocked')) {
                setShowErrorAttempsLogin(false)

                resetField('password')

                navigation.navigate('LoginBlocked')
              } else {
                if (response?.data.status === 428) {
                  setIsNewVersionAtom({ is_required: true, open: true })
                } else {
                  setShowErrorAttempsLogin(true)

                  setIsNewVersionAtom({ is_required: false, open: false })
                }
              }
            },
            onSuccess: (responseData) => {
              setShowErrorAttempsLogin(false)

              if (!responseData.data.data?.is_authorized) {
                const preAuthData = responseData.data.data as PreAuthData

                if (preAuthData.status === 1) {
                  handleSendAuthCode('sms', {
                    user_id: preAuthData.id,
                    slt: responseData.headers['x-slt']
                  }).then(() =>
                    navigation.reset({
                      index: 0,
                      routes: [
                        {
                          name: 'CreateAccountAuthCode',
                          params: {
                            id: preAuthData.id,
                            slt: responseData.headers['x-slt'],
                            email: formData.email,
                            password: formData.password
                          }
                        }
                      ]
                    })
                  )
                } else if (preAuthData.status === 2) {
                  sendConfirmEmailMutation.mutate(
                    {
                      id: preAuthData.id
                    },
                    {
                      onError: () => {
                        bottomSheetContext.open({
                          description: `Usuário aguardando confirmar e-mail`,
                          title:
                            'Não foi possível enviar o e-mail de confirmação, tente novamente mais tarde'
                        })
                      },
                      onSuccess: () =>
                        navigation.reset({
                          index: 0,
                          routes: [
                            {
                              name: 'CreateAccountSendEmailConfirmation',
                              params: {
                                id: preAuthData.id,
                                email: formData.email
                              }
                            }
                          ]
                        })
                    }
                  )
                } else if (preAuthData.status === 3) {
                  navigation.navigate('LoginAccountReview')
                } else {
                  setPreAuthAtom({
                    ...formData,
                    device_code,
                    device_description,
                    device_push_notification_token
                  })

                  setAuthCodeAtom({
                    user_id: preAuthData.id,
                    phone_number_cell: preAuthData.phone_number_cell,
                    slt: responseData.headers['x-slt'],
                    email: formData.email
                  })

                  setOpenMethodAuthenticate(true)
                }
              } else {
                const authData = responseData.data.data as unknown as AuthData

                setAuthAtom(authData)

                setTokenAuthAtom(authData.auth.token)

                LogEventUserId(`${authData.user?.std_code}`)

                LogEventUserProperties({
                  business_code: returnDescriptionSegment(authData.user?.business_code),
                  std_code: `${authData.user?.std_code}`,
                  occupation: `${authData.user?.occupation}`
                })
              }

              if (responseData.data.data?.app_update) {
                setIsNewVersionAtom({ is_required: false, open: true })
              }
            }
          }
        )
      },
      [
        mutate,
        navigation,
        resetField,
        setPreAuthAtom,
        setAuthAtom,
        setTokenAuthAtom,
        setAuthCodeAtom,
        handleSendAuthCode,
        bottomSheetContext,
        sendConfirmEmailMutation,
        setIsNewVersionAtom
      ]
    )
  )

  useEffect(() => {
    const getEmailFromStorage = async () => {
      const savedEmail = await AsyncStorage.getItem(REMEMBER_EMAIL_STORAGE_KEY)

      if (savedEmail) {
        setValue('email', savedEmail)
      }
    }

    getEmailFromStorage()
  }, [setValue])

  const isAndroid = Platform.OS === 'android'

  return {
    control,
    goToResetPasswordSendEmailScreen,
    goToTermsScreen,
    handleInputPasswordFocus,
    isMobile,
    isAndroid,
    isLoading,
    setRememberEmail,
    refInputPassword,
    submit,
    showErrorAttempsLogin,
    openMethodAuthenticate,
    authCodeLoading: isLoadingAuthCode,
    handleConfirmButtonMethodAuthentication,
    handleSSORedirect
  }
}
