import { FunctionComponent, useCallback } from 'react'

import { TermsContent } from 'atoms'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { USER_STATUS_ENUM, useAcceptNewTermMutation } from 'integration/resources/auth'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  Actionsheet,
  useBreakpointValue,
  Modal,
  VStack,
  Text,
  Checkbox,
  Button,
  useDisclose,
  Heading,
  ScrollView,
  Box,
  HStack
} from 'native-base'
import { useAuthAtom } from 'src/store/auth'

import { useSetIsOpenRefuseNewTermAtom } from './BottomSheetRefuseNewTerm'

const isOpenNewTermAtom = atom(false)

export const useIsOpenNewTermAtom = () => useAtom(isOpenNewTermAtom)

export const useIsOpenNewTermAtomValue = () => useAtomValue(isOpenNewTermAtom)

export const useSetIsOpenNewTermAtom = () => useSetAtom(isOpenNewTermAtom)

const NewTermContent: FunctionComponent = () => {
  const setIsOpenRefuseNewTermAtom = useSetIsOpenRefuseNewTermAtom()

  const setIsOpenNewTermAtom = useSetIsOpenNewTermAtom()

  const acceptTermsMutation = useAcceptNewTermMutation()

  const bottomSheetContext = useBottomSheetContext()

  const [authAtom, setAuthAtom] = useAuthAtom()

  const { isOpen: isConfirmed, onToggle: handleToggleConfirm } = useDisclose(false)

  const handleOnAccept = useCallback(() => {
    acceptTermsMutation.mutate(undefined, {
      onError: () =>
        bottomSheetContext.open({
          description: 'Não foi possível aceitar os termos, tente novamente mais tarde.',
          title: 'Erro ao aceitar termos'
        }),
      onSuccess: () => {
        if (authAtom) {
          /**
           * NOTE: RootStackNavigator is listening for authAtom.
           * Once authAtom change its value, the RootStackNavigator will handle the transition to either the Onboarding screen or the Home screen.
           */
          setAuthAtom({
            ...authAtom,
            waiting_onboarding: false,
            user: {
              ...authAtom.user,
              status: USER_STATUS_ENUM.ACTIVE,
              accepted_commitment_term_at: new Date().toString(),
              accepted_responsibility_term_at: new Date().toString()
            }
          })
        }
      }
    })

    setIsOpenNewTermAtom(false)
  }, [acceptTermsMutation, authAtom, bottomSheetContext, setAuthAtom, setIsOpenNewTermAtom])

  const handleGoToRefuseTerms = useCallback(() => {
    setIsOpenRefuseNewTermAtom(true)

    setIsOpenNewTermAtom(false)
  }, [setIsOpenNewTermAtom, setIsOpenRefuseNewTermAtom])

  return (
    <VStack space={0}>
      <VStack justifyContent="center" alignItems="center">
        <Heading fontSize="24px" color="gray.700" mb={6}>
          Atualizamos os nossos termos.
        </Heading>
        <Box h={320} bg="white" borderRadius={10} p={4} pr={1}>
          <ScrollView showsVerticalScrollIndicator pagingEnabled overScrollMode="never" h={320}>
            <TermsContent />
          </ScrollView>
        </Box>
      </VStack>
      <Text bold color="gray.700" mt={6} px={4}>
        Ao clicar no botão ”Aceitar Termos” eu declaro que li e aceito os Termos de uso e de
        confidencialidade.
      </Text>
      <Box px={4} mt={6}>
        <Checkbox onChange={handleToggleConfirm} value="1">
          <Text ml={1} color="gray.500">
            Confirmo que li e concordo com os{'\n'}
            <Text bold underline fontFamily="OpenSans_700Bold">
              termos de uso e de confidencialidade
            </Text>{' '}
            do {'\n'}Portal Mais Turbo.
          </Text>
        </Checkbox>
      </Box>
      <HStack w="full" space={4} alignItems="center" mt={6}>
        <Button
          flex={1}
          onPress={handleGoToRefuseTerms}
          colorScheme="gray"
          variant="outline"
          mr="24px">
          Recusar
        </Button>
        <Button
          flex={1}
          isDisabled={!isConfirmed}
          isLoading={acceptTermsMutation.isLoading}
          colorScheme="gray"
          onPress={handleOnAccept}>
          Aceitar Termos
        </Button>
      </HStack>
    </VStack>
  )
}

export const BottomSheetNewTerm: FunctionComponent = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const isOpen = useIsOpenNewTermAtomValue()

  return isDesktop ? (
    <Modal isOpen={isOpen}>
      <Modal.Content minWidth="640px" minH={700} px={6} py={12}>
        <Modal.Body p={0}>
          <NewTermContent />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  ) : (
    <Actionsheet hideDragIndicator isOpen={isOpen} onClose={() => {}}>
      <Actionsheet.Content pt={3} pb={10}>
        <NewTermContent />
      </Actionsheet.Content>
    </Actionsheet>
  )
}
