import { FunctionComponent, PropsWithChildren } from 'react'

import { Header, Sidebar } from 'molecules'
import { VStack, HStack, ScrollView } from 'native-base'

export const InternalLayoutDesktop: FunctionComponent<
  PropsWithChildren<{
    hiddenSearch?: boolean
    hiddenScroll?: boolean
    onSearch?(search: string): void
    search?: string
    isContent?: boolean
    onPressSearch?: any
    onPressSearchClear?: () => void
  }>
> = ({
  children,
  hiddenSearch = false,
  onSearch,
  search,
  isContent,
  onPressSearch,
  hiddenScroll,
  onPressSearchClear
}) => {
  return (
    <HStack h="full">
      <Sidebar />
      {/* main content */}
      <VStack flex={1} bg="background.base">
        <VStack w="full" mx={{ '2xl': 'auto' }} flex={1} overflowY="visible">
          {/* header */}
          <Header
            hiddenSearch={hiddenSearch}
            onSearch={onSearch}
            search={search}
            isContent={isContent}
            onPressSearch={onPressSearch}
            onPressSearchClear={onPressSearchClear}
          />
          {/* header */}

          {/* content */}
          {hiddenScroll ? (
            children
          ) : (
            <ScrollView showsHorizontalScrollIndicator flex={1}>
              {children}
            </ScrollView>
          )}
        </VStack>
      </VStack>
    </HStack>
  )
}
