import { useCallback } from 'react'

import { getProfile } from 'integration/resources/user/requests'
import { useSetAuthAtom } from 'src/store/auth'
import { useSetTokenAtom } from 'src/store/token'
import { LogEventUserId, LogEventUserProperties } from 'src/utils/logEvents'
import { returnDescriptionSegment } from 'src/utils/users'

export const useAuthSSOScreen = () => {
  const setAuthAtom = useSetAuthAtom()

  const setTokenAuthAtom = useSetTokenAtom()

  const handleSSOLogin = useCallback(
    async (token: string) => {
      try {
        const response = await getProfile(token)

        const userData = response as unknown as any

        setAuthAtom({
          auth: { token },
          user: userData.data.data
        })

        setTokenAuthAtom(token)

        LogEventUserId(`${userData.data.data?.std_code}`)

        LogEventUserProperties({
          business_code: returnDescriptionSegment(userData.data.data?.business_code),
          std_code: `${userData.data.data?.std_code}`,
          occupation: `${userData.data.data?.occupation}`
        })
      } catch (err) {
        console.error('Erro no login SSO:', err)
      }
    },
    [setAuthAtom, setTokenAuthAtom]
  )

  return { handleSSOLogin }
}
