/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  TRevenueCalculatorSerialized,
  CALCULATOR_TYPE_ENUM,
  useGetCalculatorData,
  useResetCalculatorSimulationMutation,
  useSendCalculatorSimulationMutation
} from 'integration/resources/rvCalculators'
import { useBreakpointValue, useDisclose } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  goalFields,
  percentRealizedFields,
  realizedFields,
  rvSimulatedFields,
  targetFields,
  weightFields,
  formsDefaultValues
} from './constants'
import {
  RevenueCalculatorFormFields,
  SelectedTarget,
  Shape,
  UseRevenueCalculator
} from './RevenueCalculator.types'
import {
  calculateMultiplier,
  calculatePercentRealized,
  calculatePercentRealizedExpenses,
  calculateRVMultiplied,
  calculateRVSimulated,
  calculateTarget,
  calculateTotal,
  calculateTotalWithPercentage
} from '../../Utils/calculationsFunctions'
import {
  indicatorsLines,
  targets,
  performanceValues,
  mobSPFValues,
  indicatorsLinesLeader,
  chargeGoalValues
} from '../../Utils/constants'

// Dynamically create Yup validation schema based on field names
const createYupSchema = (fieldNames: (keyof RevenueCalculatorFormFields)[]) => {
  const shape: Shape = {} as Shape

  fieldNames.forEach((name: string) => {
    shape[name] = yup.number().required()
  })

  return yup.object().shape(shape)
}

// check if all values are zero, if so, it should reset with form default values
const areAllValuesZero = (revenueData: TRevenueCalculatorSerialized): boolean => {
  return Object.values(revenueData).every((value) =>
    typeof value === 'object'
      ? areAllValuesZero(value as TRevenueCalculatorSerialized)
      : value === 0
  )
}

// Validation schema creation for RV calculator form fields
const RevenueCalculatorFormSchema = createYupSchema([
  ...weightFields,
  ...targetFields,
  ...goalFields,
  ...realizedFields,
  ...percentRealizedFields,
  ...rvSimulatedFields,
  'multiplier',
  'multipliedRV',
  'performance',
  'rvTotal'
])

// Returns the first three targets for GR users (userIsGR is true), or full targets otherwise.
const getFilteredTargets = (targets: SelectedTarget[]) => targets

export const useRevenueCalculator: UseRevenueCalculator = ({
  userIsGR,
  dontShowConfirm,
  handleDontShowConfirm
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { isOpen: showTooltip, onOpen: openTooltip, onClose: closeTooltip } = useDisclose(false)

  const targetsList = useMemo(() => targets, [targets])

  const year_month = 202412

  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<RevenueCalculatorFormFields>({
    resolver: yupResolver(RevenueCalculatorFormSchema),
    mode: 'onChange',
    defaultValues: formsDefaultValues
  })

  const formFields = watch()

  const defaultTargetAccount = useMemo(() => targetsList[!userIsGR ? 3 : 0], [userIsGR])

  const [selectedTarget, setSelectedTarget] = useState<SelectedTarget>(defaultTargetAccount)

  const isRegional = useMemo(() => selectedTarget.id === '3', [selectedTarget.id])

  const { data: revenueCalculatorData, isLoading: isLoadingRevenueCalculatorData } =
    useGetCalculatorData(CALCULATOR_TYPE_ENUM.SIM_REVENUE, year_month, true)

  const revenueCalculatorFields = useMemo(
    () => revenueCalculatorData?.data.data,
    [revenueCalculatorData?.data.data]
  )

  useEffect(() => {
    if (
      revenueCalculatorFields &&
      !areAllValuesZero(revenueCalculatorFields as TRevenueCalculatorSerialized)
    ) {
      Object.entries(revenueCalculatorFields).forEach(([key, value]) => {
        setValue(key as keyof RevenueCalculatorFormFields, value)
      })

      if (revenueCalculatorFields.targetAccount)
        setSelectedTarget(
          targetsList.filter((target) => target.value === revenueCalculatorFields.targetAccount)[0]
        )
    }
  }, [revenueCalculatorFields])

  const { mutateAsync: calculatorMutateAsync, isLoading: isSendingCalculator } =
    useSendCalculatorSimulationMutation(CALCULATOR_TYPE_ENUM.SIM_REVENUE, year_month)

  const {
    mutate: resetRevenueCalculatorSimulation,
    isLoading: isResettingRevenueCalculatorSimulation
  } = useResetCalculatorSimulationMutation({
    calculatorType: CALCULATOR_TYPE_ENUM.SIM_REVENUE,
    year_month
  })

  const [linesFulfilled, setLinesFulfilled] = useState(0)

  const [multiplierContainsInsurance, setMultiplierContainsInsurance] = useState(false)

  const [successSave, setSuccessSave] = useState(false)

  const { isOpen: savedIsOpen, onClose: savedOnClose, onOpen: savedOnOpen } = useDisclose(false)

  useEffect(() => {
    if (isDirty && dontShowConfirm) {
      handleDontShowConfirm?.()
    }

    if (!isDirty && !dontShowConfirm) {
      handleDontShowConfirm?.()
    }
  }, [isDirty])

  const handleConfirmSimulation = handleSubmit(
    useCallback<SubmitHandler<RevenueCalculatorFormFields>>(
      async (formData) => {
        return calculatorMutateAsync(
          {
            calculator_json: formData,
            year_month,
            calculator_type_enum: CALCULATOR_TYPE_ENUM.SIM_REVENUE
          },
          {
            onSuccess: () => {
              setSuccessSave(true)
            }
          }
        ).then(() => {
          reset(formData)

          savedOnOpen()
        })
      },

      [reset]
    )
  )

  const handleResetSimulation = () => {
    resetRevenueCalculatorSimulation(
      { calculatorType: CALCULATOR_TYPE_ENUM.SIM_REVENUE, year_month },
      {
        onSuccess: () => {
          setSelectedTarget(targetsList[!userIsGR ? 3 : 0])

          reset(formsDefaultValues)
        }
      }
    )
  }

  // --- Weight Section ---
  // Function to update total weight and manage validation
  const updateTotalWeight = () => {
    const weightValues = [
      formFields.weightProduction,
      formFields.weightPaying,
      formFields.weightRecurrent,
      formFields.weightCharge,
      formFields.weightMobSPF,
      formFields.weightExpenses
    ]

    const total = calculateTotal(weightValues)

    setValue('weightTotal', total)

    return total !== 100
      ? setError('weightTotal', { message: 'Valor do campo precisa ser igual a 100' })
      : clearErrors('weightTotal')
  }

  useEffect(() => {
    updateTotalWeight()
  }, [
    formFields.weightMobSPF,
    formFields.weightCharge,
    formFields.weightProduction,
    formFields.weightRecurrent,
    formFields.weightPaying,
    formFields.weightExpenses
  ])

  // --- Target Section ---
  // Function to update target values for each field
  const updateTargetValues = () => {
    setValue(
      'targetProduction',
      calculateTarget(formFields.weightProduction, formFields.targetAccount)
    )

    setValue('targetPaying', calculateTarget(formFields.weightPaying, formFields.targetAccount))

    setValue(
      'targetExpenses',
      isRegional ? calculateTarget(formFields.weightExpenses, formFields.targetAccount) : 0
    )

    setValue(
      'targetRecurrent',
      calculateTarget(formFields.weightRecurrent, formFields.targetAccount)
    )

    setValue('targetCharge', calculateTarget(formFields.weightCharge, formFields.targetAccount))

    setValue('targetMobSPF', calculateTarget(formFields.weightMobSPF, formFields.targetAccount))

    const {
      targetProduction,
      targetPaying,
      targetRecurrent,
      targetCharge,
      targetMobSPF,
      targetExpenses
    } = getValues()

    const values = [
      targetProduction,
      targetPaying,
      targetRecurrent,
      targetCharge,
      targetMobSPF,
      targetExpenses
    ]

    return setValue('targetTotal', calculateTotal(values))
  }

  useEffect(() => {
    setValue('targetAccount', selectedTarget?.value ?? 0)
  }, [selectedTarget])

  useEffect(() => {
    if (formFields.targetAccount === 0) {
      setSelectedTarget(defaultTargetAccount)

      setValue('targetAccount', defaultTargetAccount.value)
    }
  }, [formFields.targetAccount])

  useEffect(() => {
    if (formFields.weightTotal === 100) updateTargetValues()
  }, [
    formFields.weightMobSPF,
    formFields.weightCharge,
    formFields.weightProduction,
    formFields.weightRecurrent,
    formFields.weightPaying,
    formFields.weightExpenses,
    formFields.weightTotal,
    formFields.targetAccount
  ])

  // --- PercentRealized Section ---
  // Function to update percent realized value for a given field
  const updatePercentRealizedValue = (
    name: keyof RevenueCalculatorFormFields,
    goal: number,
    realized: number,
    chargeGoal?: number
  ) => {
    const targetType = isMOB ? 'Recurrent' : 'Charge'

    const isTargetField = name.includes(targetType)

    const shouldApplyFixedValue = isTargetField && chargeGoal === 2

    const newValue = shouldApplyFixedValue
      ? 150
      : calculatePercentRealized(goal, realized, name.includes('Production'))

    setValue(name, newValue)
  }

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedProduction',
      formFields.goalProduction,
      formFields.realizedProduction
    )
  }, [formFields.goalProduction, formFields.realizedProduction])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedPaying',
      formFields.goalPaying,
      formFields.realizedPaying
    )
  }, [formFields.goalPaying, formFields.realizedPaying])

  useEffect(() => {
    if (isRegional)
      setValue(
        'percentRealizedExpenses',
        calculatePercentRealizedExpenses(formFields.goalExpenses, formFields.realizedExpenses)
      )
  }, [formFields.goalExpenses, formFields.realizedExpenses])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedRecurrent',
      formFields.goalRecurrent,
      formFields.realizedRecurrent,
      formFields.chargeGoal
    )
  }, [formFields.goalRecurrent, formFields.realizedRecurrent, formFields.chargeGoal])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedCharge',
      formFields.goalCharge,
      formFields.realizedCharge,
      formFields.chargeGoal
    )
  }, [formFields.goalCharge, formFields.realizedCharge, formFields.chargeGoal])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedMobSPF',
      formFields.goalMobSPF,
      formFields.realizedMobSPF
    )
  }, [formFields.goalMobSPF, formFields.realizedMobSPF])

  // --- RVSimulated Section ---
  // Function to update RV simulated value for a given field
  const updateRVSimulatedValue = (
    name: keyof RevenueCalculatorFormFields,
    target: number,
    realized: number
  ) => {
    const targetField = isMOB ? 'rvSimulatedCharge' : 'rvSimulatedMobSPF'

    const requires98 = name === targetField && (isMOB || formFields.mobSPF === 'MOB')

    const threshold = requires98 ? 98 : 100

    const shouldCalculate = linesFulfilled >= 2 && realized >= threshold

    setValue(name, shouldCalculate ? calculateRVSimulated(target, realized) : 0)
  }

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedProduction',
      formFields.targetProduction,
      formFields.percentRealizedProduction
    )
  }, [formFields.targetProduction, formFields.percentRealizedProduction, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedPaying',
      formFields.targetPaying,
      formFields.percentRealizedPaying
    )
  }, [formFields.targetPaying, formFields.percentRealizedPaying, linesFulfilled])

  useEffect(() => {
    if (isRegional)
      updateRVSimulatedValue(
        'rvSimulatedExpenses',
        formFields.targetExpenses,
        formFields.percentRealizedExpenses
      )
  }, [formFields.targetExpenses, formFields.percentRealizedExpenses, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedRecurrent',
      formFields.targetRecurrent,
      formFields.percentRealizedRecurrent
    )
  }, [formFields.targetRecurrent, formFields.percentRealizedRecurrent, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedCharge',
      formFields.targetCharge,
      formFields.percentRealizedCharge
    )
  }, [formFields.targetCharge, formFields.percentRealizedCharge, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedMobSPF',
      formFields.targetMobSPF,
      formFields.percentRealizedMobSPF
    )
  }, [formFields.targetMobSPF, formFields.percentRealizedMobSPF, linesFulfilled])

  // --- RVSimulatedTotal Section ---
  // Function to update RV simulated total value
  const updateRVSimulatedTotalValue = () => {
    const {
      rvSimulatedProduction,
      rvSimulatedPaying,
      rvSimulatedRecurrent,
      rvSimulatedCharge,
      rvSimulatedMobSPF,
      rvSimulatedExpenses
    } = getValues()

    const values = [
      rvSimulatedProduction,
      rvSimulatedPaying,
      rvSimulatedRecurrent,
      rvSimulatedCharge,
      rvSimulatedMobSPF,
      rvSimulatedExpenses
    ]

    return setValue('rvSimulatedTotal', calculateTotal(values))
  }

  useEffect(() => {
    updateRVSimulatedTotalValue()
  }, [
    formFields.rvSimulatedProduction,
    formFields.rvSimulatedPaying,
    formFields.rvSimulatedRecurrent,
    formFields.rvSimulatedCharge,
    formFields.rvSimulatedMobSPF,
    formFields.rvSimulatedExpenses
  ])

  // --- isFulfilled Section ---
  // Callback to check if a specific field is fulfilled (>= 100)
  const isFulfilled = useCallback(
    (fieldName: keyof RevenueCalculatorFormFields) => {
      const targetField = isMOB ? 'percentRealizedCharge' : 'percentRealizedMobSPF'

      const requires98 = fieldName === targetField && (isMOB || formFields.mobSPF === 'MOB')

      const threshold = requires98 ? 98 : 100

      return (formFields[fieldName] as number) >= threshold
    },
    [formFields]
  )

  useEffect(() => {
    const percentFields = isRegional
      ? percentRealizedFields
      : percentRealizedFields.filter((item) => !item.includes('Expenses'))

    const fulfilledLinesCount = percentFields.filter((field) => isFulfilled(field)).length

    setLinesFulfilled(fulfilledLinesCount)
  }, [...percentRealizedFields.map((field) => formFields[field]), isFulfilled])

  // --- containsInsurance & multiplier & multipliedRV Section ---
  useEffect(() => {
    const fieldValue = isRegional
      ? formFields.percentRealizedCharge
      : formFields.percentRealizedMobSPF

    const requires98 = isMOB || formFields.mobSPF === 'MOB'

    const threshold = requires98 ? 98 : 100

    const containsInsurance = fieldValue >= threshold

    setMultiplierContainsInsurance(containsInsurance)
  }, [isRegional, formFields.percentRealizedCharge, formFields.percentRealizedMobSPF])

  useEffect(() => {
    setValue(
      'multiplier',
      calculateMultiplier(linesFulfilled, multiplierContainsInsurance, isRegional)
    )
  }, [formFields.rvSimulatedTotal, linesFulfilled, multiplierContainsInsurance])

  useEffect(() => {
    const newMultipliedRV = calculateRVMultiplied(
      formFields.rvSimulatedTotal,
      formFields.multiplier
    )

    setValue('multipliedRV', newMultipliedRV)
  }, [formFields.multiplier, formFields.rvSimulatedTotal])

  // --- RV Total Section ---
  useEffect(() => {
    const newTotalRV = calculateTotalWithPercentage(formFields.multipliedRV, formFields.performance)

    setValue('rvTotal', newTotalRV)
  }, [formFields.performance, formFields.multipliedRV])

  const filteredTargets = useMemo(() => getFilteredTargets(targetsList), [targetsList])

  const isMOB = isRegional

  useEffect(() => {
    if (isRegional) {
      setValue('weightPaying', 10)

      setValue('weightRecurrent', 10)

      setValue('weightExpenses', 10)

      setValue('mobSPF', 'SPF')
    } else {
      setValue('weightPaying', 15)

      setValue('weightRecurrent', 15)

      setValue('weightExpenses', 0)

      setValue('mobSPF', 'MOB')
    }

    setValue('chargeGoal', 1)
  }, [isRegional])

  return {
    isMobile,
    userIsGR,
    control,
    errors,
    isDirty,
    targets: filteredTargets,
    selectedTarget,
    setSelectedTarget,
    openTooltip,
    closeTooltip,
    showTooltip,
    handleConfirmSimulation,
    handleResetSimulation,
    performanceValues,
    multiplierContainsInsurance,
    isLoading: isLoadingRevenueCalculatorData,
    isSending: isSendingCalculator || isResettingRevenueCalculatorSimulation,
    successSave,
    savedIsOpen,
    savedOnClose,
    isMOB,
    indicatorsLines: isRegional ? indicatorsLinesLeader : indicatorsLines,
    weightFields: isRegional
      ? weightFields
      : weightFields.filter((item) => !item.includes('Expenses')),
    targetFields: isRegional
      ? targetFields
      : targetFields.filter((item) => !item.includes('Expenses')),
    goalFields: isRegional ? goalFields : goalFields.filter((item) => !item.includes('Expenses')),
    realizedFields: isRegional
      ? realizedFields
      : realizedFields.filter((item) => !item.includes('Expenses')),
    percentRealizedFields: isRegional
      ? percentRealizedFields
      : percentRealizedFields.filter((item) => !item.includes('Expenses')),
    rvSimulatedFields: isRegional
      ? rvSimulatedFields
      : rvSimulatedFields.filter((item) => !item.includes('Expenses')),
    mobSPFValues: isRegional ? mobSPFValues.slice(1, 3) : mobSPFValues.slice(0, 2),
    chargeGoalValues
  }
}
