import { LIST_TYPE_ENUM } from 'src/screens/OrganizationScreen/OrganizationScreen.types'
import * as yup from 'yup'

import { TFieldConfig } from './OrderByFilterScreen.types'

export const ORDER_BY_OPTIONS_VALUE = [
  {
    id: 'desc' as 'desc',
    name: 'Maior Valor'
  },
  {
    id: 'asc' as 'asc',
    name: 'Menor Valor'
  }
]

export const ORDER_BY_OPTIONS_DATE = [
  {
    id: 'asc' as 'asc',
    name: 'Qtde. dias menor'
  },
  {
    id: 'desc' as 'desc',
    name: 'Qtde. dias maior'
  }
]

export const fieldConfig: TFieldConfig = {
  production_target_value: {
    label: 'Produção',
    options: ORDER_BY_OPTIONS_VALUE
  },
  insurance_auto_target_value: {
    label: 'Seguro Auto',
    options: ORDER_BY_OPTIONS_VALUE
  },
  spf_target_value: {
    label: 'Prestamista',
    options: ORDER_BY_OPTIONS_VALUE
  },
  debit_balance: {
    label: 'Saldo devedor',
    options: ORDER_BY_OPTIONS_VALUE
  },
  overdue_days: {
    label: 'Dias em atraso',
    options: ORDER_BY_OPTIONS_DATE
  }
}

const ODCSchema = yup.object().shape({
  production_target_value: yup.string(),
  insurance_auto_target_value: yup.string(),
  spf_target_value: yup.string()
})

const InsuranceSchema = ODCSchema

const ChargeSchema = yup.object().shape({
  debit_balance: yup.string(),
  overdue_days: yup.string()
})

export const OPPORTUNITY_ORDER_BY_SCHEMAS = {
  [LIST_TYPE_ENUM.ODC]: ODCSchema,
  [LIST_TYPE_ENUM.Insurance]: InsuranceSchema,
  [LIST_TYPE_ENUM.Charge]: ChargeSchema,
  [LIST_TYPE_ENUM.ActiveBase]: undefined,
  [LIST_TYPE_ENUM.Highlighted]: undefined,
  [LIST_TYPE_ENUM.Concluded]: undefined,
  [LIST_TYPE_ENUM.Pending]: undefined
}
