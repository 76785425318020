import { FunctionComponent, useState } from 'react'

import Icons from 'atoms/Icons'
import { OpportunitiesItem, OPPORTUNITY_TYPE_ENUM } from 'integration/resources/newOpportunities'
import {
  VStack,
  Heading,
  Text,
  HStack,
  Circle,
  Box,
  Center,
  Button,
  Modal,
  ScrollView
} from 'native-base'
import { TouchableOpacity } from 'react-native'
import { changeToCurrency } from 'src/utils/currencyFormat'

type ChargeModalProps = {
  opportunity: OpportunitiesItem
  isOpen: boolean
  onClose(): void
  handleGoToDetails?(id: string, itemType?: OPPORTUNITY_TYPE_ENUM): void
}

type ExpandTypes = 0 | 1 | 2 | undefined

type ChevronButtonProps = {
  handleExpandDetails: (type: ExpandTypes) => void
  expandDetails: ExpandTypes[]
  type: ExpandTypes
}

const renderChevronButton = ({ expandDetails, handleExpandDetails, type }: ChevronButtonProps) => (
  <TouchableOpacity onPress={() => handleExpandDetails(type)}>
    <Circle size={8} bgColor="gray.50" justifyContent="center" alignItems="center">
      <Icons.Chevron
        size="18px"
        direction={expandDetails.includes(type) ? 'up' : 'down'}
        color="gray.700"
      />
    </Circle>
  </TouchableOpacity>
)

export const ChargeModal: FunctionComponent<ChargeModalProps> = ({
  handleGoToDetails,
  opportunity,
  isOpen,
  onClose
}) => {
  const [expandDetails, setExpandDetails] = useState<ExpandTypes[]>([0])

  const handleExpandDetails = (type: ExpandTypes) =>
    expandDetails.includes(type)
      ? setExpandDetails(expandDetails.filter((prevType) => prevType !== type))
      : setExpandDetails((prev) => [...prev, type])

  return (
    <Modal isOpen={isOpen}>
      <Box bgColor="gray.50" w="100%" h="100%" safeArea p={4}>
        <HStack my={8} pb={3}>
          <TouchableOpacity onPress={onClose}>
            <Circle bgColor="white" size={10}>
              <Icons.Close size={5} color="black" />
            </Circle>
          </TouchableOpacity>
        </HStack>

        <ScrollView showsVerticalScrollIndicator={false}>
          <VStack space={10}>
            <VStack space={1}>
              <HStack>
                <Heading fontSize="14px" fontWeight={700} lineHeight="18px" color="gray.700">
                  {opportunity.contract_infos?.contract_number} •{' '}
                </Heading>
                <Heading fontSize="14px" fontWeight={700} lineHeight="18px" color="gray.700">
                  TAB {opportunity.store.tab_number}
                </Heading>
              </HStack>

              <HStack space={2}>
                <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.700">
                  Saldo Devedor:
                </Text>
                <Text fontSize="14px" fontWeight={400} lineHeight="20px" color="gray.700">
                  {changeToCurrency(opportunity.store.debit_balance ?? 0)}
                </Text>
              </HStack>

              <Center w="120px" h="24px" borderRadius="8px" bgColor="white">
                <Heading fontSize="12px" fontWeight={700} color="gray.400">
                  COBRANCA
                </Heading>
              </Center>
            </VStack>

            <VStack space={4}>
              <VStack space={4} bgColor="white" borderRadius="20px" p={4}>
                <HStack justifyContent="space-between" alignItems="center">
                  <Text fontSize="16px" fontWeight={700} lineHeight="18px" color="gray.700">
                    Dados do Cliente
                  </Text>

                  {renderChevronButton({ expandDetails, handleExpandDetails, type: 0 })}
                </HStack>

                {expandDetails.includes(0) && (
                  <VStack space={4}>
                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Nome:{' '}
                      <Text color="gray.900">
                        {opportunity.customer_infos?.customer_name
                          ? opportunity.customer_infos?.customer_name
                          : '-------'}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      CPF:{' '}
                      <Text color="gray.900">
                        {opportunity.customer_infos?.customer_cpf_cnpj
                          ? opportunity.customer_infos?.customer_cpf_cnpj
                          : '-------'}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Telefone:{' '}
                      {opportunity.customer_infos?.phone_number_01 ||
                      opportunity.customer_infos?.phone_number_02 ? (
                        Array.from({ length: 2 }, (_, index) => (
                          <Text color="gray.400" key={`info-${index}`}>
                            {' '}
                            {index === 0
                              ? opportunity.customer_infos?.phone_number_01 ?? ''
                              : opportunity.customer_infos?.phone_number_02
                              ? `/ ${opportunity.customer_infos?.phone_number_02}`
                              : ''}
                          </Text>
                        ))
                      ) : (
                        <Text color="gray.400">-------</Text>
                      )}
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Endereço:{' '}
                      <Text color="gray.400">
                        {opportunity.customer_infos?.address_city_neighborhood}
                      </Text>
                    </Text>
                  </VStack>
                )}
              </VStack>

              <VStack space={4} bgColor="white" borderRadius="20px" p={4}>
                <HStack justifyContent="space-between" alignItems="center">
                  <Text fontSize="16px" fontWeight={700} lineHeight="18px" color="gray.700">
                    Dados do Contrato
                  </Text>

                  {renderChevronButton({ expandDetails, handleExpandDetails, type: 1 })}
                </HStack>

                {expandDetails.includes(1) && (
                  <VStack space={4}>
                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      VL Prestação:{' '}
                      <Text color="gray.400">
                        {changeToCurrency(opportunity.contract_infos?.installment_value ?? 0)}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Saldo Contábil:{' '}
                      <Text color="gray.400">
                        {changeToCurrency(opportunity.contract_infos?.accounting_balance ?? 0)}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Saldo Devedor:{' '}
                      <Text color="gray.400">
                        {changeToCurrency(opportunity.store?.debit_balance ?? 0)}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Safra de Origem:{' '}
                      <Text color="gray.400">
                        {opportunity.contract_infos?.safra ?? '-------------'}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Qtde. de Contratos:{' '}
                      <Text color="gray.400">
                        {opportunity.contract_infos?.installment_quantity}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Parcelas em atraso:{' '}
                      <Text color="gray.400">
                        {opportunity.contract_infos?.installment_quantity_delayed}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Primeira parc. vencida:{' '}
                      <Text color="gray.400">
                        {opportunity.contract_infos?.installment_first_delayed ?? '-------------'}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Rastreador:{' '}
                      <Text color="gray.400">
                        {opportunity.contract_infos?.tracker ? 'Sim' : 'Não'}
                      </Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      CNPJ: <Text color="gray.400">{opportunity.store?.store_cnpj_number}</Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Nome Fantasia: <Text color="gray.400">{opportunity.store?.name_fantasy}</Text>
                    </Text>
                  </VStack>
                )}
              </VStack>

              <VStack space={4} bgColor="white" borderRadius="20px" p={4}>
                <HStack justifyContent="space-between" alignItems="center">
                  <Text fontSize="16px" fontWeight={700} lineHeight="18px" color="gray.700">
                    Dados do Veículo
                  </Text>

                  {renderChevronButton({ expandDetails, handleExpandDetails, type: 2 })}
                </HStack>

                {expandDetails.includes(2) && (
                  <VStack space={4}>
                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Modelo: <Text color="gray.400">{opportunity.car_infos?.car_model}</Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Chassi:{' '}
                      <Text color="gray.400">{opportunity.car_infos?.car_chassis_number}</Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Ano: <Text color="gray.400">{opportunity.car_infos?.car_year_model}</Text>
                    </Text>

                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      lineHeight="24px"
                      color="gray.900"
                      flexWrap="wrap">
                      Placa:{' '}
                      <Text color="gray.400">{opportunity.car_infos?.car_license_plate}</Text>
                    </Text>
                  </VStack>
                )}
              </VStack>
            </VStack>

            {handleGoToDetails && (
              <Button
                variant="outline"
                colorScheme="gray"
                px={6}
                _text={{ fontSize: '14px', fontWeight: 700, lineHeight: '20px' }}
                onPress={() =>
                  handleGoToDetails?.(
                    opportunity.opportunity_odc_id ?? '',
                    opportunity.opportunity_type_enum
                  )
                }
                _pressed={{ opacity: 0.7 }}>
                Ir para oportunidade
              </Button>
            )}
          </VStack>
        </ScrollView>
      </Box>
    </Modal>
  )
}
