import React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TAdjustments, TGoalAdjustment } from 'integration/resources/goals'
import {
  correctDate,
  formatDate,
  formatReferenceMonth
} from 'src/screens/WalletsManagement/Components/ReportPDF/utils'
import { capitalizeText } from 'src/utils'

import { formatGoalValue, formatPercentageOrUnit } from './ReportPDF'

export const GoalAdjustmentCard: React.FC<TGoalAdjustment & { goalType: TAdjustments['type'] }> = ({
  staff_name,
  occupation,
  staff_std_code,
  current,
  history,
  goalType
}) => (
  <View style={styles.container} wrap={false}>
    <View style={styles.header}>
      <Text style={styles.staff_name}>{capitalizeText(staff_name)}</Text>
      <Text style={styles.roleInfo}>
        {occupation} - matrícula {staff_std_code}
      </Text>
    </View>

    <View style={styles.row}>
      <View style={styles.block}>
        <Text style={styles.blockTitle}>
          Mês de referência: {formatReferenceMonth(correctDate(String(current.year_month)))}
        </Text>
        <View style={styles.divider} />
        <View style={styles.textRow}>
          <Text style={styles.label}>Meta enviada:</Text>
          <Text style={styles.value}>{formatGoalValue(goalType, current.default_goal)}</Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Meta ajustada:</Text>
          <Text style={styles.value}>
            {formatGoalValue(goalType, current.adjusted_goal)} ({current.percentage > 0 ? '+' : ''}
            {formatPercentageOrUnit(goalType, current.percentage)})
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Data do último ajuste:</Text>
          <Text style={styles.value}>
            {formatDate(current.last_adjustment_date ?? '', "dd'/'MM'/'yyyy h:mm a")}
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Ajustado por:</Text>
          <Text style={styles.value}>{current.adjusted_by}</Text>
        </View>
      </View>

      <View style={styles.block}>
        <Text style={styles.blockTitle}>
          Histórico de meta: {formatReferenceMonth(correctDate(String(history.year_month)))}
        </Text>
        <View style={styles.divider} />
        <View style={styles.textRow}>
          <Text style={styles.label}>Meta enviada:</Text>
          <Text style={styles.value}>{formatGoalValue(goalType, history.default_goal)}</Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Meta ajustada:</Text>
          <Text style={styles.value}>
            {formatGoalValue(goalType, history.adjusted_goal)} ({history.percentage > 0 ? '+' : ''}
            {formatPercentageOrUnit(goalType, history.percentage)})
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Data do último ajuste:</Text>
          <Text style={styles.value}>
            {formatDate(history.last_adjustment_date ?? '', "dd'/'MM'/'yyyy h:mm a")}
          </Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.label}>Ajustado por:</Text>
          <Text style={styles.value}>{history.adjusted_by}</Text>
        </View>
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    border: '1px solid #DBDBDB',
    borderRadius: 10,
    padding: 8,
    paddingTop: 16,
    marginBottom: 16,
    break: 'page'
  },
  header: {
    flexDirection: 'row',
    gap: 4,
    marginBottom: 4
  },
  staff_name: {
    fontFamily: 'Assistant',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#4D4D4D'
  },
  roleInfo: {
    fontFamily: 'Assistant',
    fontWeight: 'normal',
    fontSize: 12,
    color: '#4D4D4D'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 4
  },
  block: {
    flex: 1,
    paddingVertical: 6,
    paddingHorizontal: 9,
    backgroundColor: '#FAFAFA',
    borderRadius: 4,
    gap: 6
  },
  blockTitle: {
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    fontSize: 10,
    color: '#0D0D0D'
  },
  textRow: {
    flexDirection: 'row',
    gap: 4
  },
  label: {
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    fontSize: 10,
    color: '#0D0D0D'
  },
  value: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#0D0D0D'
  },
  divider: {
    height: 1,
    backgroundColor: '#DBDBDB',
    borderRadius: 10
  }
})
