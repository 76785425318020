import { useCallback, useMemo } from 'react'

import { useGetOpportunityDetailsOdcItemQuery } from 'integration/resources/newOpportunities'
import { useBreakpointValue } from 'native-base'

import { LEADS_APNEF_SCREEN_ENUM, UseLeadsApnefScreen } from './LeadsApnefScreen.types'

export const useLeadsApnefScreen: UseLeadsApnefScreen = ({ navigation, route }) => {
  const isLeads = useMemo(
    () => route.params.type === LEADS_APNEF_SCREEN_ENUM.Leads,
    [route.params.type]
  )

  const opportunityId = route.params.opportunityId

  const { data: opportunityData, isLoading } = useGetOpportunityDetailsOdcItemQuery(opportunityId)

  const leadsApnefItem = useMemo(
    () => opportunityData?.data.data.accelerators,
    [opportunityData?.data.data.accelerators]
  )

  const infos = isLeads ? leadsApnefItem?.leads : leadsApnefItem?.apnfes

  const infosQuantity = isLeads ? leadsApnefItem?.leads.length : leadsApnefItem?.apnfes.length

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  return {
    isMobile,
    isLeads,
    handleGoBack,
    infos,
    infosQuantity,
    isLoading
  }
}
