export type TParams = {
  leader_std_code?: string
  staffId?: string
  page?: number
  per_page?: number
  order_by?: string
  order_by_direction?: string
  search?: string
  enabled?: boolean
  assigned_stores?: boolean
  goal_status?: INDIVIDUAL_PERIOD_STATUS_ENUM
  goals_adjustments?: TGoalsUpdatesItem
}

export enum PERIOD_STATUS_ENUM {
  OPENED = 1,
  CLOSED = 2
}

export type TGoals = {
  production: number
  charge: number
  active_base: number
  accounts: number
  wm: number
  spf: number
  auto: number
}

export type TGoalsSummary = {
  id: string
  status: PERIOD_STATUS_ENUM
  finished_at: string // ISO 8601
  created_at: string // ISO 8601
  totals: TGoals
  percentage: {
    production: string
    charge: string
    active_base: string
    accounts: string
    wm: string
    spf: string
    auto: string
  }
}

export type Leader = {
  name: string
  std_code: number
  profile_image: string | null
  occupation: string
  network_name: string
  subsidiary_name: string
}

export type TGoalsLeadersListItem = {
  leader: Leader
  status: INDIVIDUAL_PERIOD_STATUS_ENUM
  total_goal: string
  total_specialists: string
}

export enum META_TYPE_ENUM {
  Production = 'production',
  Charge = 'charge',
  ActiveBase = 'active_base',
  MoreAccounts = 'accounts',
  Webmotors = 'wm',
  Insurance = 'spf',
  InsuranceAuto = 'auto'
}

export type TListsParams = {
  enabled?: boolean
  leader_std_code?: string
  search?: string
  page?: number
  per_page?: number
  order_by?: 'user_name' | 'meta_value'
  order_by_direction?: 'asc' | 'desc'
  meta_type?: META_TYPE_ENUM
  filters?: Record<string, string | number | boolean>
}

export enum BUSINESS_TYPES_ENUM {
  VEHICLES = 1,
  GOODS_AND_SERVICES = 2,
  ALL = 3
}

export type TOpenGoalsPeriodParams = {
  date_start: string
  date_end: string
  leader_std_code?: number
  business?: BUSINESS_TYPES_ENUM
  goals?: TGoals
}

export type TChangePeriodParams = {
  periodId: string
  status: INDIVIDUAL_PERIOD_STATUS_ENUM
  leader_std_code?: number
}

export type TGoalsDetails = {
  id: string
  production: TMetric
  wm: TMetric
  spf: TMetric
  accounts: TMetric
  charge: TMetric
  auto: TMetric
  active_base: TMetric
}

export type TMetric = {
  goal?: string
  current: string
  delta: string
  percentage: string
  percentage_period: string
}

export enum INDIVIDUAL_PERIOD_STATUS_ENUM {
  OPENED = 1,
  CLOSED = 2,
  COMPLETED = 3,
  REACTIVATED = 4
}

export const GoalsStatusLabels: { [key in INDIVIDUAL_PERIOD_STATUS_ENUM]: string } = {
  [INDIVIDUAL_PERIOD_STATUS_ENUM.OPENED]: 'Ativo',
  [INDIVIDUAL_PERIOD_STATUS_ENUM.CLOSED]: 'Fechado',
  [INDIVIDUAL_PERIOD_STATUS_ENUM.COMPLETED]: 'Concluído',
  [INDIVIDUAL_PERIOD_STATUS_ENUM.REACTIVATED]: 'Ativo'
}

export type TGoalsLeaderDetails = {
  id: string
  status: INDIVIDUAL_PERIOD_STATUS_ENUM
  finished_at: string // ISO 8601
  created_at: string // ISO 8601
  user: {
    name: string
    occupation: string
    network_name: string
    subsidiary_name: string
    std_code: number
  }
  deltas: TGoals
  totals: TGoals
}

export type TGoalsStaffListItem = {
  id: string
  user: {
    name: string
    std_code: number
    profile_image?: string
    occupation: string
  }
  goals: {
    goal: string
    current: string
    delta?: number
    percentage?: number
  }
  updated_at: string // ISO 8601
  updated_by: string // ISO 8601
}

export type TGoal = {
  key: string
  title: string
  goal?: number
  current: number
  delta: number
  percentage: number
}

export type TAdjustmentItem = {
  adjusted_value: number
  adjusted_percent: number
  value: number
}

export type TGoalsItem = {
  production: TAdjustmentItem
  spf: TAdjustmentItem
  auto: TAdjustmentItem
  wm: TAdjustmentItem
  accounts: TAdjustmentItem
  charge: TAdjustmentItem
  active_base: TAdjustmentItem
}

export type TUpdateGoalItem = {
  delta?: number
  value: number
  percentage?: number
}

export type TUpdatesItem = {
  production?: TUpdateGoalItem
  spf?: TUpdateGoalItem
  auto?: TUpdateGoalItem
  wm?: TUpdateGoalItem
  accounts?: TUpdateGoalItem
  charge?: TUpdateGoalItem
  active_base?: TUpdateGoalItem
}

export type TGoalsUpdatesItem = {
  id: string
  updates: TUpdatesItem
}

export type AdjustmentItem = {
  year_month: number
  default_goal: number
  adjusted_goal: number
  percentage: number
  last_adjustment_date: string
  adjusted_by: string
}

export type TGoalAdjustment = {
  staff_name: string
  occupation: string
  staff_std_code: string
  current: AdjustmentItem
  history: AdjustmentItem
}

export type TAdjustments = {
  type:
    | 'Seguro Prestamista'
    | 'Cobranças'
    | 'Seguro Auto'
    | 'Produção'
    | '+Contas'
    | 'Webmotors'
    | 'Base Ativa'

  year_month: number
  goal: number
  adjustments: TGoalAdjustment[]
}

export type TReportHeader = {
  leader_std_code?: number
  leader_name?: string
  current_date?: string
  year_month?: number
  started_at?: string // ISO 8601
  finished_at?: string // ISO 8601
}

export type TGoalsReportSerialized = {
  file_url?: string
  file_name?: string
  header?: TReportHeader
  goal_adjustments?: TAdjustments[]
}

export type GoalData = {
  goal: string
  current: string
  percentage: string
}

export type Adjustment = {
  adjusted_by: string
  last_adjustment_date: string
}

export type ReportItem = {
  accounts: GoalData
  active_base: GoalData
  auto: GoalData
  charge: GoalData
  production: GoalData
  spf: GoalData
  wm: GoalData
  year_month: number
  adjustment: Adjustment
}

export type ReportEntry = {
  current: ReportItem
  history: ReportItem
  specialist: {
    name: string
    std_code: number
    occupation: string
  }
}

export type GoalsSummary = {
  wm: string
  spf: string
  auto: string
  charge: string
  accounts: string
  production: string
  active_base: string
}

export type Header = {
  goals: GoalsSummary
  period: {
    status: PERIOD_STATUS_ENUM
    started_at: string
    finished_at: string
  }
  occupation: string
  leader_name: string
  network_name: string
  subsidiary_name: string
}

export type ReportData = {
  header: Header
  goals_report: ReportEntry[]
}

export type TReport = {
  id: string
  leader_std_code: number
  year_month: number
  status: number
  file_name?: string
  report_data: ReportData
}
