import { FunctionComponent } from 'react'

import { NOTIFICATION_CHOICES_ENUM, TNotReadResponse } from 'integration/resources/notifications'
import { Circle, HStack, Pressable, Skeleton, Text } from 'native-base'
import { LogEvent } from 'src/utils/logEvents'

type TNotificationsFiltersMenuProps = {
  empty?: boolean
  isLoading?: boolean
  notificationsCount?: TNotReadResponse
  filterSelected?: NOTIFICATION_CHOICES_ENUM
  handleApplyFilter(type?: NOTIFICATION_CHOICES_ENUM): void
  notificationsTotal?: number
}

export const NotificationsFiltersMenu: FunctionComponent<TNotificationsFiltersMenuProps> = ({
  empty,
  filterSelected,
  isLoading,
  notificationsCount,
  handleApplyFilter,
  notificationsTotal
}) => {
  return !empty ? (
    isLoading ? (
      <HStack space={4}>
        <Skeleton borderRadius="26px" w="146px" h={10} />
        <Skeleton borderRadius="26px" w="146px" h={10} />
        <Skeleton borderRadius="26px" w="146px" h={10} />
        <Skeleton borderRadius="26px" w="146px" h={10} />
        <Skeleton borderRadius="26px" w="146px" h={10} />
        <Skeleton borderRadius="26px" w="146px" h={10} />
      </HStack>
    ) : (
      <HStack space={4}>
        <Pressable
          isDisabled={filterSelected === undefined}
          onPress={() => {
            LogEvent('notificacoes_todas', {
              item_id: ''
            })

            handleApplyFilter()
          }}>
          <HStack
            bg={filterSelected === undefined ? 'primary.50' : 'white'}
            borderRadius="26px"
            px={4}
            py="10px"
            h={10}
            space={2}>
            <Text
              fontSize="16px"
              fontWeight={400}
              lineHeight="22px"
              mb={8}
              color={filterSelected === undefined ? 'primary.500' : 'gray.700'}>
              Todas
            </Text>
          </HStack>
        </Pressable>

        {!!notificationsCount?.wallet && (
          <Pressable
            isDisabled={filterSelected === NOTIFICATION_CHOICES_ENUM.WALLET}
            onPress={() => {
              LogEvent('notificacoes_carteira', {
                item_id: ''
              })

              handleApplyFilter(NOTIFICATION_CHOICES_ENUM.WALLET)
            }}>
            <HStack
              bg={filterSelected === NOTIFICATION_CHOICES_ENUM.WALLET ? 'primary.50' : 'white'}
              borderRadius="26px"
              px={4}
              py="10px"
              h={10}
              space={2}>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="22px"
                mb={8}
                color={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.WALLET ? 'primary.500' : 'gray.700'
                }>
                Carteira
              </Text>
              <Circle
                bg={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.WALLET ? 'primary.500' : 'primary.50'
                }
                px="2px"
                py="2px"
                size={6}>
                <Text
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="20px"
                  color={
                    filterSelected === NOTIFICATION_CHOICES_ENUM.WALLET ? 'white' : 'primary.500'
                  }>
                  {notificationsCount?.wallet}
                </Text>
              </Circle>
            </HStack>
          </Pressable>
        )}

        {!!notificationsCount?.content && (
          <Pressable
            isDisabled={filterSelected === NOTIFICATION_CHOICES_ENUM.CONTENT}
            onPress={() => {
              LogEvent('notificacoes_conteudos', {
                item_id: ''
              })

              handleApplyFilter(NOTIFICATION_CHOICES_ENUM.CONTENT)
            }}>
            <HStack
              bg={filterSelected === NOTIFICATION_CHOICES_ENUM.CONTENT ? 'primary.50' : 'white'}
              borderRadius="26px"
              px={4}
              py="10px"
              h={10}
              space={2}>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="22px"
                mb={8}
                color={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.CONTENT ? 'primary.500' : 'gray.700'
                }>
                Conteúdos
              </Text>
              <Circle
                bg={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.CONTENT
                    ? 'primary.500'
                    : 'primary.50'
                }
                px="2px"
                py="2px"
                size={6}>
                <Text
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="20px"
                  color={
                    filterSelected === NOTIFICATION_CHOICES_ENUM.CONTENT ? 'white' : 'primary.500'
                  }>
                  {notificationsCount?.content}
                </Text>
              </Circle>
            </HStack>
          </Pressable>
        )}

        {!!notificationsCount?.communication && (
          <Pressable
            isDisabled={filterSelected === NOTIFICATION_CHOICES_ENUM.COMMUNICATION}
            onPress={() => {
              LogEvent('notificacoes_comunicados', {
                item_id: ''
              })

              handleApplyFilter(NOTIFICATION_CHOICES_ENUM.COMMUNICATION)
            }}>
            <HStack
              bg={
                filterSelected === NOTIFICATION_CHOICES_ENUM.COMMUNICATION ? 'primary.50' : 'white'
              }
              borderRadius="26px"
              px={4}
              py="10px"
              h={10}
              space={2}>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="22px"
                mb={8}
                color={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.COMMUNICATION
                    ? 'primary.500'
                    : 'gray.700'
                }>
                Comunicados
              </Text>
              <Circle
                bg={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.COMMUNICATION
                    ? 'primary.500'
                    : 'primary.50'
                }
                px="2px"
                py="2px"
                size={6}>
                <Text
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="20px"
                  color={
                    filterSelected === NOTIFICATION_CHOICES_ENUM.COMMUNICATION
                      ? 'white'
                      : 'primary.500'
                  }>
                  {notificationsCount?.communication}
                </Text>
              </Circle>
            </HStack>
          </Pressable>
        )}

        {!!notificationsCount?.organization && (
          <Pressable
            isDisabled={filterSelected === NOTIFICATION_CHOICES_ENUM.ORGANIZATION}
            onPress={() => {
              LogEvent('notificacoes_minha_organizacao', {
                item_id: ''
              })

              handleApplyFilter(NOTIFICATION_CHOICES_ENUM.ORGANIZATION)
            }}>
            <HStack
              bg={
                filterSelected === NOTIFICATION_CHOICES_ENUM.ORGANIZATION ? 'primary.50' : 'white'
              }
              borderRadius="26px"
              px={4}
              py="10px"
              h={10}
              space={2}>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="22px"
                mb={8}
                color={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.ORGANIZATION
                    ? 'primary.500'
                    : 'gray.700'
                }>
                Minha Organização
              </Text>
              <Circle
                bg={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.ORGANIZATION
                    ? 'primary.500'
                    : 'primary.50'
                }
                px="2px"
                py="2px"
                size={6}>
                <Text
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="20px"
                  color={
                    filterSelected === NOTIFICATION_CHOICES_ENUM.ORGANIZATION
                      ? 'white'
                      : 'primary.500'
                  }>
                  {notificationsCount?.organization}
                </Text>
              </Circle>
            </HStack>
          </Pressable>
        )}

        {!!notificationsCount?.birthdate && (
          <Pressable
            isDisabled={filterSelected === NOTIFICATION_CHOICES_ENUM.BIRTHDATE}
            onPress={() => {
              LogEvent('notificacoes_aniversarios', {
                item_id: ''
              })

              handleApplyFilter(NOTIFICATION_CHOICES_ENUM.BIRTHDATE)
            }}>
            <HStack
              bg={filterSelected === NOTIFICATION_CHOICES_ENUM.BIRTHDATE ? 'primary.50' : 'white'}
              borderRadius="26px"
              px={4}
              py="10px"
              h={10}
              space={2}>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="22px"
                mb={8}
                color={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.BIRTHDATE
                    ? 'primary.500'
                    : 'gray.700'
                }>
                Aniversários
              </Text>
              <Circle
                bg={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.BIRTHDATE
                    ? 'primary.500'
                    : 'primary.50'
                }
                px="2px"
                py="2px"
                size={6}>
                <Text
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="20px"
                  color={
                    filterSelected === NOTIFICATION_CHOICES_ENUM.BIRTHDATE ? 'white' : 'primary.500'
                  }>
                  {notificationsCount?.birthdate}
                </Text>
              </Circle>
            </HStack>
          </Pressable>
        )}

        {!!notificationsCount?.opportunity && (
          <Pressable
            isDisabled={filterSelected === NOTIFICATION_CHOICES_ENUM.OPPORTUNITY}
            onPress={() => {
              LogEvent('notificacoes_oportunidades', {
                item_id: ''
              })

              handleApplyFilter(NOTIFICATION_CHOICES_ENUM.OPPORTUNITY)
            }}>
            <HStack
              bg={filterSelected === NOTIFICATION_CHOICES_ENUM.OPPORTUNITY ? 'primary.50' : 'white'}
              borderRadius="26px"
              px={4}
              py="10px"
              h={10}
              space={2}>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="22px"
                mb={8}
                color={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.OPPORTUNITY
                    ? 'primary.500'
                    : 'gray.700'
                }>
                Oportunidades
              </Text>
              <Circle
                bg={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.OPPORTUNITY
                    ? 'primary.500'
                    : 'primary.50'
                }
                px="2px"
                py="2px"
                size={6}>
                <Text
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="20px"
                  color={
                    filterSelected === NOTIFICATION_CHOICES_ENUM.OPPORTUNITY
                      ? 'white'
                      : 'primary.500'
                  }>
                  {notificationsCount?.opportunity}
                </Text>
              </Circle>
            </HStack>
          </Pressable>
        )}

        {!!notificationsCount?.activity && (
          <Pressable
            isDisabled={filterSelected === NOTIFICATION_CHOICES_ENUM.ACTIVITY}
            onPress={() => handleApplyFilter(NOTIFICATION_CHOICES_ENUM.ACTIVITY)}>
            <HStack
              bg={filterSelected === NOTIFICATION_CHOICES_ENUM.ACTIVITY ? 'primary.50' : 'white'}
              borderRadius="26px"
              px={4}
              py="10px"
              h={10}
              space={2}>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="22px"
                mb={8}
                color={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.ACTIVITY ? 'primary.500' : 'gray.700'
                }>
                Tarefas
              </Text>
              <Circle
                bg={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.ACTIVITY
                    ? 'primary.500'
                    : 'primary.50'
                }
                px="2px"
                py="2px"
                size={6}>
                <Text
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="20px"
                  color={
                    filterSelected === NOTIFICATION_CHOICES_ENUM.ACTIVITY ? 'white' : 'primary.500'
                  }>
                  {notificationsCount?.activity}
                </Text>
              </Circle>
            </HStack>
          </Pressable>
        )}

        {!!notificationsCount?.new_wallet && (
          <Pressable
            isDisabled={filterSelected === NOTIFICATION_CHOICES_ENUM.NEW_WALLET}
            onPress={() => handleApplyFilter(NOTIFICATION_CHOICES_ENUM.NEW_WALLET)}>
            <HStack
              bg={filterSelected === NOTIFICATION_CHOICES_ENUM.NEW_WALLET ? 'primary.50' : 'white'}
              borderRadius="26px"
              px={4}
              py="10px"
              h={10}
              space={2}>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="22px"
                mb={8}
                color={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.NEW_WALLET
                    ? 'primary.500'
                    : 'gray.700'
                }>
                Novos Carteira
              </Text>
              <Circle
                bg={
                  filterSelected === NOTIFICATION_CHOICES_ENUM.NEW_WALLET
                    ? 'primary.500'
                    : 'primary.50'
                }
                px="2px"
                py="2px"
                size={6}>
                <Text
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="20px"
                  color={
                    filterSelected === NOTIFICATION_CHOICES_ENUM.NEW_WALLET
                      ? 'white'
                      : 'primary.500'
                  }>
                  {notificationsCount?.new_wallet}
                </Text>
              </Circle>
            </HStack>
          </Pressable>
        )}
      </HStack>
    )
  ) : (
    <></>
  )
}
