/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import {
  TCalculatorDataSerialized,
  CALCULATOR_TYPE_ENUM,
  useGetCalculatorData,
  useResetCalculatorSimulationMutation,
  useSendCalculatorSimulationMutation
} from 'integration/resources/rvCalculators'
import { useBreakpointValue, useDisclose } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  goalFields,
  percentRealizedFields,
  realizedFields,
  rvSimulatedFields,
  targetFields,
  weightFields,
  formsDefaultValues,
  acceleratorRealizedFields,
  rvAcceleratorFields,
  expensesFields
} from './constants'
import {
  RevenueCalculatorFormFields,
  SelectedTarget,
  Shape,
  UseRevenueCalculator
} from './RevenueCalculator.types'
import {
  calculateAcceleratorRealized,
  calculateMultiplier,
  calculatePercentRealized,
  calculateRVMultiplied,
  calculateRVSimulated,
  calculateTarget,
  calculateTotal,
  calculateTotalWithPercentage,
  calculateWeightedAverage,
  calculateWeightedMean
} from '../../Utils/calculationsFunctions'
import {
  indicatorsLines,
  targets,
  performanceValues,
  expensesValues,
  chargeGoalValues
} from '../../Utils/constants'

// Dynamically create Yup validation schema based on field names
const createYupSchema = (fieldNames: (keyof RevenueCalculatorFormFields)[]) => {
  const shape: Shape = {} as Shape

  fieldNames.forEach((name: string) => {
    shape[name] = yup.number().required()
  })

  return yup.object().shape(shape)
}

// check if all values are zero, if so, it should reset with form default values
const areAllValuesZero = (revenueData: TCalculatorDataSerialized): boolean => {
  return Object.values(revenueData).every((value) =>
    typeof value === 'object' ? areAllValuesZero(value as TCalculatorDataSerialized) : value === 0
  )
}

// Validation schema creation for RV calculator form fields
const RevenueCalculatorFormSchema = createYupSchema([
  ...weightFields,
  ...targetFields,
  ...goalFields,
  ...realizedFields,
  ...percentRealizedFields,
  ...rvSimulatedFields,
  ...acceleratorRealizedFields,
  ...rvAcceleratorFields,
  ...expensesFields,
  'multiplier',
  'multipliedRV',
  'performance',
  'rvTotal'
])

// Returns the first three targets for GR users (userIsGR is true), or full targets otherwise.
const getFilteredTargets = (targets: SelectedTarget[], userIsGR?: boolean) =>
  userIsGR ? targets.slice(0, 3) : targets

export const useRevenueCalculator: UseRevenueCalculator = ({
  userIsGR,
  dontShowConfirm,
  handleDontShowConfirm
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const year_month = Number(format(new Date(), 'yyyyMM'))

  const [tooltipFieldName, setTooltipFieldName] = useState<string | undefined>()

  const handleOpenTooltip = (fieldName?: string) => {
    setTooltipFieldName(fieldName)
  }

  const handleCloseTooltip = () => setTooltipFieldName(undefined)

  const targetsList = useMemo(
    () => targets.filter((target) => !['3', '4', '5'].includes(target.id)),
    []
  )

  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<RevenueCalculatorFormFields>({
    resolver: yupResolver(RevenueCalculatorFormSchema),
    mode: 'onChange',
    defaultValues: formsDefaultValues
  })

  const formFields = watch()

  const defaultTargetAccount = useMemo(() => targetsList[userIsGR ? 0 : 3], [userIsGR])

  const [selectedTarget, setSelectedTarget] = useState<SelectedTarget>(defaultTargetAccount)

  const { data: revenueData, isLoading: isLoadingRevenueData } = useGetCalculatorData(
    CALCULATOR_TYPE_ENUM.BUSINESS_BLOCK,
    year_month,
    true
  )

  const revenueFields = useMemo(() => revenueData?.data.data, [revenueData?.data.data])

  useEffect(() => {
    if (revenueFields && !areAllValuesZero(revenueFields)) {
      Object.entries(revenueFields).forEach(([key, value]) => {
        setValue(key as keyof RevenueCalculatorFormFields, value as number)
      })

      if (revenueFields.targetAccount)
        setSelectedTarget(
          targetsList.filter((target) => target.value === revenueFields.targetAccount)[0]
        )
    }
  }, [revenueFields])

  useEffect(() => {
    if (revenueFields && !areAllValuesZero(revenueFields)) {
      Object.entries(revenueFields).forEach(([key, value]) => {
        setValue(key as keyof RevenueCalculatorFormFields, value as number)
      })

      if (revenueFields.targetAccount)
        setSelectedTarget(
          targetsList.filter((target) => target.value === revenueFields.targetAccount)[0]
        )
    }
  }, [revenueFields])

  const { mutateAsync, isLoading: isSendingSimulation } = useSendCalculatorSimulationMutation(
    CALCULATOR_TYPE_ENUM.BUSINESS_BLOCK,
    year_month
  )

  const { mutate: resetRevenueSimulation, isLoading: isResettingRevenueSimulation } =
    useResetCalculatorSimulationMutation({
      calculatorType: CALCULATOR_TYPE_ENUM.BUSINESS_BLOCK,
      year_month
    })

  const [linesFulfilled, setLinesFulfilled] = useState(0)

  const [multiplierContainsInsurance, setMultiplierContainsInsurance] = useState(false)

  const [successSave, setSuccessSave] = useState(false)

  const { isOpen: savedIsOpen, onClose: savedOnClose, onOpen: savedOnOpen } = useDisclose(false)

  useEffect(() => {
    if (isDirty && dontShowConfirm) {
      handleDontShowConfirm?.()
    }

    if (!isDirty && !dontShowConfirm) {
      handleDontShowConfirm?.()
    }
  }, [isDirty])

  const handleConfirmSimulation = handleSubmit(
    useCallback<SubmitHandler<RevenueCalculatorFormFields>>(
      async (formData) => {
        return mutateAsync(
          {
            calculator_json: formData,
            target: formData.targetAccount,
            total: formData.rvTotal,
            year_month,
            calculator_type_enum: CALCULATOR_TYPE_ENUM.BUSINESS_BLOCK
          },
          {
            onSuccess: () => {
              setSuccessSave(true)
            }
          }
        ).then(() => {
          reset(formData)

          savedOnOpen()
        })
      },

      [reset]
    )
  )

  const handleResetSimulation = () => {
    resetRevenueSimulation(
      { calculatorType: CALCULATOR_TYPE_ENUM.BUSINESS_BLOCK, year_month },
      {
        onSuccess: () => {
          setSelectedTarget(targetsList[userIsGR ? 0 : 3])

          reset(formsDefaultValues)
        }
      }
    )
  }

  // --- Ecosystem Section ---
  const updateEcosystemGoal = () => {
    const ecosystemValues = [formFields.goalActiveBase, formFields.goalMoreAccounts]

    const total = calculateTotal(ecosystemValues)

    setValue('goalEcosystem', total)
  }

  useEffect(() => {
    updateEcosystemGoal()
  }, [formFields.goalActiveBase, formFields.goalMoreAccounts])

  const updateEcosystemRealized = () => {
    const ecosystemValues = [formFields.realizedActiveBase, formFields.realizedMoreAccounts]

    const total = calculateTotal(ecosystemValues)

    setValue('realizedEcosystem', total)
  }

  useEffect(() => {
    updateEcosystemRealized()
  }, [formFields.realizedActiveBase, formFields.realizedMoreAccounts])

  const updateEcosystemWeight = () => {
    const weightValues = [formFields.weightActiveBase, formFields.weightMoreAccounts]

    const total = calculateTotal(weightValues)

    if (total !== 100) {
      setError('weightEcosystem', {
        message: 'Soma das porcentagens de Base Ativa e +Contas precisa ser igual a 100%.'
      })

      return false
    }

    clearErrors('weightEcosystem')

    return true
  }

  useEffect(() => {
    if (updateEcosystemWeight()) {
      setValue(
        'percentRealizedEcosystem',
        calculateWeightedMean(
          [formFields.percentRealizedMoreAccounts, formFields.percentRealizedActiveBase],
          [formFields.weightMoreAccounts, formFields.weightActiveBase]
        )
      )
    }
  }, [
    formFields.weightActiveBase,
    formFields.weightMoreAccounts,
    formFields.percentRealizedMoreAccounts,
    formFields.percentRealizedActiveBase
  ])

  // --- Weight Section ---
  // Function to update total weight and manage validation
  const updateTotalWeight = () => {
    const weightValues = [
      formFields.weightProduction,
      formFields.weightSPF,
      formFields.weightSA,
      formFields.weightCharge,
      formFields.weightWebmotors,
      formFields.weightEcosystem
    ]

    const total = calculateTotal(weightValues)

    setValue('weightTotal', total)

    return total !== 100
      ? setError('weightTotal', { message: 'Valor do campo precisa ser igual a 100' })
      : clearErrors('weightTotal')
  }

  useEffect(() => {
    updateTotalWeight()
  }, [
    formFields.weightWebmotors,
    formFields.weightCharge,
    formFields.weightEcosystem,
    formFields.weightProduction,
    formFields.weightSA,
    formFields.weightSPF
  ])

  // --- Target Section ---
  // Function to update target values for each field
  const updateTargetValues = () => {
    setValue(
      'targetProduction',
      calculateTarget(formFields.weightProduction, formFields.targetAccount)
    )

    setValue('targetSPF', calculateTarget(formFields.weightSPF, formFields.targetAccount))

    setValue('targetSA', calculateTarget(formFields.weightSA, formFields.targetAccount))

    setValue('targetCharge', calculateTarget(formFields.weightCharge, formFields.targetAccount))

    setValue(
      'targetWebmotors',
      calculateTarget(formFields.weightWebmotors, formFields.targetAccount)
    )

    setValue(
      'targetEcosystem',
      calculateTarget(formFields.weightEcosystem, formFields.targetAccount)
    )

    setValue(
      'targetActiveBase',
      calculateTarget(formFields.weightActiveBase, formFields.targetAccount)
    )

    setValue(
      'targetMoreAccounts',
      calculateTarget(formFields.weightMoreAccounts, formFields.targetAccount)
    )

    const {
      targetProduction,
      targetSPF,
      targetSA,
      targetCharge,
      targetWebmotors,
      targetEcosystem
    } = getValues()

    const values = [
      targetProduction,
      targetSPF,
      targetSA,
      targetCharge,
      targetWebmotors,
      targetEcosystem
    ]

    return setValue('targetTotal', calculateTotal(values))
  }

  useEffect(() => {
    setValue('targetAccount', selectedTarget?.value ?? 0)
  }, [selectedTarget])

  useEffect(() => {
    if (formFields.targetAccount === 0) {
      setSelectedTarget(defaultTargetAccount)

      setValue('targetAccount', defaultTargetAccount.value)
    }
  }, [formFields.targetAccount])

  useEffect(() => {
    if (revenueFields?.targetAccount === 0) {
      setSelectedTarget(defaultTargetAccount)

      setValue('targetAccount', defaultTargetAccount.value)
    }
  }, [revenueFields?.targetAccount, defaultTargetAccount])

  useEffect(() => {
    if (formFields.weightTotal === 100) updateTargetValues()
  }, [formFields.weightTotal, formFields.targetAccount])

  // --- PercentRealized Section ---
  // Function to update percent realized value for a given field
  const updatePercentRealizedValue = (
    name: keyof RevenueCalculatorFormFields,
    goal: number,
    realized: number
  ) => {
    return setValue(name, calculatePercentRealized(goal, realized, name.includes('Production')))
  }

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedProduction',
      formFields.goalProduction,
      formFields.realizedProduction
    )
  }, [formFields.goalProduction, formFields.realizedProduction])

  useEffect(() => {
    updatePercentRealizedValue('percentRealizedSPF', formFields.goalSPF, formFields.realizedSPF)
  }, [formFields.goalSPF, formFields.realizedSPF])

  useEffect(() => {
    updatePercentRealizedValue('percentRealizedSA', formFields.goalSA, formFields.realizedSA)
  }, [formFields.goalSA, formFields.realizedSA])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedCharge',
      formFields.goalCharge,
      formFields.realizedCharge
    )
  }, [formFields.goalCharge, formFields.realizedCharge])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedWebmotors',
      formFields.goalWebmotors,
      formFields.realizedWebmotors
    )
  }, [formFields.goalWebmotors, formFields.realizedWebmotors])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedActiveBase',
      formFields.goalActiveBase,
      formFields.realizedActiveBase
    )
  }, [formFields.goalActiveBase, formFields.realizedActiveBase])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedMoreAccounts',
      formFields.goalMoreAccounts,
      formFields.realizedMoreAccounts
    )
  }, [formFields.goalMoreAccounts, formFields.realizedMoreAccounts])

  // --- PercentAccelerator Section ---
  // Function to update accelerator realized value for a given field
  const updatePercentAcceleratorValue = (
    name: keyof RevenueCalculatorFormFields,
    realized: number,
    accelerators: number,
    goal?: number
  ) =>
    setValue(
      name,
      calculateAcceleratorRealized(realized, accelerators, name.includes('Production'), goal)
    )

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedProduction',
      formFields.percentRealizedProduction,
      formFields.expensesProduction
    )
  }, [formFields.percentRealizedProduction, formFields.expensesProduction])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedSPF',
      formFields.percentRealizedSPF,
      formFields.expensesSPF
    )
  }, [formFields.percentRealizedSPF, formFields.expensesSPF])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedSA',
      formFields.percentRealizedSA,
      formFields.expensesSA
    )
  }, [formFields.percentRealizedSA, formFields.expensesSA])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedCharge',
      formFields.percentRealizedCharge,
      formFields.expensesCharge,
      formFields.chargeGoal
    )
  }, [formFields.percentRealizedCharge, formFields.expensesCharge, formFields.chargeGoal])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedWebmotors',
      formFields.percentRealizedWebmotors,
      formFields.expensesWebmotors
    )
  }, [formFields.percentRealizedWebmotors, formFields.expensesWebmotors])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedEcosystem',
      formFields.percentRealizedEcosystem,
      formFields.expensesEcosystem
    )
  }, [formFields.percentRealizedEcosystem, formFields.expensesEcosystem])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedActiveBase',
      formFields.percentRealizedActiveBase,
      formFields.expensesActiveBase
    )
  }, [formFields.percentRealizedActiveBase, formFields.expensesActiveBase])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedMoreAccounts',
      formFields.percentRealizedMoreAccounts,
      formFields.expensesMoreAccounts
    )
  }, [formFields.percentRealizedMoreAccounts, formFields.expensesMoreAccounts])

  // --- RVSimulated Section ---
  // Function to update RV simulated value for a given field
  const updateRVSimulatedValue = (
    name: keyof RevenueCalculatorFormFields,
    target: number,
    realized: number
  ) => {
    const shouldCalculate = linesFulfilled >= 2 && realized >= 100

    return setValue(name, shouldCalculate ? calculateRVSimulated(target, realized) : 0)
  }

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedProduction',
      formFields.targetProduction,
      formFields.percentRealizedProduction
    )
  }, [formFields.targetProduction, formFields.percentRealizedProduction, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue('rvSimulatedSPF', formFields.targetSPF, formFields.percentRealizedSPF)
  }, [formFields.targetSPF, formFields.percentRealizedSPF, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue('rvSimulatedSA', formFields.targetSA, formFields.percentRealizedSA)
  }, [formFields.targetSA, formFields.percentRealizedSA, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedCharge',
      formFields.targetCharge,
      formFields.percentRealizedCharge
    )
  }, [formFields.targetCharge, formFields.percentRealizedCharge, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedWebmotors',
      formFields.targetWebmotors,
      formFields.percentRealizedWebmotors
    )
  }, [formFields.targetWebmotors, formFields.percentRealizedWebmotors, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedEcosystem',
      formFields.targetEcosystem,
      formFields.percentRealizedEcosystem
    )
  }, [formFields.targetEcosystem, formFields.percentRealizedEcosystem, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedActiveBase',
      formFields.targetActiveBase,
      formFields.percentRealizedActiveBase
    )
  }, [formFields.targetActiveBase, formFields.percentRealizedActiveBase, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedMoreAccounts',
      formFields.targetMoreAccounts,
      formFields.percentRealizedMoreAccounts
    )
  }, [formFields.targetMoreAccounts, formFields.percentRealizedMoreAccounts, linesFulfilled])

  // --- RVSimulatedTotal Section ---
  // Function to update RV simulated total value
  const updateRVSimulatedTotalValue = () => {
    const {
      rvSimulatedProduction,
      rvSimulatedSPF,
      rvSimulatedSA,
      rvSimulatedCharge,
      rvSimulatedWebmotors,
      rvSimulatedEcosystem
    } = getValues()

    const values = [
      rvSimulatedProduction,
      rvSimulatedSPF,
      rvSimulatedSA,
      rvSimulatedCharge,
      rvSimulatedWebmotors,
      rvSimulatedEcosystem
    ]

    return setValue('rvSimulatedTotal', calculateTotal(values))
  }

  useEffect(() => {
    updateRVSimulatedTotalValue()
  }, [
    formFields.rvSimulatedProduction,
    formFields.rvSimulatedSPF,
    formFields.rvSimulatedSA,
    formFields.rvSimulatedCharge,
    formFields.rvSimulatedWebmotors,
    formFields.rvSimulatedEcosystem
  ])

  // --- RVAccelerator Section ---
  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorProduction',
      formFields.targetProduction,
      formFields.acceleratorRealizedProduction
    )
  }, [formFields.targetProduction, formFields.acceleratorRealizedProduction, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorSPF',
      formFields.targetSPF,
      formFields.acceleratorRealizedSPF
    )
  }, [formFields.targetSPF, formFields.acceleratorRealizedSPF, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue('rvAcceleratorSA', formFields.targetSA, formFields.acceleratorRealizedSA)
  }, [formFields.targetSA, formFields.acceleratorRealizedSA, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorWebmotors',
      formFields.targetWebmotors,
      formFields.acceleratorRealizedWebmotors
    )
  }, [formFields.targetWebmotors, formFields.acceleratorRealizedWebmotors, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorCharge',
      formFields.targetCharge,
      formFields.acceleratorRealizedCharge
    )
  }, [formFields.targetCharge, formFields.acceleratorRealizedCharge, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorEcosystem',
      formFields.targetEcosystem,
      formFields.acceleratorRealizedEcosystem
    )
  }, [formFields.targetEcosystem, formFields.acceleratorRealizedEcosystem, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorActiveBase',
      formFields.targetActiveBase,
      formFields.acceleratorRealizedActiveBase
    )
  }, [formFields.targetActiveBase, formFields.acceleratorRealizedActiveBase, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorMoreAccounts',
      formFields.targetMoreAccounts,
      formFields.acceleratorRealizedMoreAccounts
    )
  }, [formFields.targetMoreAccounts, formFields.acceleratorRealizedMoreAccounts, linesFulfilled])

  // --- RVAcceleratorTotal Section ---
  // Function to update RV accelerator total value
  const updateRVAcceleratorTotalValue = () => {
    const {
      rvAcceleratorProduction,
      rvAcceleratorSPF,
      rvAcceleratorSA,
      rvAcceleratorCharge,
      rvAcceleratorWebmotors,
      rvAcceleratorEcosystem
    } = getValues()

    const values = [
      rvAcceleratorProduction,
      rvAcceleratorSPF,
      rvAcceleratorSA,
      rvAcceleratorCharge,
      rvAcceleratorWebmotors,
      rvAcceleratorEcosystem
    ]

    return setValue('rvAcceleratorTotal', calculateTotal(values))
  }

  useEffect(() => {
    updateRVAcceleratorTotalValue()
  }, [
    formFields.rvAcceleratorProduction,
    formFields.rvAcceleratorSPF,
    formFields.rvAcceleratorSA,
    formFields.rvAcceleratorCharge,
    formFields.rvAcceleratorWebmotors,
    formFields.rvAcceleratorEcosystem
  ])

  // --- isFulfilled Section ---
  // Callback to check if a specific field is fulfilled (>= 100)
  const isFulfilled = useCallback(
    (fieldName: keyof RevenueCalculatorFormFields) => {
      return formFields[fieldName] >= 100
    },
    [formFields]
  )

  useEffect(() => {
    const fulfilledLinesCount = acceleratorRealizedFields.filter(
      (field) =>
        isFulfilled(field) &&
        !['acceleratorRealizedActiveBase', 'acceleratorRealizedMoreAccounts'].includes(field)
    ).length

    setLinesFulfilled(fulfilledLinesCount)
  }, [...acceleratorRealizedFields.map((field) => formFields[field]), isFulfilled])

  useEffect(() => {
    setValue('expensesSPF', formFields.expensesProduction)

    setValue('expensesSA', formFields.expensesProduction)

    setValue('expensesCharge', formFields.expensesProduction)

    setValue('expensesWebmotors', formFields.expensesProduction)

    setValue('expensesEcosystem', formFields.expensesProduction)
  }, [formFields.expensesProduction])

  // --- containsInsurance & multiplier & multipliedRV Section ---
  useEffect(() => {
    const containsInsurance = calculateWeightedAverage(
      formFields.weightSPF,
      formFields.acceleratorRealizedSPF,
      formFields.weightSA,
      formFields.acceleratorRealizedSA
    )

    setMultiplierContainsInsurance(containsInsurance)
  }, [
    formFields.weightSPF,
    formFields.acceleratorRealizedSPF,
    formFields.weightSA,
    formFields.acceleratorRealizedSA
  ])

  useEffect(() => {
    setValue('multiplier', calculateMultiplier(linesFulfilled, multiplierContainsInsurance))
  }, [formFields.rvAcceleratorTotal, linesFulfilled, multiplierContainsInsurance])

  useEffect(() => {
    const newMultipliedRV = calculateRVMultiplied(
      formFields.rvAcceleratorTotal,
      formFields.multiplier
    )

    setValue('multipliedRV', newMultipliedRV)
  }, [formFields.multiplier, formFields.rvAcceleratorTotal])

  // --- RV Total Section ---
  useEffect(() => {
    const newTotalRV = calculateTotalWithPercentage(formFields.multipliedRV, formFields.performance)

    setValue('rvTotal', newTotalRV)
  }, [formFields.performance, formFields.multipliedRV])

  const filteredTargets = useMemo(
    () => getFilteredTargets(targetsList, userIsGR),
    [targetsList, userIsGR]
  )

  const hasErrors = Object.keys(errors).length > 0

  return {
    isMobile,
    userIsGR,
    control,
    errors,
    hasErrors,
    isDirty,
    targets: filteredTargets,
    selectedTarget,
    setSelectedTarget,
    tooltipFieldName,
    handleOpenTooltip,
    handleCloseTooltip,
    handleConfirmSimulation,
    handleResetSimulation,
    indicatorsLines,
    weightFields,
    targetFields,
    goalFields,
    realizedFields,
    percentRealizedFields,
    rvSimulatedFields,
    acceleratorRealizedFields,
    rvAcceleratorFields,
    performanceValues,
    expensesFields,
    expensesValues,
    chargeGoalValues,
    multiplierContainsInsurance,
    isLoading: isLoadingRevenueData,
    isSending: isSendingSimulation || isResettingRevenueSimulation,
    successSave,
    savedIsOpen,
    savedOnClose
  }
}
