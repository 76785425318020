/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  CALCULATOR_TYPE_ENUM,
  useGetCalculatorData,
  useResetCalculatorSimulationMutation,
  useSendCalculatorSimulationMutation
} from 'integration/resources/rvCalculators'
import { useBreakpointValue, useDisclose } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  formsDefaultValues,
  goalFields,
  goalOneFields,
  goalTwoFields,
  percentAchievedFields,
  percentAchievedOneFields,
  percentAchievedTwoFields,
  realizedFields,
  realizedOneFields,
  realizedTwoFields,
  weightFields,
  pointsFields
} from './constants'
import {
  ResultsCalculatorFormFields,
  SelectedTarget,
  Shape,
  UseResultsCalculator
} from './ResultsCalculator.types'
import {
  calculateRVSimulated,
  calculatePercentRealized,
  calculateTotal,
  calculateResultsMultiplier,
  calculateRVMultiplied,
  calculateTotalWithPercentage
} from '../../Utils/calculationsFunctions'
import {
  indicatorsLines,
  resultsTargets,
  performanceValues,
  mobSPFValues
} from '../../Utils/constants'

// Dynamically create Yup validation schema based on field names
const createYupSchema = (fieldNames: (keyof ResultsCalculatorFormFields)[]) => {
  const shape: Shape = {} as Shape

  fieldNames.forEach((name: string) => {
    shape[name] = yup.number().required()
  })

  return yup.object().shape(shape)
}

// Validation schema creation for RV calculator form fields
const ResultsCalculatorFormSchema = createYupSchema([
  ...weightFields,
  ...goalOneFields,
  ...realizedOneFields,
  ...percentAchievedOneFields,
  ...goalTwoFields,
  ...realizedTwoFields,
  ...percentAchievedTwoFields,
  ...goalFields,
  ...realizedFields,
  ...percentAchievedFields,
  ...pointsFields,
  'targetAccount',
  'weightTotal',
  'rvValue',
  'multiplier',
  'multipliedRV',
  'performance',
  'rvTotal',
  'pointsTotal'
])

export const useResultsCalculator: UseResultsCalculator = ({
  userIsGR,
  dontShowConfirm,
  handleDontShowConfirm
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { isOpen: showTooltip, onOpen: openTooltip, onClose: closeTooltip } = useDisclose(false)

  const year_month = 202412

  const {
    control,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<ResultsCalculatorFormFields>({
    resolver: yupResolver(ResultsCalculatorFormSchema),
    mode: 'onChange',
    defaultValues: formsDefaultValues
  })

  const formFields = watch()

  const { data: calculatorData, isLoading: isLoadingResultsData } = useGetCalculatorData(
    CALCULATOR_TYPE_ENUM.SIM_RESULTS,
    year_month,
    true
  )

  useEffect(() => {
    if (calculatorData?.data?.data) {
      reset({ ...calculatorData.data.data })
    }
  }, [calculatorData, reset])

  const { mutateAsync, isLoading: isSendingSimulation } = useSendCalculatorSimulationMutation(
    CALCULATOR_TYPE_ENUM.SIM_RESULTS,
    year_month
  )

  const { mutate: resetResultsSimulation, isLoading: isResettingResultsSimulation } =
    useResetCalculatorSimulationMutation({
      calculatorType: CALCULATOR_TYPE_ENUM.SIM_RESULTS,
      year_month
    })

  const [selectedTarget, setSelectedTarget] = useState<SelectedTarget>(resultsTargets[0])

  const [successSave, setSuccessSave] = useState(false)

  const { isOpen: savedIsOpen, onClose: savedOnClose, onOpen: savedOnOpen } = useDisclose(false)

  useEffect(() => {
    if (isDirty && dontShowConfirm) {
      handleDontShowConfirm?.()
    }

    if (!isDirty && !dontShowConfirm) {
      handleDontShowConfirm?.()
    }
  }, [isDirty])

  const handleConfirmSimulation = handleSubmit(
    useCallback<SubmitHandler<ResultsCalculatorFormFields>>(
      async (formData) =>
        mutateAsync(
          {
            calculator_json: formData,
            year_month: 202412,
            calculator_type_enum: CALCULATOR_TYPE_ENUM.SIM_RESULTS
          },
          {
            onSuccess: () => {
              setSuccessSave(true)
            }
          }
        ).then(() => {
          reset(formFields)

          savedOnOpen()
        }),

      [reset]
    )
  )

  const handleResetSimulation = () => {
    resetResultsSimulation(
      { calculatorType: CALCULATOR_TYPE_ENUM.SIM_RESULTS, year_month },
      {
        onSuccess: () => {
          reset(formsDefaultValues)
        }
      }
    )
  }

  // --- Weight Section ---
  // Function to update total weight and manage validation
  const updateTotalWeight = () => {
    const weightValues = [
      formFields.weightProduction,
      formFields.weightPaying,
      formFields.weightRecurrent,
      formFields.weightCharge,
      formFields.weightMobSPF
    ]

    const total = calculateTotal(weightValues)

    setValue('weightTotal', total)

    return total !== 100
      ? setError('weightTotal', { message: 'Valor do campo precisa ser igual a 100' })
      : clearErrors('weightTotal')
  }

  useEffect(() => {
    updateTotalWeight()
  }, [
    formFields.weightMobSPF,
    formFields.weightCharge,
    formFields.weightProduction,
    formFields.weightRecurrent,
    formFields.weightPaying
  ])

  // --- Target Section ---
  useEffect(() => {
    setValue('targetAccount', selectedTarget?.value ?? 0)
  }, [selectedTarget])

  useEffect(() => {
    if (formFields.targetAccount === 0) {
      setSelectedTarget(resultsTargets[0])

      setValue('targetAccount', resultsTargets[0].value)
    }
  }, [formFields.targetAccount])

  // --- PercentAchieved Section ---
  // Function to update percent realized value for a given field
  const updatePercentAchievedValue = (
    name: keyof ResultsCalculatorFormFields,
    goal: number,
    realized: number
  ) => {
    return setValue(name, calculatePercentRealized(goal, realized, name.includes('Production')))
  }

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneProduction',
      formFields.goalOneProduction,
      formFields.realizedOneProduction
    )
  }, [formFields.goalOneProduction, formFields.realizedOneProduction])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOnePaying',
      formFields.goalOnePaying,
      formFields.realizedOnePaying
    )
  }, [formFields.goalOnePaying, formFields.realizedOnePaying])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneRecurrent',
      formFields.goalOneRecurrent,
      formFields.realizedOneRecurrent
    )
  }, [formFields.goalOneRecurrent, formFields.realizedOneRecurrent])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneCharge',
      formFields.goalOneCharge,
      formFields.realizedOneCharge
    )
  }, [formFields.goalOneCharge, formFields.realizedOneCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneMobSPF',
      formFields.goalOneMobSPF,
      formFields.realizedOneMobSPF
    )
  }, [formFields.goalOneMobSPF, formFields.realizedOneMobSPF])

  // --- update percentAchieved Two Section ---
  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoProduction',
      formFields.goalTwoProduction,
      formFields.realizedTwoProduction
    )
  }, [formFields.goalTwoProduction, formFields.realizedTwoProduction])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoPaying',
      formFields.goalTwoPaying,
      formFields.realizedTwoPaying
    )
  }, [formFields.goalTwoPaying, formFields.realizedTwoPaying])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoRecurrent',
      formFields.goalTwoRecurrent,
      formFields.realizedTwoRecurrent
    )
  }, [formFields.goalTwoRecurrent, formFields.realizedTwoRecurrent])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoCharge',
      formFields.goalTwoCharge,
      formFields.realizedTwoCharge
    )
  }, [formFields.goalTwoCharge, formFields.realizedTwoCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoMobSPF',
      formFields.goalTwoMobSPF,
      formFields.realizedTwoMobSPF
    )
  }, [formFields.goalTwoMobSPF, formFields.realizedTwoMobSPF])

  // --- update goal & realized Section ---
  const updateGoalOrRealized = (
    fieldName: keyof ResultsCalculatorFormFields,
    valueOne: number,
    valueTwo: number
  ) => {
    setValue(fieldName, calculateTotal([valueOne, valueTwo]))
  }

  useEffect(() => {
    updateGoalOrRealized(
      'goalProduction',
      formFields.goalOneProduction,
      formFields.goalTwoProduction
    )
  }, [formFields.goalOneProduction, formFields.goalTwoProduction])

  useEffect(() => {
    updateGoalOrRealized('goalPaying', formFields.goalOnePaying, formFields.goalTwoPaying)
  }, [formFields.goalOnePaying, formFields.goalTwoPaying])

  useEffect(() => {
    updateGoalOrRealized('goalRecurrent', formFields.goalOneRecurrent, formFields.goalTwoRecurrent)
  }, [formFields.goalOneRecurrent, formFields.goalTwoRecurrent])

  useEffect(() => {
    updateGoalOrRealized('goalCharge', formFields.goalOneCharge, formFields.goalTwoCharge)
  }, [formFields.goalOneCharge, formFields.goalTwoCharge])

  useEffect(() => {
    updateGoalOrRealized('goalMobSPF', formFields.goalOneMobSPF, formFields.goalTwoMobSPF)
  }, [formFields.goalOneMobSPF, formFields.goalTwoMobSPF])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedProduction',
      formFields.realizedOneProduction,
      formFields.realizedTwoProduction
    )
  }, [formFields.realizedOneProduction, formFields.realizedTwoProduction])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedPaying',
      formFields.realizedOnePaying,
      formFields.realizedTwoPaying
    )
  }, [formFields.realizedOnePaying, formFields.realizedTwoPaying])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedRecurrent',
      formFields.realizedOneRecurrent,
      formFields.realizedTwoRecurrent
    )
  }, [formFields.realizedOneRecurrent, formFields.realizedTwoRecurrent])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedCharge',
      formFields.realizedOneCharge,
      formFields.realizedTwoCharge
    )
  }, [formFields.realizedOneCharge, formFields.realizedTwoCharge])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedMobSPF',
      formFields.realizedOneMobSPF,
      formFields.realizedTwoMobSPF
    )
  }, [formFields.realizedOneMobSPF, formFields.realizedTwoMobSPF])

  // --- updatePercentAchievedValue Section ---
  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedProduction',
      formFields.goalProduction,
      formFields.realizedProduction
    )
  }, [formFields.goalProduction, formFields.realizedProduction])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedPaying',
      formFields.goalPaying,
      formFields.realizedPaying
    )
  }, [formFields.goalPaying, formFields.realizedPaying])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedRecurrent',
      formFields.goalRecurrent,
      formFields.realizedRecurrent
    )
  }, [formFields.goalRecurrent, formFields.realizedRecurrent])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedCharge',
      formFields.goalCharge,
      formFields.realizedCharge
    )
  }, [formFields.goalCharge, formFields.realizedCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedMobSPF',
      formFields.goalMobSPF,
      formFields.realizedMobSPF
    )
  }, [formFields.goalMobSPF, formFields.realizedMobSPF])

  // --- Points Section ---
  // Function to update Points value for a given field
  const updatePoints = (
    pointsField: keyof ResultsCalculatorFormFields,
    weight: number,
    percentAchieved: number,
    chargeGoal: number = 1
  ) => {
    const percentLimit = pointsField.includes('Production') ? 200 : 150

    const appliedPercent =
      percentAchieved > percentLimit || chargeGoal === 2 ? percentLimit : percentAchieved

    setValue(pointsField, calculateRVSimulated(appliedPercent, weight))
  }

  useEffect(() => {
    updatePoints(
      'pointsProduction',
      formFields.weightProduction,
      formFields.percentAchievedProduction
    )
  }, [formFields.weightProduction, formFields.percentAchievedProduction])

  useEffect(() => {
    updatePoints('pointsPaying', formFields.weightPaying, formFields.percentAchievedPaying)
  }, [formFields.weightPaying, formFields.percentAchievedPaying])

  useEffect(() => {
    updatePoints('pointsRecurrent', formFields.weightRecurrent, formFields.percentAchievedRecurrent)
  }, [formFields.weightRecurrent, formFields.percentAchievedRecurrent])

  useEffect(() => {
    updatePoints('pointsCharge', formFields.weightCharge, formFields.percentAchievedCharge)
  }, [formFields.weightCharge, formFields.percentAchievedCharge])

  useEffect(() => {
    updatePoints('pointsMobSPF', formFields.weightMobSPF, formFields.percentAchievedMobSPF)
  }, [formFields.weightMobSPF, formFields.percentAchievedMobSPF])

  const updateTotalPoints = () => {
    const pointsValues = [
      formFields.pointsProduction,
      formFields.pointsPaying,
      formFields.pointsRecurrent,
      formFields.pointsCharge,
      formFields.pointsMobSPF
    ]

    const total = calculateTotal(pointsValues)

    setValue('pointsTotal', total > 150 ? 150 : total)
  }

  useEffect(() => {
    updateTotalPoints()
  }, [
    formFields.pointsProduction,
    formFields.pointsPaying,
    formFields.pointsRecurrent,
    formFields.pointsCharge,
    formFields.pointsMobSPF
  ])

  // --- RV Value Section ---
  // Function to update RV Value value for a given field

  const updateRvValue = (totalPoints: number, target: number) => {
    let newValue = 0

    if (totalPoints >= 100) newValue = calculateRVSimulated(totalPoints, target)

    setValue('rvValue', newValue)
  }

  useEffect(() => {
    updateRvValue(formFields.pointsTotal, formFields.targetAccount)
  }, [formFields.pointsTotal, formFields.targetAccount])

  // --- quadrant Section ---
  const [quadrant, setQuadrant] = useState(0)

  useEffect(() => {
    if (formFields.pointsTotal >= 140.01) return setQuadrant(5)

    if (formFields.pointsTotal >= 130.01) return setQuadrant(4)

    if (formFields.pointsTotal >= 120.01) return setQuadrant(3)

    if (formFields.pointsTotal >= 110.01) return setQuadrant(2)

    if (formFields.pointsTotal >= 105.01) return setQuadrant(1)

    return setQuadrant(0)
  }, [formFields.pointsTotal])

  // --- containsInsurance & multiplier & multipliedRV Section ---

  const [multiplierContainsInsurance, setMultiplierContainsInsurance] = useState(false)

  useEffect(() => {
    const containsInsurance = formFields.percentAchievedMobSPF >= 100

    setMultiplierContainsInsurance(containsInsurance)
  }, [formFields.percentAchievedMobSPF])

  // --- Multiplier Section ---
  // Function to update Multiplier value for a given field

  useEffect(() => {
    setValue('multiplier', calculateResultsMultiplier(quadrant, multiplierContainsInsurance))
  }, [formFields.rvValue, quadrant, multiplierContainsInsurance])

  useEffect(() => {
    const newMultipliedRV = calculateRVMultiplied(formFields.rvValue, formFields.multiplier)

    setValue('multipliedRV', newMultipliedRV)
  }, [formFields.multiplier, formFields.rvValue])

  // --- RV Total Section ---
  useEffect(() => {
    const newTotalRV = calculateTotalWithPercentage(formFields.multipliedRV, formFields.performance)

    setValue('rvTotal', newTotalRV)
  }, [formFields.performance, formFields.multipliedRV])

  const isMOB = formFields.mobSPF === 'MOB'

  return {
    isMobile,
    userIsGR,

    control,
    errors,
    isDirty,

    targets: resultsTargets,
    selectedTarget,
    setSelectedTarget,

    openTooltip,
    closeTooltip,
    showTooltip,

    handleConfirmSimulation,
    handleResetSimulation,

    indicatorsLines,
    goalFields,
    goalOneFields,
    goalTwoFields,
    percentAchievedFields,
    percentAchievedOneFields,
    percentAchievedTwoFields,
    realizedFields,
    realizedOneFields,
    realizedTwoFields,
    weightFields,
    pointsFields,

    performanceValues,
    mobSPFValues: mobSPFValues.slice(0, 2),
    multiplierContainsInsurance: multiplierContainsInsurance && formFields.pointsTotal >= 105.1,

    isLoading: isLoadingResultsData,
    isSending: isSendingSimulation || isResettingResultsSimulation,
    successSave,
    savedIsOpen,
    savedOnClose,
    isMOB
  }
}
