import { ResultsCalculatorFormFields } from 'organisms/RVCalculators/Calculators/Results/ResultsCalculator.types'
import { RevenueCalculatorFormFields } from 'organisms/RVCalculators/Calculators/Revenue/RevenueCalculator.types'
import { RecoveryCalculatorFormFields } from 'organisms/RVCalculatorsOld/Calculators/Recovery/RecoveryCalculator.types'
import { ResultsCalculatorFormFields as ResultsCalculatorOldFormFields } from 'organisms/RVCalculatorsOld/Calculators/Results/ResultsCalculator.types'
import { RevenueCalculatorFormFields as RevenueCalculatorOldFormFields } from 'organisms/RVCalculatorsOld/Calculators/Revenue/RevenueCalculator.types'
import { ResultsCalculatorFormFields as ResultsCalculatorSimFormFields } from 'organisms/RVCalculatorsSim/Calculators/Results/ResultsCalculator.types'
import { RevenueCalculatorFormFields as RevenueCalculatorSimFormFields } from 'organisms/RVCalculatorsSim/Calculators/Revenue/RevenueCalculator.types'

type TRevenueCalculatorBlocks = {
  production: number
  spf: number
  sa: number
  charge: number
  webmotors: number
  more_accounts?: number
  active_base?: number
}

type TRevenueAcceleratorBlocks = {
  expenses: number
  chargeGoal: number
  performance: number
}

export type TRevenueCalculator = {
  total: number
  target: number
  weight: TRevenueCalculatorBlocks
  goal: TRevenueCalculatorBlocks
  realized: TRevenueCalculatorBlocks
  accelerators: TRevenueAcceleratorBlocks
}

export enum CALCULATOR_TYPE_ENUM {
  BUSINESS_BLOCK = 1,
  REVENUE = 2,
  RECOVERY = 3,
  RESULTS = 4,
  SIM_REVENUE = 5,
  SIM_RESULTS = 6
}

export type TCalculatorData = {
  year_month?: number
  target?: number
  total?: number
  calculator_type_enum: CALCULATOR_TYPE_ENUM
  calculator_json:
    | RevenueCalculatorFormFields
    | RevenueCalculatorOldFormFields
    | ResultsCalculatorFormFields
    | ResultsCalculatorOldFormFields
    | RecoveryCalculatorFormFields
    | ResultsCalculatorSimFormFields
    | RevenueCalculatorSimFormFields
}

export type TCalculatorType = (typeof CALCULATOR_TYPE_ENUM)[keyof typeof CALCULATOR_TYPE_ENUM]

export type TRevenueCalculatorSerialized = Pick<
  RevenueCalculatorFormFields,
  | 'targetAccount'
  | 'weightProduction'
  | 'weightSPF'
  | 'weightSA'
  | 'weightCharge'
  | 'weightWebmotors'
  | 'goalProduction'
  | 'goalSPF'
  | 'goalSA'
  | 'goalCharge'
  | 'goalWebmotors'
  | 'goalMoreAccounts'
  | 'realizedProduction'
  | 'realizedSPF'
  | 'realizedSA'
  | 'realizedCharge'
  | 'realizedWebmotors'
  | 'realizedMoreAccounts'
  | 'expensesProduction'
  | 'chargeGoal'
  | 'performance'
  | 'rvTotal'
>

export type TCalculatorDataSerialized =
  | RevenueCalculatorFormFields
  | ResultsCalculatorFormFields
  | RecoveryCalculatorFormFields
  | ResultsCalculatorOldFormFields
  | RevenueCalculatorOldFormFields
  | ResultsCalculatorSimFormFields
  | RevenueCalculatorSimFormFields

export type CalculatorParams = {
  calculatorType: CALCULATOR_TYPE_ENUM
  year_month: number
}
