import { FunctionComponent } from 'react'

import Svg, { SvgProps, Path, G, Rect, Defs, ClipPath } from 'react-native-svg'

export const CockpitIcon: FunctionComponent<SvgProps> = ({ ...props }) => (
  <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <G clipPath="url(#clip0_19858_1444)">
      <Path
        d="M15.9076 11.9173C15.4304 13.0457 14.6841 14.04 13.734 14.8133C12.7838 15.5866 11.6586 16.1154 10.4569 16.3534C9.25514 16.5914 8.01338 16.5314 6.84017 16.1787C5.66696 15.8259 4.59803 15.1911 3.72682 14.3298C2.85562 13.4685 2.20867 12.4069 1.84253 11.2378C1.4764 10.0687 1.40223 8.82768 1.62651 7.62329C1.85078 6.4189 2.36668 5.28779 3.12909 4.32884C3.89151 3.36989 4.87722 2.61231 6.00006 2.12232M15.7501 8.99982C16.1641 8.99982 16.5038 8.66307 16.4626 8.25132C16.2896 6.52944 15.5266 4.92034 14.3028 3.69681C13.079 2.47327 11.4697 1.71059 9.74781 1.53807C9.33531 1.49682 8.99931 1.83657 8.99931 2.25057V8.25057C8.99931 8.44948 9.07833 8.64025 9.21898 8.7809C9.35963 8.92155 9.5504 9.00057 9.74931 9.00057L15.7501 8.99982Z"
        stroke="#A8A8A8"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_19858_1444">
        <Rect width="18" height="18" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
)
