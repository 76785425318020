import { useEffect } from 'react'

import { CommonActions } from '@react-navigation/native'
import { LoadingTurbo } from 'atoms'

import { AuthSSOScreenComponent } from './AuthSSO.types'
import { useAuthSSOScreen } from './useAuthSSOScreen'

export const AuthSSOScreen: AuthSSOScreenComponent = ({ navigation }) => {
  const { handleSSOLogin } = useAuthSSOScreen()

  useEffect(() => {
    const authenticateUser = async () => {
      const paramsURL = new URLSearchParams(window.location.search)

      const token = paramsURL.get('token')

      if (token) {
        try {
          await handleSSOLogin(token)

          setTimeout(() => {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: 'Tab', params: { screen: 'Home', refetch: true } }]
              })
            )
          }, 100)
        } catch (error) {
          console.error('Erro no login SSO:', error)
        }
      } else {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'Login' }]
          })
        )
      }
    }

    authenticateUser()
  }, [handleSSOLogin, navigation])

  return <LoadingTurbo />
}
