import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'src/integration/types'

import {
  OpportunitiesSummary,
  OpportunityDetailsItem,
  OpportunityDetailsItemSerialized,
  XrayItemSerialized
} from './types'

export const getOpportunityDetailsItem: (
  response: AxiosResponse<ResponseSuccess<OpportunityDetailsItem>>
) => AxiosResponse<ResponseSuccess<OpportunityDetailsItemSerialized>> = (response) => {
  const productionItem = response.data.data.production

  const spfItem = response.data.data.spf

  const insuranceItem = response.data.data.insurance_auto

  const xRayItem = response.data.data.store_xray

  const newData: XrayItemSerialized[] = [
    {
      value: xRayItem?.store_month_potential ?? '',
      title: 'Potencial médio (R$)',
      updated_at: xRayItem?.date_ref_pdv ?? ''
    },
    {
      value: xRayItem?.store_current_production ?? '',
      title: 'Produção atual (R$)',
      updated_at: xRayItem?.date_ref_production ?? ''
    },
    {
      value: xRayItem?.store_average_ticket ?? '',
      title: 'Ticket Médio (R$)',
      updated_at: xRayItem?.date_ref_pdv ?? ''
    },
    {
      value: xRayItem?.store_share_pdv ?? '',
      title: 'Share PDV',
      description: { title: 'MoM (p.p.)', value: xRayItem?.store_share_pdv_ad },
      updated_at: xRayItem?.date_ref_pdv ?? ''
    },
    {
      value: xRayItem?.store_conversion ?? '',
      title: 'Conversão',
      description: { title: 'MoM (p.p.)', value: xRayItem?.store_conversion_ad },
      updated_at: xRayItem?.date_ref_conversao ?? ''
    },
    {
      value: xRayItem?.store_visualization ?? '',
      title: 'Visualização',
      description: { title: 'MoM (p.p.)', value: xRayItem?.store_visualization_ad },
      updated_at: xRayItem?.date_ref_pdv ?? ''
    },
    {
      value: xRayItem?.store_insurance_auto_ip ?? '',
      title: 'IP Seguro Auto',
      description: { title: 'MoM (p.p.)', value: xRayItem?.store_insurance_auto_ip_ad },
      updated_at: xRayItem?.date_ref_sa ?? ''
    },
    {
      value: xRayItem?.store_spf_ip ?? '',
      title: 'IP SPF',
      description: { title: 'MoM (p.p.)', value: xRayItem?.store_spf_ip_ad },
      updated_at: xRayItem?.date_ref_production ?? ''
    },
    {
      value: response.data.data.store.subsegment_name ?? '',
      title: 'Subsegmento',
      updated_at: xRayItem?.date_ref_intermediario ?? ''
    },
    {
      value: xRayItem?.store_fpd_10 ?? '',
      title: 'FPD10',
      description: { title: 'MoM (p.p.)', value: xRayItem?.store_fpd_10_ad },
      updated_at: xRayItem?.date_ref_fpd_10 ?? ''
    },
    {
      value: xRayItem?.store_over_30m3 ?? '',
      title: 'Over30M3',
      description: { title: 'MoM (p.p.)', value: xRayItem?.store_over_30m3_ad },
      updated_at: xRayItem?.date_ref_over_m3 ?? ''
    },
    {
      value: xRayItem?.last_contract_date ?? '',
      title: 'Contrato',
      description: { title: 'Data do último contrato' },
      updated_at: xRayItem?.date_ref_production ?? ''
    }
  ]

  return {
    ...response,
    data: {
      ...response.data,
      data: {
        ...response.data.data,
        production_serialized: {
          quantity: productionItem?.quantity ?? 0,
          average: productionItem?.average_quantity ?? 0,
          goal: productionItem?.target_quantity ?? 0,
          value: Number(productionItem?.average_value) ?? 0,
          updated_at: productionItem?.date_ref ?? ''
        },
        spf_serialized: {
          quantity: spfItem?.quantity ?? 0,
          average: spfItem?.average_quantity ?? 0,
          goal: spfItem?.target_quantity ?? 0,
          value: Number(spfItem?.average_value) ?? 0,
          updated_at: spfItem?.date_ref ?? ''
        },
        insurance_auto_serialized: {
          quantity: insuranceItem?.quantity ?? 0,
          average: insuranceItem?.average_quantity ?? 0,
          goal: insuranceItem?.target_quantity ?? 0,
          value: Number(insuranceItem?.average_value) ?? 0,
          updated_at: insuranceItem?.date_ref ?? ''
        },
        store_xray_serialized: newData
      }
    }
  }
}

const formatValueWithSlash = (value: string): string => {
  if (value.includes('/')) {
    const [numberOne, numberTwo] = value.split('/')

    return `${Number(numberOne).toLocaleString('pt-BR')}/${Number(numberTwo).toLocaleString(
      'pt-BR'
    )}`
  }

  return `${Number(value).toLocaleString('pt-BR')}`
}

export const getOpportunitySummary: (
  response: AxiosResponse<ResponseSuccess<OpportunitiesSummary>>
) => AxiosResponse<ResponseSuccess<OpportunitiesSummary>> = (response) => {
  const odcDescriptionsValue = response.data.data.odc.descriptions.map((item) => {
    return {
      title: item.title,
      value: formatValueWithSlash(item.value)
    }
  })

  const activeBaseDescriptionsValue = response.data.data.activeBase.descriptions.map((item) => {
    return {
      title: item.title,
      value: formatValueWithSlash(item.value)
    }
  })

  const insuranceAutoDescriptionsValue = response.data.data.insuranceAuto.descriptions.map(
    (item) => {
      return {
        title: item.title,
        value: formatValueWithSlash(item.value)
      }
    }
  )

  const chargeDescriptionsValue = response.data.data.charge.descriptions.map((item) => {
    return {
      title: item.title,
      value: formatValueWithSlash(item.value)
    }
  })

  return {
    ...response,
    data: {
      ...response.data,
      data: {
        odc: {
          ...response.data.data.odc,
          descriptions: odcDescriptionsValue
        },
        activeBase: {
          ...response.data.data.activeBase,
          descriptions: activeBaseDescriptionsValue
        },
        insuranceAuto: {
          ...response.data.data.insuranceAuto,
          descriptions: insuranceAutoDescriptionsValue
        },
        charge: {
          ...response.data.data.charge,
          descriptions: chargeDescriptionsValue
        }
      }
    }
  }
}
