import { FunctionComponent } from 'react'

import { ContentSerialized } from 'integration/resources/knowledgeBase'
import { RootStackScreenComponentProps } from 'src/navigation'

export type ContentScreenComponent = FunctionComponent<ContentScreenProps>

export type ContentScreenProps = RootStackScreenComponentProps<'Content'>

export type ResponsiveContentScreenComponent = FunctionComponent<ReturnType<ContentScreen>>

export type ContentScreen = ({
  navigation,
  route
}: Pick<ContentScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  contentData: ContentSerialized | undefined
  handleGoToHomeContent(): void
  handleTogglefavorite(type: FAVORITE_TYPE_ENUM): void
  isLoadingFavorite: boolean
  isLoading: boolean
  favorite: boolean
}

export enum FAVORITE_TYPE_ENUM {
  FAVORITE = 'favorite',
  UNFAVORITE = 'unfavorite'
}
