import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  TGoal,
  TMetric,
  TUpdatesItem,
  useGetGoalsDetailsQuery,
  useSaveGoalsAdjustmentsMutation
} from 'integration/resources/goals'
import { useDisclose, useToast } from 'native-base'
import { Toast } from 'organisms'
import { formatCurrencyField } from 'src/utils'

import { TOperation, UseGoalsAdjustmentScreen } from './GoalsAdjustmentScreen.types'

const titleMapping: Record<string, string> = {
  spf: 'Seguro Prestamista',
  auto: 'Seguro Auto',
  wm: 'Webmotors',
  production: 'Produção',
  charge: 'Cobrança',
  active_base: 'Base Ativa',
  accounts: '+Contas'
}

export const useGoalsAdjustmentScreen: UseGoalsAdjustmentScreen = ({ navigation, route }) => {
  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const currentGoal = route?.params?.currentList

  const staffId = route?.params?.staffId

  const staffName = route?.params?.infos.name

  const staffOccupation = route?.params?.infos.occupation

  const toast = useToast()

  const [timeouts, setTimeouts] = useState<number[]>([])

  const {
    isOpen: goalsListIsOpen,
    onOpen: goalsListOnOpen,
    onClose: goalsListOnClose
  } = useDisclose()

  useEffect(() => {
    return () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))
    }
  }, [timeouts])

  const [goalsAdjustments, setGoalsAdjustments] = useState<TUpdatesItem | undefined>()

  const { data: savedAdjustmentsData, isLoading: savedAdjustmentsDataIsLoading } =
    useGetGoalsDetailsQuery({
      enabled: !!staffId,
      staffId: staffId ?? ''
    })

  const savedAdjustments = useMemo(
    () => savedAdjustmentsData?.data.data,
    [savedAdjustmentsData?.data.data]
  )

  const formattedGoals = useMemo(
    () =>
      Object.entries(savedAdjustments ?? {})
        .filter(([key]) => key !== 'id')
        .map(([key, value]) => {
          const metric = value as TMetric

          return {
            key,
            title: titleMapping[key] || key,
            current: Number(metric.current),
            goal: Number(metric.goal),
            delta: Number(metric.delta) ?? 0,
            percentage: Number(metric.percentage_period) ?? 0
          }
        }),
    [savedAdjustments]
  )

  const handleUpdateAdjustedGoals = (
    selectedGoal: TGoal,
    delta: number,
    value: number,
    percentage: number
  ) => {
    setGoalsAdjustments((prevState) => {
      const newAdjustments = {
        ...prevState,
        [selectedGoal.key]: {
          delta,
          value,
          percentage
        }
      }

      return newAdjustments
    })
  }

  const [totalAdjustedGoals, setTotalAdjustedGoals] = useState(0)

  useEffect(() => {
    if (goalsAdjustments)
      return setTotalAdjustedGoals(
        Object.values(goalsAdjustments).filter((goal) => goal.value !== 0).length
      )
  }, [goalsAdjustments])

  const {
    isOpen: confirmAdjustmentsModalIsOpen,
    onClose: confirmAdjustmentsModalOnClose,
    onOpen: confirmAdjustmentsModalOnOpen
  } = useDisclose()

  const { mutate: saveAdjustmentsMutate, isLoading: saveAdjustmentsIsLoading } =
    useSaveGoalsAdjustmentsMutation()

  const handleConcludeAdjustments = useCallback(() => {
    const clearAllTimeouts = () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))

      setTimeouts([])
    }

    goalsAdjustments &&
      saveAdjustmentsMutate(
        {
          goals_adjustments: {
            id: staffId ?? '',
            updates: goalsAdjustments
          }
        },
        {
          onError() {
            const timeoutId = window.setTimeout(() => {
              clearAllTimeouts()
            }, 1000)

            setTimeouts((prev) => [...prev, timeoutId])

            toast.show({
              render: () => (
                <Toast
                  type="error"
                  text={`Não foi possível salvar o Ajuste de Metas de ${staffName}. 
                Tente novamente mais tarde`}
                />
              ),
              placement: 'bottom',
              duration: 1500
            })
          },
          onSuccess() {
            const timeoutId = window.setTimeout(() => {
              clearAllTimeouts()
            }, 1000)

            setTimeouts((prev) => [...prev, timeoutId])

            toast.show({
              render: () => (
                <Toast
                  type="success"
                  text={`Ajuste de Metas de ${staffName} realizada com Sucesso!`}
                />
              ),
              placement: 'bottom',
              duration: 1500
            })
          }
        }
      )

    confirmAdjustmentsModalOnClose()

    handleGoBack()
  }, [
    confirmAdjustmentsModalOnClose,
    handleGoBack,
    saveAdjustmentsMutate,
    staffId,
    timeouts,
    toast,
    goalsAdjustments,
    staffName
  ])

  const [isHovered, setIsHovered] = useState(false)

  const handleChangeHovered = useCallback((value: boolean) => {
    setIsHovered(value)
  }, [])

  const [selectedGoal, setSelectedGoal] = useState<TGoal>(
    formattedGoals.find((goal) => goal.key === currentGoal) ?? formattedGoals[0]
  )

  useEffect(() => {
    if (currentGoal)
      return setSelectedGoal(
        formattedGoals.find((goal) => goal.key === currentGoal) ?? formattedGoals[0]
      )
    else if (formattedGoals[0]) return setSelectedGoal(formattedGoals[0])
  }, [formattedGoals, currentGoal])

  const handleChangeSelectedGoal = useCallback((value: TGoal) => {
    setSelectedGoal(value)
  }, [])

  const handleSaveAdjustments = useCallback(() => {
    confirmAdjustmentsModalOnOpen()
  }, [confirmAdjustmentsModalOnOpen])

  const isCurrency = !['accounts', 'active_base', 'charge'].includes(selectedGoal?.key)

  const noChanges = !selectedGoal?.percentage || !selectedGoal.goal

  const [inputValue, setInputValue] = useState<number>(0)

  const [operationType, setOperationType] = useState<'add' | 'subtract' | string>('add')

  const handleChangeOperationType = useCallback((value: TOperation) => {
    setOperationType(value)
  }, [])

  const percentage = useMemo(() => selectedGoal?.percentage ?? 0, [selectedGoal?.percentage])

  const minValue = -percentage

  const baseValue = useMemo(() => {
    return Number(selectedGoal?.current) ?? Number(selectedGoal?.goal) ?? 0
  }, [selectedGoal])

  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(formatCurrencyField(value, isCurrency))
    },
    [isCurrency]
  )

  const adjustedGoalValue = useMemo(() => {
    const result = operationType === 'add' ? baseValue + inputValue : baseValue - inputValue

    return Math.max(0, result)
  }, [inputValue, operationType, baseValue])

  const adjustedPercentage = useMemo(() => {
    if (isCurrency) {
      const initialPercentage = (inputValue / baseValue) * 100

      const outOfBounds = initialPercentage > percentage || initialPercentage < minValue

      const finalPercentage = outOfBounds
        ? initialPercentage > 0
          ? percentage
          : minValue
        : initialPercentage

      return operationType === 'add'
        ? parseFloat(finalPercentage.toFixed(2))
        : -parseFloat(finalPercentage.toFixed(2))
    } else {
      return operationType === 'add' ? inputValue : -inputValue
    }
  }, [inputValue, operationType, baseValue, minValue, percentage, isCurrency])

  const isOutOfBounds = useMemo(() => {
    const initialPercentage = (inputValue / baseValue) * 100

    return !isCurrency
      ? inputValue > percentage || inputValue < minValue
      : initialPercentage > percentage || initialPercentage < minValue
  }, [percentage, minValue, inputValue, baseValue, isCurrency])

  const markerPosition = useMemo(() => {
    const position = ((adjustedPercentage - minValue) / (percentage - minValue)) * 100

    let finalPosition = position

    if (operationType === 'add') {
      if (position < 100 && position > 65) finalPosition = finalPosition - 3.5
    }

    return isOutOfBounds ? (operationType === 'add' ? 100 : 0) : finalPosition
  }, [adjustedPercentage, minValue, isOutOfBounds, operationType, percentage])

  const deltaValue = useMemo(() => {
    const delta = Number(selectedGoal?.delta)

    const value = operationType === 'add' ? inputValue : -inputValue

    return delta + value
  }, [inputValue, operationType, selectedGoal])

  useEffect(() => {
    if (!isOutOfBounds && inputValue !== 0)
      handleUpdateAdjustedGoals(selectedGoal, deltaValue, adjustedGoalValue, adjustedPercentage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedGoalValue, adjustedPercentage])

  useEffect(() => {
    handleInputChange(String(0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal])

  const anyHookIsLoading = savedAdjustmentsDataIsLoading || saveAdjustmentsIsLoading

  return {
    handleGoBack,
    goalsListIsOpen,
    goalsListOnOpen,
    goalsListOnClose,
    goalsAdjustments,
    totalAdjustedGoals,
    confirmAdjustmentsModalIsOpen,
    confirmAdjustmentsModalOnClose,
    handleConcludeAdjustments,
    handleChangeHovered,
    isHovered,
    selectedGoal,
    handleChangeSelectedGoal,
    noChanges,
    isCurrency,
    adjustedGoalValue,
    adjustedPercentage,
    markerPosition,
    percentage,
    operationType,
    handleChangeOperationType,
    inputValue,
    handleInputChange,
    isOutOfBounds,
    deltaValue,
    handleSaveAdjustments,
    anyHookIsLoading,
    goals: formattedGoals,
    staffName,
    staffOccupation
  }
}
