import { useCallback, useEffect, useMemo, useState } from 'react'

import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import {
  META_TYPE_ENUM,
  PERIOD_STATUS_ENUM,
  TGoalsLeadersListItem,
  useUpdateGoalsPeriodMutation,
  useGetGoalsLeadersListInfinityQuery,
  useGetGoalsReportQuery,
  useGetGoalsSummaryQuery,
  INDIVIDUAL_PERIOD_STATUS_ENUM
} from 'integration/resources/goals'
import {
  DIRECTORY_TYPE_ENUM,
  EXTENSION_TYPE_ENUM,
  useUploadMutation
} from 'integration/resources/upload'
import { useSaveReportFileMutation } from 'integration/resources/walletsManagement'
import { useBreakpointValue, useDisclose } from 'native-base'
import useDebounce from 'src/hooks/useDebounce'

import { OrderField, UseGoalsLeadersListScreen } from './GoalsLeadersListScreen.types'
import { ReportPDF } from '../Components/ReportPDF/ReportPDF'

const mergeGoalsLeadersInfiniteQuery = (
  data: ReturnType<typeof useGetGoalsLeadersListInfinityQuery>['data']
) =>
  data?.pages.reduce<TGoalsLeadersListItem[]>((previousValue, currentValue) => {
    previousValue.push(...currentValue.data.data)

    return previousValue
  }, []) ?? []

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Valor da meta',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o Menor' }
    ]
  }
]

export const useGoalsLeadersListScreen: UseGoalsLeadersListScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data: summaryData, isLoading: summaryDataIsLoading } = useGetGoalsSummaryQuery()

  const goalsSummary = useMemo(() => summaryData?.data.data, [summaryData?.data.data])

  const periodIsOpen = goalsSummary?.status === PERIOD_STATUS_ENUM.OPENED

  const [currentList, setCurrentList] = useState<META_TYPE_ENUM>(META_TYPE_ENUM.Production)

  const handleListChange = (list: META_TYPE_ENUM) => {
    setCurrentList(list)
  }

  const [search, onSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search, 500)

  const handleSearchClear = () => {
    onSearch('')
  }

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const [appliedOrderBy, setAppliedOrderBy] = useState<'user_name' | 'meta_value' | undefined>()

  const [appliedOrderByDirection, setAppliedOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >()

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  const handleApply = () => {
    setAppliedOrderBy(
      selectedOrderBy?.field
        ? selectedOrderBy?.field === 'Nome'
          ? 'user_name'
          : 'meta_value'
        : undefined
    )

    setAppliedOrderByDirection(selectedOrderBy?.direction)
  }

  const {
    data: leadersListData,
    isLoading: leadersListLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetGoalsLeadersListInfinityQuery({
    order_by: appliedOrderBy,
    order_by_direction: appliedOrderByDirection,
    meta_type: currentList,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const leadersList = useMemo(
    () => mergeGoalsLeadersInfiniteQuery(leadersListData) ?? [],
    [leadersListData]
  )

  const leadersListTotalItems = leadersListData?.pages[0].data.metadata.pagination?.total ?? 0

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const handleGoToGoalsLeaderDetails = (userStdCode: string) => {
    navigation.navigate('GoalsLeaderDetails', { userStdCode })
  }

  const handleGoToOpenGoalsPeriod = () => {
    navigation.navigate('OpenGoalsPeriod', {})
  }

  const {
    isOpen: closePeriodModalIsOpen,
    onClose: closePeriodModalOnClose,
    onOpen: closePeriodModalOnOpen
  } = useDisclose()

  const {
    isOpen: confirmClosePeriodModalIsOpen,
    onClose: confirmClosePeriodModalOnClose,
    onOpen: confirmClosePeriodModalOnOpen
  } = useDisclose()

  const { mutateAsync: updatePeriod, isLoading: updatePeriodIsLoading } =
    useUpdateGoalsPeriodMutation()

  const handleClosePeriod = useCallback(() => {
    goalsSummary?.id &&
      updatePeriod(
        { periodId: goalsSummary?.id, status: INDIVIDUAL_PERIOD_STATUS_ENUM.CLOSED },
        {
          onError: () => {
            closePeriodModalOnClose()
          },
          onSuccess: () => {
            closePeriodModalOnClose()
          }
        }
      )
  }, [goalsSummary?.id, closePeriodModalOnClose, updatePeriod])

  const unadjustedLeaders = 0

  const [reportLeaderStdCode, setReportLeaderStdCode] = useState('')

  const { data: goalsReportData, isLoading: goalsReportIsLoading } = useGetGoalsReportQuery({
    leader_std_code: reportLeaderStdCode,
    enabled: !!reportLeaderStdCode
  })

  const reportData = goalsReportData?.data.data

  const handleDownloadReport = (leaderStdCode: string) => {
    setReportLeaderStdCode(leaderStdCode)
  }

  const { mutateAsync: saveFileMutationAsync, isLoading: saveFileIsLoading } =
    useSaveReportFileMutation()

  const { mutateAsync: uploadMutationAsync, isLoading: isUploadLoading } = useUploadMutation()

  const [downloadReportIsLoading, setDownloadReportIsLoading] = useState(false)

  const handleCreatePDF = useCallback(async () => {
    setDownloadReportIsLoading(true)

    if (!reportData?.file_url) {
      try {
        const blob = await pdf(<ReportPDF {...reportData} />).toBlob()

        const file = blob

        if (file.type && file.type.split('/').length > 0) {
          const type = file.type.split('/')[1]

          const files = await uploadMutationAsync({
            directory_type: DIRECTORY_TYPE_ENUM.GOALS_REPORTS,
            extension_type: type.toUpperCase() as keyof typeof EXTENSION_TYPE_ENUM,
            files: [file]
          })

          if (files.length) {
            if (files[0].status !== 200) {
              console.log('erro')

              return
            }

            const fileName = files[0].name

            saveFileMutationAsync({ leaderStdCode: Number(reportLeaderStdCode), fileName })
          }
        }

        saveAs(file, 'Relatorio Ajuste de Metas.pdf')
      } catch (error) {
        console.error('Erro ao gerar o PDF:', error)
      }
    } else {
      const response = await fetch(reportData?.file_url)

      const file = await response.blob()

      saveAs(file, 'Relatorio Ajuste de Metas.pdf')
    }

    setDownloadReportIsLoading(false)
  }, [reportData, reportLeaderStdCode, saveFileMutationAsync, uploadMutationAsync])

  useEffect(() => {
    if ((reportLeaderStdCode && reportData?.header?.leader_std_code) || reportData?.file_url) {
      handleCreatePDF()
    }
  }, [reportLeaderStdCode, reportData, handleCreatePDF])

  const anyHookIsLoading =
    summaryDataIsLoading ||
    leadersListLoading ||
    updatePeriodIsLoading ||
    goalsReportIsLoading ||
    isUploadLoading ||
    saveFileIsLoading

  return {
    isMobile,
    goalsSummary,
    summaryDataIsLoading,
    periodIsOpen,
    currentList,
    handleListChange,
    leadersListData: leadersList,
    leadersListIsLoading: leadersListLoading,
    leadersListIsFetchingNextPage: isFetchingNextPage,
    leadersListTotalItems,
    onEndReached,
    search,
    onSearch,
    onPressSearchClear: handleSearchClear,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    handleGoToGoalsLeaderDetails,
    handleGoToOpenGoalsPeriod,
    closePeriodModalIsOpen,
    closePeriodModalOnClose,
    closePeriodModalOnOpen,
    confirmClosePeriodModalIsOpen,
    confirmClosePeriodModalOnClose,
    confirmClosePeriodModalOnOpen,
    handleClosePeriod,
    updatePeriodIsLoading,
    unadjustedLeaders,
    handleDownloadReport,
    downloadReportIsLoading: downloadReportIsLoading || goalsReportIsLoading,
    anyHookIsLoading
  }
}
