import { MultiplierValuesType, Tab } from '../RVCalculatorsSim.types'

export const rolesGR = [
  'GTE RELAC FINANC III (FIN)',
  'GTE RELAC FINANC II',
  'ESPEC COML FINANCEIRA I',
  'ESPEC COML FINANCEIRA II',
  'ESPEC COML FINANCEIRA III',
  'ESPEC RISCOS CREDITO III',
  'ESPEC RISCOS CREDITO I',
  'ESPEC INVESTIMENTOS II'
]

export const targets = [
  { id: '0', name: 'Espec I', value: 908 },
  { id: '1', name: 'Espec II', value: 1272 },
  { id: '2', name: 'Espec III', value: 1526 },
  { id: '3', name: 'Regional', value: 7000 }
]

export const resultsTargets = [
  { id: '0', name: 'Espec I', value: 1817 },
  { id: '1', name: 'Espec II', value: 2543 },
  { id: '2', name: 'Espec III', value: 3052 }
]

export const performanceValues = [0, 5, 15, 30]

export const chargeGoalValues = [1, 2]

export const mobSPFValues = ['MOB', 'SPF', 'TABS recorrentes']

export const multiplierEspecValues: MultiplierValuesType = {
  2: { withInsurance: 1, withoutInsurance: 1 },
  3: { withInsurance: 1.4, withoutInsurance: 1.2 },
  4: { withInsurance: 2.3, withoutInsurance: 1.8 },
  5: { withInsurance: 4, withoutInsurance: 4 }
}

export const multiplierValues: MultiplierValuesType = {
  2: { withInsurance: 1, withoutInsurance: 1 },
  3: { withInsurance: 1.4, withoutInsurance: 1.2 },
  4: { withInsurance: 1.8, withoutInsurance: 1.4 },
  5: { withInsurance: 2.3, withoutInsurance: 1.8 },
  6: { withInsurance: 4.0, withoutInsurance: 4.0 }
}

export const resultMultiplierValues: MultiplierValuesType = {
  0: { withInsurance: 1, withoutInsurance: 1 },
  1: { withInsurance: 1.2, withoutInsurance: 1.1 },
  2: { withInsurance: 1.4, withoutInsurance: 1.2 },
  3: { withInsurance: 2, withoutInsurance: 1.5 },
  4: { withInsurance: 2.8, withoutInsurance: 2 },
  5: { withInsurance: 4, withoutInsurance: 3 }
}

export const indicatorsLines = [
  'Produção',
  'Cobrança',
  'TABS pagantes',
  'TABS recorrentes',
  'mobSPF',
  'Total'
]

export const indicatorsLinesLeader = [
  'Produção',
  'MOB',
  'TABS pagantes',
  'Gastos',
  'Cobrança',
  'mobSPF',
  'Total'
]

export const tabsConfig: Tab[] = [
  {
    id: 1,
    title: `Calculadora Bloco de Negócios`,
    subtitle: 'Simule seus ganhos de remuneração variável e compare com sua RV atual',
    observations: [
      `Este “SIMULADOR” deve ser utilizado para simples estimativas e para esclarecer eventuais pontos sobre o cálculo do programa de RV 2025. Ele não considera eventuais proporcionalidades nem representa critérios oficiais de elegibilidade, apuração e pagamento.`
    ]
  },
  {
    id: 2,
    title: 'Calculadora de Resultados',
    subtitle: 'Subtítulo contextualizando a calculadora.',
    observations: [
      `Este “SIMULADOR” deve ser utilizado para simples estimativas e para esclarecer eventuais pontos sobre o cálculo do programa de RV 2025. Ele não considera eventuais proporcionalidades nem representa critérios oficiais de elegibilidade, apuração e pagamento.`
    ]
  }
]
