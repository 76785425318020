import { RevenueCalculatorFormFields } from './RevenueCalculator.types'

export const formsDefaultValues: RevenueCalculatorFormFields = {
  targetAccount: 0,
  mobSPF: 'SPF',
  weightProduction: 40,
  weightCharge: 20,
  weightPaying: 15,
  weightRecurrent: 15,
  weightMobSPF: 10,
  weightExpenses: 0,
  weightTotal: 100,

  targetProduction: 0,
  targetCharge: 0,
  targetPaying: 0,
  targetRecurrent: 0,
  targetMobSPF: 0,
  targetExpenses: 0,
  targetTotal: 0,

  goalProduction: 0,
  goalCharge: 0,
  goalPaying: 0,
  goalRecurrent: 0,
  goalMobSPF: 0,
  goalExpenses: 0,

  realizedProduction: 0,
  realizedCharge: 0,
  realizedPaying: 0,
  realizedRecurrent: 0,
  realizedMobSPF: 0,
  realizedExpenses: 0,

  percentRealizedProduction: 0,
  percentRealizedCharge: 0,
  percentRealizedPaying: 0,
  percentRealizedRecurrent: 0,
  percentRealizedMobSPF: 0,
  percentRealizedExpenses: 0,

  rvSimulatedProduction: 0,
  rvSimulatedCharge: 0,
  rvSimulatedPaying: 0,
  rvSimulatedRecurrent: 0,
  rvSimulatedMobSPF: 0,
  rvSimulatedExpenses: 0,
  rvSimulatedTotal: 0,

  chargeGoal: 1,
  multiplier: 0,
  multipliedRV: 0,
  performance: 0,
  rvTotal: 0
}

export const weightFields: (keyof RevenueCalculatorFormFields)[] = [
  'weightProduction',
  'weightCharge',
  'weightPaying',
  'weightExpenses',
  'weightRecurrent',
  'weightMobSPF'
]

export const targetFields: (keyof RevenueCalculatorFormFields)[] = [
  'targetProduction',
  'targetCharge',
  'targetPaying',
  'targetExpenses',
  'targetRecurrent',
  'targetMobSPF',
  'targetTotal'
]

export const goalFields: (keyof RevenueCalculatorFormFields)[] = [
  'goalProduction',
  'goalCharge',
  'goalPaying',
  'goalExpenses',
  'goalRecurrent',
  'goalMobSPF'
]

export const realizedFields: (keyof RevenueCalculatorFormFields)[] = [
  'realizedProduction',
  'realizedCharge',
  'realizedPaying',
  'realizedExpenses',
  'realizedRecurrent',
  'realizedMobSPF'
]

export const percentRealizedFields: (keyof RevenueCalculatorFormFields)[] = [
  'percentRealizedProduction',
  'percentRealizedCharge',
  'percentRealizedPaying',
  'percentRealizedExpenses',
  'percentRealizedRecurrent',
  'percentRealizedMobSPF'
]

export const rvSimulatedFields: (keyof RevenueCalculatorFormFields)[] = [
  'rvSimulatedProduction',
  'rvSimulatedCharge',
  'rvSimulatedPaying',
  'rvSimulatedExpenses',
  'rvSimulatedRecurrent',
  'rvSimulatedMobSPF',
  'rvSimulatedTotal'
]
