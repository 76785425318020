import { FunctionComponent, memo, useCallback, useEffect, useState } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Avatar, Icons } from 'atoms'
import { AxiosResponse } from 'axios'
import { OpportunitySerialized } from 'integration/resources/opportunities'
import { Store } from 'integration/resources/store'
import { Button, Center, HStack, Heading, Text, VStack } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { TouchableOpacity } from 'react-native'
import { FAVORITE_TYPE_ENUM } from 'src/screens/ContentScreen/ContentScreen.types'

type CardBaseProps = OpportunitySerialized & {
  onFavoriteClick?: (type: FAVORITE_TYPE_ENUM, id: string) => Promise<AxiosResponse<any, any>>
  title: string
  id: string
  coverImageURL?: string
  earningPotential: string
  isFavorite?: boolean
  store: Store
}

/**
 * TODO
 * pontos da oportunidade faltante na API
 * esta mocado nesse componente
 **/

export const OpportunityCard: FunctionComponent<CardBaseProps> = memo(
  ({ id, title, store, isFavorite, earningPotential, onFavoriteClick, is_finished }) => {
    const [loading, setLoading] = useState(false)

    const [favorite, setFavorite] = useState(isFavorite)

    const handlePressFavorite = useCallback(() => {
      setLoading(true)

      onFavoriteClick?.(favorite ? FAVORITE_TYPE_ENUM.UNFAVORITE : FAVORITE_TYPE_ENUM.FAVORITE, id)
        .then(() => {
          setFavorite(!favorite)
        })
        .finally(() => {
          setLoading(false)
        })
    }, [favorite, onFavoriteClick, id])

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

    useEffect(() => {
      setFavorite(isFavorite)
    }, [isFavorite])

    return (
      <VStack space={4} key={id}>
        <VStack bg="white" borderRadius="20px" px={6} py={4}>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate('OrganizationOpportunity', {
                id: String(id)
              })
            }>
            <HStack space={2} alignItems="center">
              <Center
                bg={is_finished ? 'tertiary.50' : 'white'}
                borderWidth={1}
                borderColor={is_finished ? 'tertiary.50' : 'gray.50'}
                borderRadius="full"
                mr={2}
                h={12}
                w={12}>
                <Icons.Dollar color={is_finished ? 'secondary.500' : 'gray.700'} size={24} />
              </Center>

              <VStack flex={1}>
                <Heading
                  fontSize="16px"
                  lineHeight="21px"
                  fontWeight="normal"
                  color="gray.700"
                  mb={1}>
                  {title}
                </Heading>
              </VStack>

              <HStack flex={1} space={4} alignItems="center">
                <Center
                  bg="white"
                  borderRadius="full"
                  h={10}
                  w={10}
                  display={{
                    xl: 'flex',
                    lg: 'none'
                  }}>
                  <Avatar uri={undefined} title={title} type="light" h={10} w={10} />
                </Center>
                <VStack
                  display={{
                    xl: 'flex',
                    lg: 'none'
                  }}>
                  <Text
                    fontSize="16px"
                    lineHeight="21px"
                    fontWeight="normal"
                    color="gray.400"
                    maxWidth={200}>
                    {store.name_fantasy}
                  </Text>
                  <Text fontSize="14px" lineHeight="21px" fontWeight="semibold" color="gray.400">
                    TAB {store?.tab_number}
                  </Text>
                </VStack>
                <HStack flex={1} space={4}>
                  <VStack ml={2}>
                    <Text fontSize="14px" lineHeight="21px" fontWeight="normal" color="gray.400">
                      Potencial
                    </Text>
                    <Text fontSize="14px" lineHeight="21px" fontWeight="normal" color="gray.400">
                      R$ {earningPotential}
                    </Text>
                  </VStack>
                </HStack>
              </HStack>

              <HStack flex={1} alignItems="center">
                <Button
                  h="24px"
                  p={0}
                  px={4}
                  mr={3}
                  bg="tertiary.800"
                  _hover={{ bg: 'tertiary.800' }}
                  _text={{
                    color: 'white',
                    fontSize: '12px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                  }}>
                  oportunidade
                </Button>
                <Button
                  h="24px"
                  p={0}
                  px={4}
                  bg="white"
                  display={{
                    xl: 'flex',
                    lg: 'none'
                  }}
                  borderColor="gray.700"
                  borderWidth={1}
                  _hover={{ bg: 'white' }}
                  _text={{
                    color: 'gray.700',
                    fontSize: '12px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                  }}>
                  100 pts
                </Button>
              </HStack>

              <HStack alignItems="center">
                <Button
                  alignSelf="flex-end"
                  onPress={handlePressFavorite}
                  h={10}
                  w={10}
                  borderRadius="full"
                  isLoading={loading}
                  colorScheme="dark"
                  _spinner={{
                    color: 'gray.700'
                  }}
                  _loading={{
                    bgColor: 'gray.70'
                  }}
                  bgColor="gray.30">
                  <Center>
                    <Icons.Star
                      // size={4}
                      filled={favorite}
                      color={favorite ? 'tabs-yellow.50' : 'gray.200'}
                    />
                  </Center>
                </Button>
              </HStack>
            </HStack>
          </TouchableOpacity>
        </VStack>
      </VStack>
    )
  }
)
