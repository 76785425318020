import { ResultsCalculatorFormFields } from './ResultsCalculator.types'

export const formsDefaultValues = {
  targetAccount: 0,
  weightProduction: 40,
  weightCharge: 20,
  weightPaying: 15,
  weightRecurrent: 15,
  weightMobSPF: 10,

  weightTotal: 100,
  goalProduction: 0,
  goalPaying: 0,
  goalRecurrent: 0,
  goalCharge: 0,
  goalMobSPF: 0,

  realizedProduction: 0,
  realizedPaying: 0,
  realizedRecurrent: 0,
  realizedCharge: 0,
  realizedMobSPF: 0,

  percentAchievedProduction: 0,
  percentAchievedPaying: 0,
  percentAchievedRecurrent: 0,
  percentAchievedCharge: 0,
  percentAchievedMobSPF: 0,

  goalOneProduction: 0,
  goalOnePaying: 0,
  goalOneRecurrent: 0,
  goalOneCharge: 0,
  goalOneMobSPF: 0,

  realizedOneProduction: 0,
  realizedOnePaying: 0,
  realizedOneRecurrent: 0,
  realizedOneCharge: 0,
  realizedOneMobSPF: 0,

  percentAchievedOneProduction: 0,
  percentAchievedOnePaying: 0,
  percentAchievedOneRecurrent: 0,
  percentAchievedOneCharge: 0,
  percentAchievedOneMobSPF: 0,

  goalTwoProduction: 0,
  goalTwoPaying: 0,
  goalTwoRecurrent: 0,
  goalTwoCharge: 0,
  goalTwoMobSPF: 0,

  realizedTwoProduction: 0,
  realizedTwoPaying: 0,
  realizedTwoRecurrent: 0,
  realizedTwoCharge: 0,
  realizedTwoMobSPF: 0,

  percentAchievedTwoProduction: 0,
  percentAchievedTwoPaying: 0,
  percentAchievedTwoRecurrent: 0,
  percentAchievedTwoCharge: 0,
  percentAchievedTwoMobSPF: 0,

  pointsProduction: 0,
  pointsPaying: 0,
  pointsRecurrent: 0,
  pointsCharge: 0,
  pointsMobSPF: 0,
  pointsTotal: 0,
  finalAverage: 0,
  multiplier: 0,
  multipliedRV: 0,
  performance: 0,
  rvTotal: 0,
  rvValue: 0,
  mobSPF: 'SPF'
}

export const weightFields: (keyof ResultsCalculatorFormFields)[] = [
  'weightProduction',
  'weightCharge',
  'weightPaying',
  'weightRecurrent',
  'weightMobSPF'
]

export const goalOneFields: (keyof ResultsCalculatorFormFields)[] = [
  'goalOneProduction',
  'goalOneCharge',
  'goalOnePaying',
  'goalOneRecurrent',
  'goalOneMobSPF'
]

export const realizedOneFields: (keyof ResultsCalculatorFormFields)[] = [
  'realizedOneProduction',
  'realizedOneCharge',
  'realizedOnePaying',
  'realizedOneRecurrent',
  'realizedOneMobSPF'
]

export const percentAchievedOneFields: (keyof ResultsCalculatorFormFields)[] = [
  'percentAchievedOneProduction',
  'percentAchievedOneCharge',
  'percentAchievedOnePaying',
  'percentAchievedOneRecurrent',
  'percentAchievedOneMobSPF'
]

export const goalTwoFields: (keyof ResultsCalculatorFormFields)[] = [
  'goalTwoProduction',
  'goalTwoCharge',
  'goalTwoPaying',
  'goalTwoRecurrent',
  'goalTwoMobSPF'
]

export const realizedTwoFields: (keyof ResultsCalculatorFormFields)[] = [
  'realizedTwoProduction',
  'realizedTwoCharge',
  'realizedTwoPaying',
  'realizedTwoRecurrent',
  'realizedTwoMobSPF'
]

export const percentAchievedTwoFields: (keyof ResultsCalculatorFormFields)[] = [
  'percentAchievedTwoProduction',
  'percentAchievedTwoCharge',
  'percentAchievedTwoPaying',
  'percentAchievedTwoRecurrent',
  'percentAchievedTwoMobSPF'
]

export const goalFields: (keyof ResultsCalculatorFormFields)[] = [
  'goalProduction',
  'goalCharge',
  'goalPaying',
  'goalRecurrent',
  'goalMobSPF'
]

export const realizedFields: (keyof ResultsCalculatorFormFields)[] = [
  'realizedProduction',
  'realizedCharge',
  'realizedPaying',
  'realizedRecurrent',
  'realizedMobSPF'
]

export const percentAchievedFields: (keyof ResultsCalculatorFormFields)[] = [
  'percentAchievedProduction',
  'percentAchievedCharge',
  'percentAchievedPaying',
  'percentAchievedRecurrent',
  'percentAchievedMobSPF'
]

export const pointsFields: (keyof ResultsCalculatorFormFields)[] = [
  'pointsProduction',
  'pointsCharge',
  'pointsPaying',
  'pointsRecurrent',
  'pointsMobSPF',
  'pointsTotal'
]
