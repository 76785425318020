import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { BottomSheetModal } from 'molecules'
import { RootStackScreenComponentProps, TabScreenComponentProps } from 'src/navigation'

export type OrganizationScreenComponent = FunctionComponent<OrganizationScreenProps>

export type OrganizationScreenActivityComponent = FunctionComponent<OrganizationActivityScreenProps>

export type OrganizationScreenOpportunityComponent =
  FunctionComponent<OrganizationOpportunityScreenProps>

export type OrganizationScreenProps = TabScreenComponentProps<'Organization'>

export type OrganizationActivityScreenProps =
  RootStackScreenComponentProps<'OrganizationActivityList'>

export type OrganizationOpportunityScreenProps =
  RootStackScreenComponentProps<'OrganizationOpportunityList'>

export type ResponsiveProfileScreenComponent = FunctionComponent<ReturnType<UseOrganizationScreen>>

export interface Events {
  id: number
  name: string
  category: string
  tab: string
  value: string
}

export interface EventsByCategory {
  id: number
  title: string
  events: Events[]
}

export type ViewType = {
  title: string
  group: number
  icon: keyof typeof Icons
  combo?: boolean
}

export type PeriodTypes = {
  startingDay: string
  endingDay: string
}

export type ActivitiesGroup = { group: 1 | 2 | 3 | 4 | 11 | 12 }

export type OpportunitiesGroup = { group: LIST_TYPE_ENUM }

export type ViewModes = 'default' | 'kanban'

export type UseOrganizationScreen = ({
  navigation,
  route
}: Pick<OrganizationScreenProps, 'navigation' | 'route'>) => {
  tabs: TTab[]
  isMobile: boolean
  isLoading: boolean
  currentTab: 1 | 2
  viewType: ViewType
  viewTypes: ViewType[]
  ascOrDesc: 'asc' | 'desc'
  organizationValue: {
    total_activities: number
    total_opportunities: number
  }

  currentSelectedDate?: string
  currentSelectedPeriod?: {
    startingDay: string | undefined
    endingDay: string | undefined
  }
  handleToggleAscOrDesc: () => void
  setCurrentTab(index: 1 | 2): void
  handleToggleActivitiesTypeView: (viewType: ViewType, date: string) => void
  handleToggleActivitiesOrOpportunities: (id: 1 | 2 | number) => void
  navigation: OrganizationScreenProps['navigation']
  route: OrganizationScreenProps['route']
  viewTypeSheetRef: React.RefObject<BottomSheetModal>
  openViewTypeSheet: () => void
  handleGoToFilters: () => void
  handleGoToOrderByFilter: () => void
  activeFilterCount?: number
  activeOrderByFilterCount?: number
}

export type TUserMessage = {
  avatarUri: string
  id: number
  name: string
  description: string
  amountToRead: number
  lastMessage: string
}

export type TTab = {
  id: 1 | 2 | number
  title: string
}

export type FilterScreenProps = {
  modalVisible: boolean
  setModalVisible: (_: boolean) => void
}

export type ListTypesRef = {
  fetchMore?: () => void
  refetch: () => void
  refreshing?: boolean
  search(search: string): void
  clearSearch(): void
}

export type ListTypesView = {
  navigation: OrganizationScreenProps['navigation']
  route: OrganizationScreenProps['route']
  handleSelect(vision: ViewType, date?: string): void
  data: {
    date?: string
    schedule_date_start?: string
    schedule_date_end?: string
  }
  ascOrDesc: 'asc' | 'desc'
  isLoading: boolean
}

export enum LIST_TYPE_ENUM {
  Pending = 13,
  Concluded = 10,
  Highlighted = 9,
  Charge = 8,
  Insurance = 7,
  ActiveBase = 6,
  ODC = 5
}

export const LIST_TYPES_NAMES = {
  [LIST_TYPE_ENUM.ActiveBase]: 'base_ativa',
  [LIST_TYPE_ENUM.Charge]: 'cobranca',
  [LIST_TYPE_ENUM.Concluded]: 'concluidas',
  [LIST_TYPE_ENUM.Highlighted]: 'destacadas',
  [LIST_TYPE_ENUM.Insurance]: 'seguro_auto',
  [LIST_TYPE_ENUM.ODC]: 'odc',
  [LIST_TYPE_ENUM.Pending]: 'pendentes'
}
