import { useCallback, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  BUSINESS_TYPES_ENUM,
  useGetGoalsLeaderDetailsSummaryQuery,
  useOpenGoalsPeriodMutation
} from 'integration/resources/goals'
import { useToast, useDisclose } from 'native-base'
import { Toast } from 'organisms'
import { SubmitHandler, useForm } from 'react-hook-form'
import { capitalizeText } from 'src/utils'
import * as yup from 'yup'

import { TOpenGoalsPeriodForm, UseOpenGoalsPeriodScreen } from './OpenGoalsPeriodScreen.types'

export const OpenGoalsPeriodFormSchema = yup.object().shape({
  date_start: yup
    .string()
    .required('A data de abertura é obrigatória.')
    .test(
      'is-before-end',
      'A data de abertura deve ser anterior à data de encerramento.',
      function (startDate) {
        const endDate = this.parent.date_end

        return !startDate || !endDate || new Date(startDate) < new Date(endDate)
      }
    ),
  date_end: yup
    .string()
    .required('A data de encerramento é obrigatória.')
    .test(
      'is-after-opening',
      'A data de fechamento deve ser maior que a data de abertura.',
      function (closingDate) {
        const { date_start } = this.parent

        return !date_start || !closingDate || new Date(closingDate) > new Date(date_start)
      }
    ),
  goals: yup
    .object()
    .shape({
      production: yup.number().min(0, 'O valor mínimo é 0.').max(100, 'O valor máximo é 100.'),
      spf: yup.number().min(0, 'O valor mínimo é 0.').max(100, 'O valor máximo é 100.'),
      auto: yup.number().min(0, 'O valor mínimo é 0.').max(100, 'O valor máximo é 100.'),
      wm: yup.number().min(0, 'O valor mínimo é 0.').max(100, 'O valor máximo é 100.'),
      charge: yup.number().min(0, 'O valor mínimo é 0.').max(100, 'O valor máximo é 100.'),
      accounts: yup.number().min(0, 'O valor mínimo é 0.').max(1000, 'O valor máximo é 1000.'),
      active_base: yup.number().min(0, 'O valor mínimo é 0.').max(1000, 'O valor máximo é 1000.')
    })
    .test(
      'at-least-one-goal',
      'Pelo menos um dos objetivos deve ter um valor maior que 0.',
      function (goals) {
        if (!goals) return false

        return Object.values(goals).some((value) => value && value > 0)
      }
    )
})

export const useOpenGoalsPeriodScreen: UseOpenGoalsPeriodScreen = ({ navigation, route }) => {
  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const leaderStdCode = route?.params?.leaderStdCode

  const { data: leaderDetailsData, isLoading: leaderDetailsIsLoading } =
    useGetGoalsLeaderDetailsSummaryQuery({
      leader_std_code: leaderStdCode ?? ''
    })

  const leaderName = capitalizeText(leaderDetailsData?.data.data.user?.name ?? '')

  const lastFinished = leaderDetailsData?.data.data.finished_at

  const {
    isOpen: goBackModalIsOpen,
    onClose: goBackModalOnClose,
    onOpen: goBackModalOnOpen
  } = useDisclose()

  const {
    isOpen: finishModalIsOpen,
    onClose: finishModalOnClose,
    onOpen: finishModalOnOpen
  } = useDisclose()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<TOpenGoalsPeriodForm>({
    mode: 'onChange',
    resolver: yupResolver(OpenGoalsPeriodFormSchema),
    defaultValues: {
      date_start: '',
      date_end: '',
      business: BUSINESS_TYPES_ENUM.ALL,
      goals: {
        production: 0,
        spf: 0,
        auto: 0,
        wm: 0,
        charge: 0,
        accounts: 0,
        active_base: 0
      }
    }
  })

  const { mutate: openGoalsPeriodMutate, isLoading } = useOpenGoalsPeriodMutation()

  const toast = useToast()

  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<TOpenGoalsPeriodForm>>(
      (data) => {
        const formattedData = {
          date_start: data.date_start,
          date_end: data.date_end,
          business: data.business,
          goals: data.goals,
          ...(leaderStdCode && {
            leader_std_code: Number(leaderStdCode)
          })
        }

        openGoalsPeriodMutate(formattedData, {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Não foi possível completar a ação " />,
              placement: 'bottom',
              duration: 1500
            })

            finishModalOnClose()

            handleGoBack()

            return leaderStdCode
              ? navigation.navigate('GoalsLeaderDetails', {
                  userStdCode: leaderStdCode
                })
              : navigation.navigate('GoalsLeadersList')
          },
          onSuccess: () => {
            toast.show({
              render: () => <Toast type="success" text="Período alterado 🎉" />,
              placement: 'bottom',
              duration: 1500
            })

            finishModalOnClose()

            handleGoBack()

            return leaderStdCode
              ? navigation.navigate('GoalsLeaderDetails', {
                  userStdCode: leaderStdCode
                })
              : navigation.navigate('GoalsLeadersList')
          }
        })
      },
      [finishModalOnClose, navigation, openGoalsPeriodMutate, toast, leaderStdCode, handleGoBack]
    )
  )

  const isIndividualOpening = useMemo(() => !!leaderStdCode, [leaderStdCode])

  return {
    handleGoBack,
    isLoading,
    control,
    handleSubmit: onSubmit,
    errors,
    isValid,
    goBackModalIsOpen,
    goBackModalOnClose,
    goBackModalOnOpen,
    finishModalIsOpen,
    finishModalOnClose,
    finishModalOnOpen,
    onConfirmSubmit: onSubmit,
    isIndividualOpening,
    leaderName,
    lastFinished,
    leaderDetailsIsLoading
  }
}
