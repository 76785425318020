import { FunctionComponent } from 'react'

import { TLeadersListItem, TWalletsManagementPeriod } from 'integration/resources/walletsManagement'
import { RootStackScreenComponentProps } from 'src/navigation'

export type LeadersListScreenComponent = FunctionComponent<LeadersListScreenProps>

export type LeadersListScreenProps = RootStackScreenComponentProps<'LeadersList'>

export type ResponsiveLeadersListScreenComponent = FunctionComponent<
  ReturnType<UseLeadersListScreen>
>

export type CardProps = TLeadersListItem & {
  handleGoToStaffList?: (userStdCode: string) => void
  generalStatusIsOpen?: boolean
  handleDownloadReport?: (leaderStdCode: string) => void
  reportIsLoading?: boolean
}

export type SearchBarProps = {
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  isStaff?: boolean
}

export enum TAB_ENUM {
  Specialists,
  AssignedTabs,
  UnassignedTabs
}

export type TabButtonProps = {
  tab: TAB_ENUM
  label: string
  currentTab: TAB_ENUM
  onPress: (tab: TAB_ENUM) => void
}

export type OrderOption = {
  id: 'asc' | 'desc'
  name: string
}

export type OrderField = {
  label: string
  options: OrderOption[]
}

export type OrderByButtonProps = {
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
}

export type LeadersListProps = {
  search?: string
  leadersListData?: TLeadersListItem[]
  leadersListIsLoading: boolean
  leadersListIsFetchingNextPage: boolean
  handleGoToStaffList: (userStdCode: string) => void
  periodIsOpen: boolean
  handleDownloadReport?: (leaderStdCode: string) => void
  reportIsLoading: boolean
}

export type UseLeadersListScreen = ({
  navigation,
  route
}: Pick<LeadersListScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  leadersListData?: TLeadersListItem[]
  leadersListIsLoading: boolean
  leadersListIsFetchingNextPage: boolean
  totalItems: number
  unassignedLeaders: number
  onEndReached: () => void
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
  handleGoToStaffList: (userStdCode: string) => void
  handleGoToOpenWalletsPeriod: () => void
  handleFinishPeriod: () => void
  finishWalletsPeriodIsLoading: boolean
  adminPeriodData?: TWalletsManagementPeriod
  periodIsLoading: boolean
  anyHookIsLoading: boolean
  handleDownloadReport: (leaderStdCode: string) => void
  walletsManagementReportIsLoading: boolean
}
