import { UserProfile } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getProfile = (token?: string) => {
  return client.get<ResponseSuccess<UserProfile>>('v1/users/me/profile', {
    headers: token
      ? { Authorization: `${token}`, auth_sso: process.env.EXPO_PUBLIC_AUTH_SSO }
      : undefined
  })
}

export const updateProfile = async (
  params: Pick<UserProfile, 'profile_image_file_name' | 'phone_number_cell'>
) => await client.patch('v1/users/me', params)
