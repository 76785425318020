import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { Box, Center, Heading, HStack, Pressable, Skeleton, Text, VStack } from 'native-base'
import { panelsResumedChartsProps, PANELS_TYPES_ENUM } from 'organisms/TabPanels/constants'
import { Platform } from 'react-native'
import { formatDateStringToMask } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

type ResumedChartLayoutProps = {
  type: PANELS_TYPES_ENUM
  chart?: React.ReactElement
  handleGoToFullChartScreen(type: PANELS_TYPES_ENUM): void
  horizontalLabels?: boolean
  isLoading: boolean
  updated_at?: string
  noLabels?: boolean
  is_short_format?: boolean
}

export const ResumedChartLayout: FunctionComponent<ResumedChartLayoutProps> = ({
  type,
  chart,
  isLoading,
  updated_at,
  is_short_format = false,
  horizontalLabels,
  handleGoToFullChartScreen,
  noLabels = false
}) => {
  const isMobile = Platform.OS !== 'web'

  return (
    <VStack
      bg="white"
      borderRadius="20px"
      py="24px"
      px={4}
      space={4}
      style={!isMobile ? { minHeight: 430, flex: 1 } : {}}>
      <HStack justifyContent="space-between" zIndex={1} mb={2}>
        <Heading
          color="gray.700"
          fontSize={isMobile ? '16px' : 18}
          fontWeight={isMobile ? 'bold' : 'normal'}>
          {panelsResumedChartsProps[type].title}
        </Heading>
        {isLoading ? (
          <Skeleton borderRadius="full" h={6} w={60} />
        ) : (
          <Pressable
            isDisabled={noLabels}
            onPress={() => {
              LogEvent('resumo_carteira_grafico', {
                item_id: panelsResumedChartsProps[type].slug
              })

              handleGoToFullChartScreen(type)
            }}>
            <HStack alignItems="center">
              <Text
                color={isMobile ? (noLabels ? 'gray.200' : 'gray.700') : 'tertiary.800'}
                fontSize="12px"
                fontWeight="semibold"
                mr={2}>
                Ver mais
              </Text>
              <Icons.Arrow
                direction="right"
                size={6}
                color={isMobile ? (noLabels ? 'gray.200' : 'gray.700') : 'tertiary.800'}
              />
            </HStack>
          </Pressable>
        )}
      </HStack>

      <Box
        {...(!noLabels && {
          h: isMobile ? '190px' : 'auto'
        })}>
        {chart}
      </Box>

      {noLabels ? (
        <></>
      ) : (
        <Box>
          <HStack flexWrap="wrap" w="full" maxW={{ lg: '70%' }}>
            {panelsResumedChartsProps[type].subtitles &&
              panelsResumedChartsProps[type].subtitles.length > 0 &&
              panelsResumedChartsProps[type].subtitles.map((subtitle) => (
                <HStack alignItems="center" mb={isMobile ? 2 : 0} mr={3} key={subtitle.title}>
                  <Center
                    bg={subtitle.color}
                    borderRadius="full"
                    size={isMobile ? 3 : 4}
                    mr="6px"
                  />
                  <Text color="gray.700" fontSize={isMobile ? '12px' : '14px'} bold>
                    {subtitle.title}
                  </Text>
                </HStack>
              ))}
          </HStack>
          {isLoading ? (
            <Skeleton borderRadius="full" h={4} w={60} />
          ) : (
            updated_at && (
              <Text
                color="gray.200"
                fontSize={isMobile ? '10px' : '14px'}
                fontWeight="semibold"
                mt={isMobile ? -2 : 2}>
                Referência{' '}
                {updated_at &&
                  formatDateStringToMask(updated_at, is_short_format ? 'MM/yyyy' : 'dd/MM/yyyy')}
              </Text>
            )
          )}
        </Box>
      )}
    </VStack>
  )
}
