import { FunctionComponent } from 'react'

import { RootStackScreenComponentProps } from 'src/navigation'

export type LockscreenScreenComponent = FunctionComponent<LockscreenScreenProps>

export type LockscreenScreenProps = RootStackScreenComponentProps<'Lockscreen'>

export type ResponsiveLockscreenScreenComponent = FunctionComponent<ReturnType<UseLockscreenScreen>>

export enum LOCK_SCREEN_TIME_ENUM {
  IMMEDIATELY = 0,
  ONE_MINUTE = 1,
  FIFTEEN_MINUTES = 15,
  ONE_HOUR = 60
}

export type UseLockscreenScreen = ({ navigation }: Pick<LockscreenScreenProps, 'navigation'>) => {
  isMobile: boolean
  unlock: boolean
  requestUnlock: (item: boolean) => void
  unlockTime: LOCK_SCREEN_TIME_ENUM
  handleUnlockTime: (item: LOCK_SCREEN_TIME_ENUM) => void
  listOfTimes: number[]
}
